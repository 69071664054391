import React from 'react'
import GoannaDrawer from '../../../../../components/GoannaDrawer/GoannaDrawer'
import useGoannaDrawerContext from '../../../../../hooks/ContextHooks/useGoannaDrawerContext'

function FieldDetailHelpDrawer() {
    const { open, content, action, closeDrawer } = useGoannaDrawerContext();

    return (
        <GoannaDrawer open={open} content={content} action={action} closeDrawer={closeDrawer}/>
    )
}

export default FieldDetailHelpDrawer