import { Typography } from "@mui/material";
import { FunctionComponent } from "react";

interface IComingSoonProps {

}

const ComingSoon: FunctionComponent<IComingSoonProps> = () => {
    return (
        <Typography variant="h5" sx={{
            margin: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
        }}>
            Coming Soon!
        </Typography>);
}

export default ComingSoon;