import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import WaterIcon from '../../assets/images/Card/WaterLogo.png';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { ReactNode } from 'react';
import { JSXElement } from '@babel/types';

export enum AddRemoveButtonDisplay {
    None,
    Add,
    Remove
}

// add all Icons here

export enum SideIcon {
    WaterIcon,
    AcUnitIcon
}

export interface IInternalText {
    TextNumber: Number | undefined;
    TextData: String | ReactNode;
    dateString: string;
}

export function renderAddRemoveIcon(showAddRemoveButton?: AddRemoveButtonDisplay): React.ReactNode {
    switch (showAddRemoveButton) {

        // show add icon
        case AddRemoveButtonDisplay.Add:
            return  <AddIcon sx={{fill: '#0958FF'}} fontSize={'large'} />;

        // show remove Icon
        case AddRemoveButtonDisplay.Remove:
            return <RemoveIcon sx={{fill: '#0958FF'}} fontSize={'large'}/>;

        // Should never be called
        // don't show anything
        case AddRemoveButtonDisplay.None:
            return <></>;
        default:
            return <></>;
    }
}

//converts props enum to image 
export function getIconFromProps(icon: SideIcon | Number, size: string = '130px'): ReactNode {

    switch (icon) {
        case SideIcon.WaterIcon:
            return <img src={WaterIcon} alt='WaterIcon' width={size} />;

        // case SideIcon.AcUnitIcon:
        // return <AcUnitIcon style={{ fontSize: size }} />;
        default:
            break;
    }
}
