import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function DataTable() {
    const rows = [
        { name: 'First Irrigation', value: '27-Dec-22' },
        { name: 'Last Irrigation', value: '22-Mar-23' },
        { name: 'Irrigations', value: '7' },
        { name: 'Irrig - Avg Days Between', value: '14.17' },
        { name: 'Rain total mm', value: '399.60' },
        { name: 'Rain Days', value: '50' },
        { name: 'Avg Soil Total Inc', value: '42.76' },
        { name: 'Min of Soil Total Inc', value: '19.54' },
        { name: 'Max of Soil Total Inc', value: '66.78' },
        { name: 'Cum Day Degrees', value: '1438' },
        { name: 'Total Water Used ML', value: '6.17' },
        { name: 'Stress Time Above Threshold', value: '139.00' },
        { name: 'Add the Stress Impact / ha', value: '278' },
        { name: 'Stress Impact $ / ha', value: '722.8' },
        { name: 'Plant To Flower', value: '87' },
        { name: 'KC Maturity', value: '10-Apr-23' },
        { name: 'Avg Maturity', value: '177' }
      ];
    
      return (
          <Table aria-label="irrigation data table">
            <TableHead>
              <TableRow>
                <TableCell style={{
                    fontSize: '1rem',
                    fontFamily: 'poppins',
                    fontWeight: 'bold'
                }}>Field Details</TableCell>
                <TableCell style={{
                    fontSize: '1rem',
                    fontFamily: 'poppins',
                    fontWeight: 'bold',
                    textAlign:'right'
                }}>Values</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
      );
}

export default DataTable;