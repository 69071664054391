import * as React from 'react';
import { useSelector } from 'react-redux';
import useFieldDetailContext from '../../hooks/ContextHooks/useFieldDetailContext';
import { valueFormatter, valueRounder } from '../../utils/numericConversions';
import { MinimumMaximum } from '../../utils/Types';

export interface SoilMoistureProfileGraphSeriesType {
  GraphData: any,
  dailyUsageMap: Map<any, any>,
}

export interface SoilMoistureProfileContextDataType {
  loading: boolean,
  SoilMoistureGraphSeries: SoilMoistureProfileGraphSeriesType,
  depth: string[];
  minMaxMoisture: MinimumMaximum,
  minMaxTemp: MinimumMaximum,
  minMaxElectricalConductivity: MinimumMaximum,
}

export interface ISoilMoistureProfileProviderProps {
  children: React.ReactNode,
}

const SoilMoistureProfileContext = React.createContext({} as SoilMoistureProfileContextDataType);
const ACTUAL_DATA_START_INDEX = 6;

export default function SoilMoistureProfileProvider(props: ISoilMoistureProfileProviderProps) {

  const { deviceData, soilTotalDatawithTemp } = useFieldDetailContext();
  const [soilMoistureProfileData, setSoilMoistureProfileData] = React.useState({} as SoilMoistureProfileGraphSeriesType);
  const [depth, setDepth] = React.useState<string[]>([]);
  const [minMaxMoisture, setMinMaxMoisture] = React.useState<MinimumMaximum>({ min: undefined, max: undefined });
  const [minMaxTemp, setMinMaxTemp] = React.useState<MinimumMaximum>({ min: undefined, max: undefined });
  const [minMaxElectricalConductivity, setMinMaxElectricalConductivity] = React.useState<MinimumMaximum>({ min: undefined, max: undefined });
  const { numericSystem }: { numericSystem: string } = useSelector((state: any) => state.user);

  React.useEffect(() => {
    if (!soilTotalDatawithTemp.isError.valueOf() && soilTotalDatawithTemp.data) {
      const { series, dailyUsageMap } = convertsoilMoistureProfileDataSeries();
      setSoilMoistureProfileData((prevState) => {
        return {
          ...prevState,
          GraphData: series,
          dailyUsageMap
        }
      });
    }
  }, [soilTotalDatawithTemp.data])

  const convertsoilMoistureProfileDataSeries = () => {
    const series: any = {};
    const dailyUsageMap = new Map();
    //count the number of depth
    let depthCount: string[] = [];
    const lastIndex = soilTotalDatawithTemp.data?.data ? soilTotalDatawithTemp.data?.data.length - 1 : 0;
    for (let i = 1; i <= 12; i++) {
      const depthName = 'depth' + String(i);
      const depthTempName = depthName + 'Temp';
      const depthElectricalConductivityName = depthName + 'ElectricalConductivity';
      const depthNote = 'NotesDepth' + String(i);

      if (!deviceData.data?.value[0]?.hasOwnProperty(depthName)) continue;
      const depthValue = deviceData.data.value[0][depthName];
      if (!depthValue) continue;
      const name = valueFormatter(numericSystem, depthValue, "cm", true, 0);

      if (name) depthCount.push(name);

      if (typeof soilTotalDatawithTemp.data?.data[lastIndex] === 'object' && soilTotalDatawithTemp.data?.data[lastIndex].hasOwnProperty(depthName)) {
        series[depthName] = {
          type: 'line',
          name: 'Moisture',
          data: new Array<any>()
        };
        series[depthTempName] = {
          type: 'line',
          name: 'Temp',
          data: new Array<any>()
        };
        series[depthElectricalConductivityName] = {
          type: 'line',
          name: 'Electrical Conductivity',
          data: new Array<any>()
        };
        series[depthNote] = {
          type: 'line',
          name: 'Daily usage',
          data: new Array<any>()
        }
      }
    }

    setDepth(depthCount);
    let uniquefiedData = uniquefy(soilTotalDatawithTemp.data?.data ?? []);
    let _minMaxMoisture: MinimumMaximum = { min: undefined, max: undefined };
    let _minMaxTemp: MinimumMaximum = { min: undefined, max: undefined };
    let _minMaxElectricalConductivity: MinimumMaximum = { min: undefined, max: undefined };
    uniquefiedData = uniquefiedData.slice(ACTUAL_DATA_START_INDEX);

    for (let i = 1; i <= depthCount.length; i++) {
      const depth = 'depth' + String(i);
      const depthTemp = 'depth' + String(i) + 'Temp';
      const depthElectricalConductivity = 'depth' + String(i) + 'ElectricalConductivity';
      const depthNote = 'NotesDepth' + String(i);
      const tempDepthDailyUsage = new Map();

      let prevDateTime: string | null = null;

      uniquefiedData.forEach(element => {
        if (element.DateTime) {
          if (!_minMaxMoisture.min || element[depth] < _minMaxMoisture.min) {
            _minMaxMoisture.min = element[depth];
          }
          if (!_minMaxMoisture.max || element[depth] > _minMaxMoisture.max) {
            _minMaxMoisture.max = element[depth];
          }
          if (!_minMaxTemp.min || element[depthTemp] < _minMaxTemp.min) {
            _minMaxTemp.min = element[depthTemp];
          }
          if (!_minMaxTemp.max || element[depthTemp] > _minMaxTemp.max) {
            _minMaxTemp.max = element[depthTemp];
          }
          if (!_minMaxElectricalConductivity.max || element[depthElectricalConductivity] > _minMaxElectricalConductivity.max) {
            _minMaxElectricalConductivity.max = element[depthElectricalConductivity];
          }
          if (!_minMaxElectricalConductivity.min || element[depthElectricalConductivity] < _minMaxElectricalConductivity.min) {
            _minMaxElectricalConductivity.min = element[depthElectricalConductivity];
          }
          if (element[depthNote] && (prevDateTime === null || prevDateTime.split('T')[0] !== element.DateTime.split('T')[0])) {
            const tmpUsage = element[depthNote] !== null ? numericSystem === 'M' ? valueRounder(element[depthNote], 2) : valueRounder(Number(element[depthNote]) * 10, 2) : null;
            tempDepthDailyUsage.set(element.DateTime, tmpUsage)
          }

          dailyUsageMap.set(depth, tempDepthDailyUsage);

          const tmp = [element.DateTime, element[depth] ?? null];
          series[depth as keyof typeof series].data.push(tmp);

          const tmpTemp = [element.DateTime, element[depthTemp] ?? null];
          series[depthTemp as keyof typeof series].data.push(tmpTemp);

          const tmpElectricalConductivity = [element.DateTime, element[depthElectricalConductivity] ?? null];
          series[depthElectricalConductivity as keyof typeof series].data.push(tmpElectricalConductivity);

          prevDateTime = element.DateTime
        }
      })
    }

    setMinMaxMoisture(_minMaxMoisture);
    setMinMaxTemp(_minMaxTemp);
    setMinMaxElectricalConductivity(_minMaxElectricalConductivity);
    return { series, dailyUsageMap };
  };

  const uniquefy = (array: any[]) => {
    var seen: any = {};
    return array.filter((item) => {
      return seen.hasOwnProperty(item.DateTime) ? false : (seen[item.DateTime] = true);
    })
  }

  return (
    <SoilMoistureProfileContext.Provider value={{ depth, minMaxMoisture, minMaxTemp, minMaxElectricalConductivity, SoilMoistureGraphSeries: soilMoistureProfileData, loading: soilTotalDatawithTemp.isLoading }}>
      {props.children}
    </SoilMoistureProfileContext.Provider>
  );
}

export { SoilMoistureProfileContext, SoilMoistureProfileProvider };