// filter Constructor for GridilterModel type in Data Gird

import { GridFilterModel } from "@mui/x-data-grid";

enum filteringType {
    Contains = 'contains',
    Equals = 'equals',
    StartsWith = 'startsWith',
    EndsWith = 'endsWith',
    IsEmpty = 'isEmpty',
    IsNotEmpty = 'isNotEmpty',
    
}

export const filterConstructor = (filter: GridFilterModel) => {

};

export interface IrrigationForecastItemFilterType {
    field: string[];
    farm: string[];
    cropType: string[];
    variety: string[];
    status: string[];
    active: number[] // 0: inactive;  1: active;  -1:any
}

export const filterIrrigationForecastItem = (IrrigatioItemArray: any[], filter: IrrigationForecastItemFilterType) => {
    const fieldFilterValue = filter.field[0].toLowerCase(); // convert filter value to lowercase
    const farmFilterValues = filter.farm.map((value) => value.toLowerCase()); // convert filter values to lowercase
    const cropTypeFilterValues = filter.cropType.map((value) => value.toLowerCase()); // convert filter values to lowercase
    const varietyFilterValues = filter.variety.map((value) => value.toLowerCase()); // convert filter values to lowercase
    const statusFilterValues = filter.status.map((value) => value.toLowerCase()); // convert filter values to lowercase
    const activeFilterValues = filter.active.map((value)=> value === 1); // convert filter values to boolean

    const result = IrrigatioItemArray.filter((item) => {

        let fieldFilter = false;
        let farmFilter = false;
        let cropTypeFilter = false;
        let varietyFilter = false;
        let statusFilter = false;
        let activeFilter = false;

        if (fieldFilterValue === 'all' || item.fieldName.toLowerCase().includes(fieldFilterValue)) {
            fieldFilter = true;
        }
        if (farmFilterValues.includes('all') || farmFilterValues.includes(item.FarmName.toLowerCase())) {
            farmFilter = true;
        }
        if (cropTypeFilterValues.includes('all') || cropTypeFilterValues.includes(item.cropType.toLowerCase())) {
            cropTypeFilter = true;
        }
        if (varietyFilterValues.includes('all') || varietyFilterValues.includes(item.variety.toLowerCase())) {
            varietyFilter = true;
        }
        if (statusFilterValues.includes(item.status.toLowerCase())) {
            statusFilter = true;
        }
        if (activeFilterValues.some((value) => value === item.Active)) {
            activeFilter = true;
        }

        return fieldFilter && farmFilter && cropTypeFilter && varietyFilter && statusFilter && activeFilter;

    });

    return result;
};








