import { gql } from "@apollo/client";
import { initialState as initialFrontendValues, userState } from "../stores/Slices/userSlice";
import { secondaryClient } from "../api/apollo";

export function transformGridData() {

}

export const swap = (json: any) => {
  var ret: any = {};
  for (var key in json) {
    ret[json[key]] = key;
  }
  return ret;
}

export const updatePreferences = (userData: userState["tablePreference"] | userState["graphPreference"], preferenceType: "T" | "G") => {
  let isChanged = false;
  let modifiedPreference: userState["tablePreference"] | userState["graphPreference"] = { ...userData }

  if (modifiedPreference) {
    Object.keys(userData).forEach(key => {
      const missingInBackendData: any[] = [];
      const backendDataNames = userData[key].map(data => data.name);
      const frontendPreference = preferenceType === "T"
        ? initialFrontendValues.tablePreference[key]
        : initialFrontendValues.graphPreference[key];

      frontendPreference
        .map(item => item.name)
        .forEach((item, index) => {
          if (!backendDataNames.includes(item))
            missingInBackendData.push({ itemName: item, itemIndex: index })
        })

      const dataCopy = [...userData[key]]

      missingInBackendData.forEach(item => {
        dataCopy.splice(item.itemIndex, 0, frontendPreference[item.itemIndex])
      })

      modifiedPreference[key] = dataCopy

      if (missingInBackendData.length > 0 && !isChanged) isChanged = true;
    })
  }
  else {
    isChanged = true;
    modifiedPreference = preferenceType === "T"
      ? initialFrontendValues.tablePreference
      : initialFrontendValues.graphPreference;
  }

  if (isChanged) {
    const preferenceLabel = preferenceType == "T" ? "tablePreference" : "graphPreference";
    const SET_PREFERENCE_QUERY = gql(`
        mutation ($preferences: String!) {
            UpdateUserSetting (
                input: {
                    ${preferenceLabel}: $preferences
                    authToken: ${localStorage.getItem("access_token")}
                }
                ) {
                    ${preferenceLabel}
                }
            }`);


    secondaryClient.mutate({
      mutation: SET_PREFERENCE_QUERY,
      variables: { preferences: JSON.stringify(modifiedPreference) },
    })
      .catch((error) => console.error(error))


    return modifiedPreference;
  }
  else {

    return userData
  }

}
