import React, { useState } from "react";
import { GoannaDrawerProps } from "../../components/GoannaDrawer/GoannaDrawer";

interface GoannaDrawerContextType extends GoannaDrawerProps {
    openDrawer: (action: GoannaDrawerContextType["action"], content: GoannaDrawerContextType["content"]) => void;
    closeDrawer: () => void;
}

export const GoannaDrawerContext = React.createContext({} as GoannaDrawerContextType)

export default function GoannaDrawerProvider(props: { children: JSX.Element }) {
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState<GoannaDrawerContextType["action"]>("");
    const [content, setContent] = useState<GoannaDrawerContextType["content"]>([]);

    const openDrawer = (
        action: GoannaDrawerContextType["action"],
        content: GoannaDrawerContextType["content"],
    ) => {
        setOpen(true);
        setAction(action);
        setContent(content)
    }

    const closeDrawer = () => {
        setOpen(false);
    }

    return (
        <GoannaDrawerContext.Provider value={{ open: open, action, content, openDrawer, closeDrawer }}>
            {props.children}
        </GoannaDrawerContext.Provider>
    );
}
