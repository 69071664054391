import * as React from 'react';
import Popper from '@mui/material/Popper';

import { Switch, Paper, Button, createTheme, ThemeProvider, Typography, Divider, MenuItem, Select, FormControl, Box, FormGroup, Checkbox, FormControlLabel, ListItemIcon, ListItemText, SelectChangeEvent, OutlinedInput, Backdrop, RadioGroup, Radio, Grid } from '@mui/material';
import TerrainIcon from '@mui/icons-material/Terrain';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import SpaIcon from '@mui/icons-material/Spa';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import HowToRegIcon from '@mui/icons-material/HowToReg';

import styles from './style.module.css';
import { defaultFilterObject, FieldListComponentContext } from '../../../pages/Dashboard/FieldList/FieldListComponent';
import { useTranslation } from 'react-i18next';
import { IrrigationForecastItemFilterType } from '../../../utils/filter';


export interface IFilterButtonProps {
    onClick: React.MouseEventHandler;
    isActive: boolean
}

const theme = createTheme({
    typography: {
        fontFamily: 'poppins',
        button: {
            textTransform: 'none'
        }
    },
    palette: {
        primary: {
            main: '#0958FF'
        }
    }
});

const filterButtonTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins',
                    background: 'linear-gradient(to bottom, #75B9F0, #1085FD)',
                    width: '50%'
                }
            }
        }
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    anchorOrigin: {
        vertical: "center",
        horizontal: "center"
    },
    transformOrigin: {
        vertical: "center",
        horizontal: "center"
    },
    getContentAnchorEl: null
};

export default function FilterButton(props: IFilterButtonProps) {

    const { farmList, cropTypeList, varietyList, filterObject, setFilterObject, handleFilterApply } = React.useContext(FieldListComponentContext);

    const [on, setOn] = React.useState(false);
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const { t } = useTranslation("FieldListTopBar");

    const handleClick = (event: any) => {
        setOn(!on);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const [check, setCheck] = React.useState({
        OK: false,
        Irrigate: false,
        Stress: false,
    });

    const handleCheckChange = (event: any) => {
        const tmp = filterObject.status.slice();
        const name = event.target.name;
        const isChecked = event.target.checked;
        if (filterObject.status.includes(name) && !isChecked) {
            const index = tmp.indexOf(name);
            if (index > - 1) {
                tmp.splice(index, 1);
            }
        }
        if (!filterObject.status.includes(name) && isChecked) {
            tmp.push(name);
        }
        setFilterObject((prevObj: any) => {
            return {
                ...prevObj,
                status: tmp
            }
        })

        setCheck({
            ...check,
            [event.target.name]: event.target.checked,
        });
    };

    const handleCancel = (event: any) => {
        setOn(!on);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleReset = (event: any) => {
        handleFilterApply(defaultFilterObject);
        setFilterObject(defaultFilterObject);
        setOn(!on);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleShowResult = (event: any) => {
        handleFilterApply(filterObject);
        setOn(!on);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleBackdropClick = (event: any) => {
        setBackdropOpen(false);
        setOn(!on);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }


    //handle farm list drop down
    // const farnManeSelectRef = React.useRef();
    // const [farmNameselected, setFarmNameSelected] = React.useState<any>([]);
    // const isAllFarmNameSelected =
    //     farmList.length > 0 && farmNameselected.length === farmList.length;

    // const handleFarmNameChange = (event: any) => {
    //     const value = event.target.value;

    //     if (value[value.length - 1] === "All") {
    //         setFarmNameSelected(farmNameselected.length === farmList.length ? [] : farmList);
    //         return;
    //     }
    //     setFarmNameSelected(value);
    // };

    // const handleFarmNameRenderValue = (selected: any) => {
    //     /* console.log("selected: ", selected); */

    //     if (farmList.length === selected.length) {
    //         return "All";
    //     }
    //     return selected.join(", ");
    // };
    const handleFarmNameChange = (event: any) => {
        setFilterObject((prevObj: any) => {
            return {
                ...prevObj,
                farm: [event.target.value]
            }
        })
    };

    //handlecropListDropDown
    const handleCropTypeChange = (event: any) => {
        setFilterObject((prevObj: any) => {
            return {
                ...prevObj,
                cropType: [event.target.value]
            }
        })
    };

    const handleVarietyChange = (event: any) => {
        setFilterObject((prevObj: any) => {
            return {
                ...prevObj,
                variety: [event.target.value]
            }
        })
    }

    const handleActiveCheckboxSelect = (event: any) => {
        const value = parseInt(event.target.value, 10);  // Convert string value to number
        if (event.target.checked) {
            // If the checkbox is checked, add the value to the array
            setFilterObject((prevState: IrrigationForecastItemFilterType) => ({
                ...prevState,
                active: [...prevState.active, value]
            }));
        } else {
            // If the checkbox is unchecked, remove the value from the array
            setFilterObject((prevState: IrrigationForecastItemFilterType) => ({
                ...prevState,
                active: prevState.active.filter(item => item !== value)
            }));
        }
    };

    return (
        <>
            <button className={on ? `${styles.button} ${styles.buttonOn}` : `${styles.button} ${styles.buttonOff}`} type="button" onClick={handleClick}>
                <svg className={on ? `${styles.svgIcon} ${styles.svgIconOn}` : `${styles.svgIcon} ${styles.svgIconOff}`}
                    role="img"
                    height="10"
                    width="10"
                    viewBox="0 0 30 30"
                    aria-hidden="true"
                    focusable="false">
                    <path id="filter" d="M30,1.875v3.75H10.688a3.647,3.647,0,0,1-6.375,0H0V1.875H4.313a3.647,3.647,0,0,1,6.375,0ZM22.5,11.25a3.629,3.629,0,0,0-3.187,1.875H0v3.75H19.313a3.647,3.647,0,0,0,6.375,0H30v-3.75H25.688A3.629,3.629,0,0,0,22.5,11.25ZM15,22.5a3.629,3.629,0,0,0-3.187,1.875H0v3.75H11.813a3.647,3.647,0,0,0,6.375,0H30v-3.75H18.188A3.629,3.629,0,0,0,15,22.5Z" />
                </svg>
            </button>
            <Backdrop
                style={{ zIndex: 1000 }}
                open={open} onClick={handleBackdropClick}>
            </ Backdrop>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
                disablePortal={false}
                style={{ zIndex: 1001 }}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                        options: {
                            altBoundary: true,
                            rootBoundary: 'document',
                            padding: 8,
                        },
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            altAxis: true,
                            altBoundary: true,
                            tether: true,
                            rootBoundary: 'document',
                            padding: 8,
                        },
                    },
                ]}>
                <div className={styles.popperWrapper}>
                    <ThemeProvider theme={theme}>
                        <Paper>
                            <div className={styles.popperheader}>
                                <div className={styles.cancelButton} ><Button variant='text' color='primary' onClick={handleCancel}>{t("FilterButton-Cancel")}</Button></div>
                                <div className={styles.headerText}><Typography fontWeight='500'>{t("FilterButton-Filters")}</Typography></div>
                                <div className={styles.resetButton}><Button variant='text' color='primary' onClick={handleReset}>{t("FilterButton-ResetToDefault")}</Button></div>
                            </div>
                            <Divider />
                            <div className={styles.popperSectionWrapper}>
                                <div className={styles.textIcon}>
                                    <TerrainIcon color='disabled' />
                                    <Typography sx={{ marginLeft: '10px' }}>Farm</Typography>
                                </div>
                                <div className={styles.selectorWrapper}>
                                    {/* <Select
                                        ref={farnManeSelectRef}
                                        labelId="select-label"
                                        multiple
                                        fullWidth
                                        value={farmNameselected}
                                        onChange={handleFarmNameChange}
                                        renderValue={handleFarmNameRenderValue}
                                        size='small'
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                    width: 250,
                                                },
                                            },
                                            // anchorEl: farnManeSelectRef.current
                                        }}
                                    >
                                        <MenuItem
                                            value="All"
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={isAllFarmNameSelected}
                                                    indeterminate={
                                                        farmNameselected.length > 0 && farmNameselected.length < farmList.length
                                                    }
                                                />
                                            </ListItemIcon>
                                            All
                                        </MenuItem>
                                        {farmList.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                <ListItemIcon>
                                                    <Checkbox checked={farmNameselected.indexOf(item) > -1} />
                                                </ListItemIcon>
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    <Select
                                        labelId="select-label"
                                        id="select-cropType"
                                        value={filterObject.farm[0]}
                                        label=""
                                        fullWidth
                                        size='small'
                                        onChange={handleFarmNameChange}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                    width: 250,
                                                },
                                            },
                                            // anchorEl: farnManeSelectRef.current
                                        }}
                                    >
                                        <MenuItem
                                            value="All"
                                        >
                                            All
                                        </MenuItem>
                                        {farmList.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <Divider />
                            <div className={styles.popperSectionWrapper}>
                                <div className={styles.textIcon}>
                                    <LocalFloristIcon color='disabled' />
                                    <Typography sx={{ marginLeft: '10px' }}>Crop Type</Typography>
                                </div>
                                <div className={styles.selectorWrapper}>
                                    <Select
                                        labelId="select-label"
                                        id="select-cropType"
                                        value={filterObject.cropType[0]}
                                        label=""
                                        fullWidth
                                        size='small'
                                        onChange={handleCropTypeChange}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                    width: 250,
                                                },
                                            },
                                            // anchorEl: farnManeSelectRef.current
                                        }}
                                    >
                                        <MenuItem
                                            value="All"
                                        >
                                            All
                                        </MenuItem>
                                        {cropTypeList.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <Divider />
                            <div className={styles.popperSectionWrapper}>
                                <div className={styles.textIcon}>
                                    <SpaIcon color='disabled' />
                                    <Typography sx={{ marginLeft: '10px' }}>Variety</Typography>
                                </div>
                                <div className={styles.selectorWrapper}>
                                    <Select
                                        labelId="select-label"
                                        id="select-cropType"
                                        value={filterObject.variety[0]}
                                        label=""
                                        fullWidth
                                        size='small'
                                        onChange={handleVarietyChange}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                    width: 250,
                                                },
                                            },
                                            // anchorEl: farnManeSelectRef.current
                                        }}
                                    >
                                        <MenuItem
                                            value="All"
                                        >
                                            All
                                        </MenuItem>
                                        {varietyList.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            {/* <Divider />
                            <div className={styles.popperSectionWrapper}>
                                <div className={styles.textIcon}>
                                    <CompareArrowsIcon color='disabled' />
                                    <Typography sx={{ marginLeft: '10px' }}>View Forecast Changes</Typography>
                                </div>
                                <div>
                                    <Switch color='primary' />
                                </div>
                            </div>
                            <Divider />
                            <div className={styles.popperSectionWrapper}>
                                <div className={styles.textIcon}>
                                    < HowToRegIcon color='disabled' />
                                    <Typography sx={{ marginLeft: '10px' }}>View My Plan</Typography>
                                </div>
                                <div>
                                    <Switch color='primary' />
                                </div>
                            </div> */}
                            <Divider />
                            <div className={styles.popperFieldStatus}>
                                <Box sx={{ display: 'flex', gap: "40px" }}>
                                    <FormControl>
                                        <Typography><strong>Field Status</strong></Typography>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox color='primary' checked={filterObject.status.includes('OK')} onClick={handleCheckChange} name="OK" />
                                                }
                                                sx={{
                                                    marginLeft: "0px",
                                                    justifyContent: "space-between",
                                                }}
                                                label="OK"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox color='primary' checked={filterObject.status.includes('Irrigate')} onClick={handleCheckChange} name="Irrigate" />
                                                }
                                                sx={{
                                                    marginLeft: "0px",
                                                    justifyContent: "space-between"
                                                }}
                                                label="Irrigate"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={filterObject.status.includes('Stress')} onClick={handleCheckChange} name="Stress" />
                                                }
                                                sx={{
                                                    marginLeft: "0px",
                                                    justifyContent: "space-between"
                                                }}
                                                label="Overdue"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox color='primary' checked={filterObject.status.includes('N/A')} onClick={handleCheckChange} name="N/A" />
                                                }
                                                sx={{
                                                    marginLeft: "0px",
                                                    justifyContent: "space-between"
                                                }}
                                                label="N/A"
                                                labelPlacement="start"
                                            />
                                        </FormGroup>
                                        </FormControl>
                                        <FormControl>
                                        <Typography><strong>Active</strong></Typography>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox color='primary' checked={filterObject.active.includes(1)} onClick={handleActiveCheckboxSelect} value={1} />
                                                }
                                                labelPlacement="start"
                                                sx={{
                                                    marginLeft: "0px",
                                                    justifyContent: "space-between"
                                                }}
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox color='primary' checked={filterObject.active.includes(0)} onClick={handleActiveCheckboxSelect} value={0} />
                                                }
                                                label="No"
                                                sx={{
                                                    marginLeft: "0px",
                                                    justifyContent: "space-between"
                                                }}
                                                labelPlacement="start"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                            </div>
                            <Divider />
                            <ThemeProvider theme={filterButtonTheme}>
                                <div className={styles.submitButtom}>
                                    <Button variant='contained' onClick={handleShowResult}>{t("FilterButton-showFieldsButton")}</Button>
                                </div>
                            </ThemeProvider>
                        </Paper>
                    </ThemeProvider>
                </div>
            </Popper>
        </>
    );
}
