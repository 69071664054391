import { ErrorPolicy, gql, useLazyQuery, useMutation } from "@apollo/client";
import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { secondaryClient } from "../../api/apollo";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";


export type shortCutPagesValueType = { name: string, path?: string }
// export type shortCutPagesType = shortCutPagesValueType[]

export interface userState {
    userID: string;
    role: string[];
    Fname: string;
    Lname: string;
    email: string;
    phone: string;
    Avatar: string;
    preferredFields: number[];
    tablePreference: {
        [key: string]: {
            name: string;
            checked: number;
        }[],
    };
    graphPreference: {
        [key: string]: {
            name: string;
            checked: number;
        }[]
    };
    dashBoardPreference: number[];
    shortCutPages: shortCutPagesValueType[];
    numericSystem: string;
    dateRange: string;
    timezone: {
        name: string;
        code: string;
        ID: number;
        AESTDifference: number;
    };
    language: string;
    debug: boolean;
    sortModel: SortModel;
    filterModel: FilterModel
}

export interface SortModel {
    [key: string] : GridSortModel;
}

export interface FilterModel {
    [key: string] : GridFilterModel;
}

export const initialState: userState = {
    userID: '',
    role: [],
    Fname: '',
    Lname: '',
    email: '',
    phone: '',
    Avatar: 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-High-Quality-Image.png',
    preferredFields: new Array<number>(),
    tablePreference: {
        "ProbeTable": [
            { name: "field", checked: 1 },
            { name: "today", checked: 1 },
            { name: "forecast", checked: 1 },
            { name: "irrforecast", checked: 1 },
            { name: "active", checked: 1 },
            { name: "lst_update", checked: 1 },
            { name: "tz", checked: 1 },
            { name: "fp", checked: 1 },
            { name: "rp", checked: 1 },
            { name: "curr", checked: 1 },
            { name: "currUsage", checked: 1 },
            { name: "etc", checked: 1 },
            { name: "cuEtc", checked: 1 },
            { name: "tdd", checked: 1 },
            { name: "startDate", checked: 0 },
            { name: "plantingDate", checked: 0 },
            { name: "farm", checked: 0 },
            { name: "region", checked: 0 },
            { name: "cropName", checked: 0 },
            { name: "variety", checked: 0 },
            { name: "accountName", checked: 0 },
            { name: "defaultUsage", checked: 0 },
            { name: "irroforecastDefault", checked: 1 },
        ]
    },
    graphPreference: {
        "ProbeGraphs": [
            { name: "sch_cal", checked: 1 },
            { name: "soil_tot", checked: 1 },
            { name: "stress", checked: 1 },
            { name: "smp&rootzone", checked: 1 },
            { name: "Location", checked: 1 },
            { name: "rainfall", checked: 1 },
            { name: "crop", checked: 1 },
            { name: "Irrigation", checked: 1 },
            { name: "etc_wu", checked: 1 },
            { name: "cdd", checked: 1 },
            { name: "notes", checked: 1 },
        ]
    },
    dashBoardPreference: new Array<number>(),
    shortCutPages: [] as shortCutPagesValueType[],
    numericSystem: 'M',
    dateRange: "60",
    timezone: {
        name: "USA - Central Daylight Time (DLS Effected)",
        code: "USCST",
        ID: 28,
        AESTDifference: -15
    }, // USCST set as default timezone
    language: 'enAU',
    debug: false,
    sortModel: {
        "ProbeTable": [
            {
              field: "IrrigationDueActual",
              sort: "asc"
            }
          ]
    },
    filterModel: {
        "ProbeTable": {
            items: [
              {
                columnField: 'LocationDescription',
                operatorValue: 'contains',
              },
            ],
          }
    }
};


const SET_DASHBOARD_PREFRENCES = gql(`
mutation ($prefrences: String!) {
  UpdateUserSetting (
    input: {
        dashBoardPreference: $prefrences
      authToken: ${localStorage.getItem("access_token")}
    }
  ) {
    dashBoardPreference
  }
}
`);

const SET_SHORTCUT_PAGES = gql(`
mutation ($prefrences: String!) {
  UpdateUserSetting (
    input: {
        shortCutPages: $prefrences
      authToken: ${localStorage.getItem("access_token")}
    }
  ) {
    shortCutPages
  }
}
`);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<userState>) => {
            state.userID = action.payload.userID;
            state.preferredFields = action.payload.preferredFields;
            state.tablePreference = action.payload.tablePreference;
            state.graphPreference = action.payload.graphPreference;
            state.dashBoardPreference = action.payload.dashBoardPreference;
            state.shortCutPages = action.payload.shortCutPages;
            state.numericSystem = action.payload.numericSystem;
            state.dateRange = action.payload.dateRange;
            state.timezone = action.payload.timezone;
            state.language = action.payload.language;
            state.role = action.payload.role;
            state.sortModel = action.payload.sortModel;
            state.filterModel = action.payload.filterModel;
        },
        setUserId: (state, action: PayloadAction<string>) => {
            state.userID = action.payload;
        },
        setRole: (state, action: PayloadAction<string[]>) => {
            state.role = action.payload;
        },
        setFname: (state, action: PayloadAction<string>) => {
            state.Fname = action.payload;
        },
        setLname: (state, action: PayloadAction<string>) => {
            state.Lname = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload;
        },
        setAvatar: (state, action: PayloadAction<string>) => {
            state.Avatar = action.payload;
        },
        setPreferredFields: (state, action: PayloadAction<number[]>) => {
            // TODO: add a middleware to handle these queries
            // client.mutate({ mutation: SET_PREFERRED_FIELDS, variables: { prefrences: JSON.stringify(action.payload) } })
            //     .catch(e => /* console.log(e.message)); */
            state.preferredFields = action.payload;
        },
        setTablePreference: (state, action: PayloadAction<userState["tablePreference"]>) => {
            // console.log('table set', action.payload);
            state.tablePreference = action.payload;
        },
        setGraphPreference: (state, action: PayloadAction<userState["graphPreference"]>) => {
            // console.log('table set', action.payload);

            state.graphPreference = action.payload;
        },
        setDashboardPreference: (state, action: PayloadAction<number[]>) => {
            secondaryClient.mutate({ mutation: SET_DASHBOARD_PREFRENCES, variables: { prefrences: JSON.stringify(action.payload) } })
                .catch(e => { })
            state.dashBoardPreference = action.payload
        },
        setShortCutPages: (state, action: PayloadAction<shortCutPagesValueType[]>) => {
            // secondaryClient.mutate({ mutation: SET_SHORTCUT_PAGES, variables: { prefrences: JSON.stringify(action.payload) } })
            //     .catch(e => { })
            state.shortCutPages = action.payload;
        },
        setNumericSystem: (state, action: PayloadAction<string>) => {
            state.numericSystem = action.payload;
        },
        setDateRange: (state, action: PayloadAction<string>) => {
            state.dateRange = action.payload;
        },
        setTimeZone: (state, action: PayloadAction<userState["timezone"]>) => {
            state.timezone = action.payload;
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        },
        setDebug: (state, action: PayloadAction<boolean>) => {
            state.debug = action.payload;
        },
        setSortModel: (state, action: PayloadAction<userState["sortModel"]>) => {
            state.sortModel = action.payload;
        },
        setFilterModel: (state, action: PayloadAction<userState["filterModel"]>) => {
            state.filterModel = action.payload;
        },
    }
});

export const {
    setUser,
    setUserId,
    setRole,
    setFname,
    setLname,
    setEmail,
    setPhone,
    setAvatar,
    setPreferredFields,
    setTablePreference,
    setGraphPreference,
    setDashboardPreference,
    setShortCutPages,
    setNumericSystem,
    setDateRange,
    setTimeZone,
    setLanguage,
    setDebug,
    setSortModel,
    setFilterModel
} = userSlice.actions;

export default userSlice.reducer;
