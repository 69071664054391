import { Link, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useAppSelector } from '../../stores/hooks'
import { Image } from '@mui/icons-material';
import styles from './style.module.css'

export interface IErrorPage {
    title: string,
    description1: JSX.Element,
    description2: JSX.Element,
    imageURL: string,
    showHelpEmail?: boolean
}

export default function ErrorPage({ title, description1, description2, imageURL, showHelpEmail = true }: IErrorPage) {
    const { isMobile, dimensions } = useAppSelector(state => state.topbar);

    return (
        <Box sx={{
            display: "flex",
            justifyContent: dimensions.width < 500 ? "flex-start" : "center",
            alignItems: "center",
            height: isMobile ? "auto" : "100vh",
            flexDirection: dimensions.width < 500 ? "column" : "row",
            gap: isMobile ? "20px" : "156px",
            margin: isMobile ? "20px" : 0,
        }}>
            <img src={imageURL} className={styles.sideImage} />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                width={dimensions.width < 500 ? "100%" : "500px"}
                height={dimensions.width < 500 ? "40%" : "100%"}
            >
                <Typography
                    variant="h4"
                    variantMapping={{ "h4": "h1" }}
                    fontSize="36px"
                    fontFamily="Montserrat"
                    fontWeight="bold"
                    color="#FA6980"
                    marginBottom="18px"
                    sx={{ textDecoration: "underline", textDecorationColor: "#0F68FD", textUnderlineOffset: "10px" }}
                >
                    {title}
                </Typography>
                <Typography fontWeight="regular" fontFamily="Poppins">
                    {description1}
                </Typography>
                {
                    showHelpEmail
                        ? <ul style={{ marginLeft: "-20px", fontSize: '15px' }}>
                            <li>
                                <Typography fontSize='15px' fontWeight="regular">Email: <Link href="mailto:help@goannaag.com.au">help@goannaag.com.au</Link></Typography>
                            </li>
                            <li>
                                <Typography fontSize='15px' fontWeight="regular">Phone: <Link href="tel:0749713790">07 4671 3790</Link></Typography>
                            </li>
                        </ul>
                        : undefined
                }
                <Typography fontWeight="regular">
                    {description2}
                </Typography>
            </Box>
        </Box>
    )
}