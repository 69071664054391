import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { http } from '../../api/http';


const PATH: string = 'odata/TimeZones';

const useTimeZones = () => {

    const query = PATH;
    const fetchData = async () => {
        const response: any = await http.get(query);
        return response;
    };

    const data = useQuery({ queryKey: ['TimeZones'], queryFn: fetchData, enabled: false });

    return data;
}

export default useTimeZones;