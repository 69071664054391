import * as React from 'react';
import { CumulativeDayDegreesContext } from '../../context/FieldDetailsContext/CumulativeDayDegreesContext';

const useCumulativeDayDegreesContext = () => {
    const context = React.useContext(CumulativeDayDegreesContext);

    if (context === undefined) {
        throw new Error("useCumulativeDayDegreesContext was used outside of its Provider");
      }
    return context;
}

export default useCumulativeDayDegreesContext;
