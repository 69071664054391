import * as React from 'react';
import FieldDetailPaper from '../../../../../components/FieldDetailPaper/FieldDetailPaper';
import FieldDetailHeader from '../../../../../components/FieldDetailPaper/FieldDetailHeader';
import styles from './style.module.css';
import FieldDetailBody from '../../../../../components/FieldDetailPaper/FieldDetailBody';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GoannaButton from '../../../../../components/GoannaButton/GoannaButton';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import { FieldDetailDataType } from '../../../../../context/FieldDetailsContext/FieldDetailsContext';
import { http } from '../../../../../api/http';
import { useSnackbar } from 'notistack';
import './style.css'

export interface INotesProps {
}

export default function Notes(props: INotesProps) {
    // let Quill = ReactQuill.Quill;
    const quil = React.useRef<any>();
    const imageHandler = () => {
        if (quil.current) {
            let quillObj = quil.current.getEditor();
            const range = quillObj.getSelection();
            const value = prompt('Please copy-paste the image URL here.');
            if (value) {
                quillObj.editor.insertEmbed(range.index, 'image', value);
            }
        }
    };

    const modules = {
        toolbar: {
            container: [
                ["bold"], ["italic"], ["underline"], ["strike"], ["blockquote"],
                [{ list: "ordered" }],
                [{ list: "bullet" }],
                [{ indent: "-1" }],
                [{ indent: "+1" }],
                ["image"], ["clean"],
            ],
            // handlers: {
            //     image: imageHandler
            // }
        },
    };

    const formats = [
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "image",
    ];

    const FieldDetailContext: FieldDetailDataType = useFieldDetailContext();
    const locationDevicesData = FieldDetailContext.locationDevicesData.data?.data.value[0] ?? {};
    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const [notes, setNotes] = React.useState<string>(locationDevicesData.Notes ?? '');
    React.useEffect(() => {
        if (locationDevicesData.Notes) {
            setNotes(locationDevicesData.Notes);
        }
    }, [locationDevicesData])

    const { enqueueSnackbar } = useSnackbar();

    const handleSave = () => {

        const url = `odata/Locations(${locationDevicesData.LocationID})`
        let responseBody: any = locationDevicesData;
        setIsSaving(true);
        responseBody.Notes = notes;
        http.put(url, responseBody)
            .then(() => {
                enqueueSnackbar("Notes update successful.", { variant: "success", persist: false });
                setIsSaving(false);
            })
            .catch((error) => {
                enqueueSnackbar("Error! Notes update failed.", { variant: 'error', persist: false });
                setIsSaving(false);
            });
    };

    return (
        <FieldDetailPaper width='100%' id='Notes'>
            <FieldDetailHeader backgroundColor='#D9D7D780'>
                <div className={styles.headder}>
                    Notes
                </div>
            </FieldDetailHeader>
            <FieldDetailBody
                loading={isSaving || FieldDetailContext.locationDevicesData.isLoading}
                style={{
                    paddingTop: '0px',
                    marginLeft: '0px',
                    marginRight: '0px',
                    height: (isSaving || FieldDetailContext.locationDevicesData.isLoading) ? '320px' : undefined,
                    minHeight: '340px',
                }}
                className={styles.Body}
            >
                <div className={styles.Editor}>
                    <ReactQuill ref={quil} theme="snow" style={{
                        height: '100%',
                        borderBottomLeftRadius: '6px', // Rounded bottom-left corner
                        borderBottomRightRadius: '6px', // Rounded bottom-right corner
                        marginLeft: '28px',
                        marginRight: '28px',
                    }}
                        modules={modules}
                        formats={formats}
                        value={notes}
                        onChange={(value) => setNotes(value)}
                    />
                    {/* <QuillEditor value={notes} onChange={(value) => setNotes(value)} isSaving quillInstanceRef/> */}
                    <div className={styles.saveButton}>
                        <GoannaButton
                            title='Save'
                            onClick={handleSave}
                            width='80px'
                            height='42px'
                        />
                    </div>
                </div>
            </FieldDetailBody>
        </FieldDetailPaper>
    );
}
