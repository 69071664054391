import { Box } from '@mui/material';
import * as React from 'react';

export interface IBasicCardProps {
    title: React.ReactNode,
    titleRight?: React.ReactNode,
    children: React.ReactNode,
}

export default function BasicCard(props: IBasicCardProps) {
    const roundness = '15px'
    return (
        <Box
            className="graph-stats"
            height={350}
            width={400}
            flex={"0 1 430px"}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            style={{
                background: "white",
                borderRadius: roundness,
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",

            }}
        >
            <Box
                height="20%"
                width="auto"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems={"center"}
                style={{
                    background: "rgb(170, 200, 251) ",
                    borderRadius: roundness + " " + roundness + " 0px 0px",
                }}
            >
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"0"}
                    style={{
                        paddingLeft: "20px"
                    }}
                >
                    {
                        props.title
                    }
                </Box>
                {
                    props.titleRight
                }
            </Box>
            <Box
                height={'80%'}
                style={{
                    overflow: 'scroll',
                    scrollbarWidth: 'none'
                }}
            >
                {
                    props.children
                }
            </Box>
        </Box >
    );
}
