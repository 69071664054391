import { Box, Card, CardContent, CardHeader, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Link as a, useNavigate } from "react-router-dom";
import styles from './style.module.css'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "./ConfirmDialog";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch, useSelector } from "react-redux";
import { AddRemoveButtonDisplay, renderAddRemoveIcon } from "../../../components/GoannaCard/GoannaCardHelper";
import { setShortCutPages, shortCutPagesValueType } from "../../../stores/Slices/userSlice";
import { useAppSelector } from "../../../stores/hooks";
import { RootState } from "../../../stores/store";
import useShortcutPages from "../../../hooks/ApiHooks/useShortcutPages";
import useUserSettings from "../../../hooks/ApiHooks/useUserSettings";

interface IShortcutsCardProps {
  className?: string;
  editMode: Boolean;
  showAddRemoveIcon: AddRemoveButtonDisplay;
  addRemoveClickCallback: (iconDisplay: AddRemoveButtonDisplay) => void;
}

const OPTIONS = [
  "Remove all Items"
]

const ITEM_HEIGHT = 48;

function ShortcutsCard(props: IShortcutsCardProps) {
  const { t } = useTranslation('ShortcutsCard')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // const shortCutPages = useAppSelector((state: RootState) => state.user.shortCutPages);
  const [saveShortCutPages, { data, loading, error }, shortCutPages] = useShortcutPages();
  const { refreshReduxFromGraphQL } = useUserSettings();

  //refresh localstorage for user who stored path name as forecasts/
  React.useEffect(() => {
    refreshReduxFromGraphQL()
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const saveItems = (_items: shortCutPagesValueType[]) => { saveShortCutPages(_items) };

  const handleClose = (option: string) => {
    setAnchorEl(null);
    switch (option) {
      case OPTIONS[0]: // delete all
        setDialogOpen(true);
        break;

      default:
        break;
    }
  };

  const handleSingleItemDeleteClick = (item: shortCutPagesValueType) => {
    let itemsCopy = [...shortCutPages] // shallow copy
    itemsCopy.splice(itemsCopy.findIndex((_item) => item.name == _item.name), 1);
    saveItems(itemsCopy);
  }

  function handleDeleteAll() {
    saveItems([]);
    setDialogOpen(false);
  }

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <div className={props.className}>
      <Card
        id={styles.ShortcutsCard}
      >
        <CardHeader
          sx={{
            font: 'bold 14px/20px Poppins',
          }}
          title={<div className={styles.Title}>{t("ShortcutsCard-title")}</div>}
          subheader={<div style={{ font: 'bold 14px/20px Poppins', color: '#fff' }}>&nbsp;</div>}
          action={
            <>
              <IconButton sx={{ marginTop: "9px", marginRight: "4px", width: "40px", height: '40px', '&:hover': { background: "rgba(9, 88, 255, 0.04)" } }} onClick={(e) => props.editMode ? props.addRemoveClickCallback(props.showAddRemoveIcon) : handleClick(e)}>
                {/* This is a if else condition | its easy to understand it this way */}
                {shortCutPages.length > 0 && !props.editMode ? <MoreHorizIcon sx={{ fill: "#0058FF" }} /> : undefined}
                {props.editMode ? renderAddRemoveIcon(props.showAddRemoveIcon) : undefined}
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose('')}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                    marginLeft: '-115px'
                  },
                }}
              >
                {OPTIONS.map((option) => (
                  <MenuItem key={option} onClick={() => handleClose(option)}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </>
          }
        />
        <CardContent sx={{ padding: 0 }}>
          <div
            className={styles.List}
          >
            {shortCutPages.length == 0 ? <Divider /> : undefined}
            {shortCutPages.map((item: shortCutPagesValueType) => (
              <Box key={item.path + item.name} sx={{ cursor: "pointer", '&:hover': { background: "#f6f6f6" } }}>
                <Divider />
                <div className={styles.LinkText}>
                  <IconButton sx={{ width: "40px", height: '40px', '&:hover': { background: "rgba(9, 88, 255, 0.04)" } }} onClick={() => handleSingleItemDeleteClick(item)}>
                    <StarIcon sx={{ fill: "#0958FF" }} />
                  </IconButton>

                  <a href={item.path ? item.path.replace(/\/([A-Z])/g, (_, letter) => `/${letter.toLowerCase()}`) : ""}>
                    {item.name}
                    <ExpandLessIcon sx={{ transform: 'rotate(90deg)' }} />
                  </a>
                </div>
              </Box>
            ))}
          </div>
        </CardContent>
      </Card>
      <ConfirmDialog
        open={dialogOpen}
        title={"Would you really like to delete all shortcuts?"}
        closeDialogBox={() => setDialogOpen(false)}
        confirmAction={handleDeleteAll}
      />
    </div >
  )
}

ShortcutsCard.defaultProps = {
  Items: []
}

export default ShortcutsCard;