import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, AlertColor, Box, Button, CircularProgress, createTheme, LinearProgress, Snackbar, ThemeProvider, Typography } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import axios from "axios";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { secondaryClient } from "../../../api/apollo";
import { http } from "../../../api/http";
import RouterBreadCrumbs from "../../../components/BreadCrumbs/RouterBreadCrumbs";
import GoannaButton from "../../../components/GoannaButton/GoannaButton";
import useFieldTableData, { FieldCell } from "../../../hooks/useFieldTableData";
import { setPreferredFields } from "../../../stores/Slices/userSlice";
import styles from './style.module.css';
import { useSnackbar } from 'notistack';
import { UpdateTabTitle } from "../../../utils/updateTabTitle";
import { DateTime } from "luxon";
import { dateFormatter } from "../../../utils/numericConversions";
import moment from "moment";
import { AEST_Zone } from "../../../utils/DateConvertor";
import React from "react";
import useTopBarTitle from "../../../hooks/TopbarHooks/useTopBarTitle";

interface IMyFieldsProps {

}

// const theme = createTheme({
//   components: {
//     div: {
//       styleOverrides: {
//         root: {
//           fontFamily: 'Poppins',
//           background: 'linear-gradient(to right, #75B9F0, #1085FD)',
//           width: '200px',
//           height: '48px',
//           marginTop: '92px',
//           marginBottom: '20px',
//         }
//       }
//     }
//   }
// });

const DataGridTheme = createTheme(
  {
    palette: {
      primary: { main: '#0958FF' },
    },
  },
);

const SET_PREFERRED_FIELDS = gql(`
mutation ($prefrences: String!, $token: String!) {
  UpdateUserSetting (
    input: {
        preferredFields: $prefrences
      	authToken: $token
    }
  ) {
    preferredFields
  }
}
`);

const MyFields: FunctionComponent<IMyFieldsProps> = () => {

  const { t } = useTranslation("MyFields");
  const { setTopBarTitle } = useTopBarTitle();
  React.useEffect(() => {
    setTopBarTitle('My Fields');
    UpdateTabTitle('My Fields');
  }, [])

  const getAllUserDevices = useFieldTableData();

  const irrigationForecastSort = (a: any, b: any, cellParams1: any) => {

    const sortModel = cellParams1.api.getSortModel();

    if (sortModel[0].sort == "asc") {
      if (a === null) {
        return 1;
      }
      if (b === null) {
        return -1;
      }
    } else {
      if (a === null) {
        return -1;
      }
      if (b === null) {
        return 1;
      }
    }
    // return (a || '').localeCompare((b || ''));
    if (a instanceof Date || b instanceof Date) {
      return DateTime.fromJSDate(a).toMillis() - DateTime.fromJSDate(b).toMillis();
    }
    return DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis();
    // a instanceof Date || b instanceof Date ?
    // DateTime.fromJSDate(a).toMillis() - DateTime.fromJSDate(b).toMillis()
    // :
    // DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis();
  }

  const { numericSystem }: { numericSystem: string } = useSelector((state: any) => state.user)

  const [activeOnly, setActiveOnly] = useState(true);

  const dateFormatter = (params: any, dateOnly: boolean = false) => {
    if (dateOnly) {
      if (params.value) {
        if (numericSystem == "M")
          return moment(params?.value).format("DD/MM/YYYY");
        else
          return moment(params?.value).format("MM/DD/YYYY");
      }
    }
    if (params.value) {
      if (numericSystem == "M")
        return moment(params?.value).format("DD/MM/YYYY hh:mm A");
      else
        return moment(params?.value).format("MM/DD/YYYY hh:mm A");
    }
    return '';

  }

  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [PageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const { preferredFields }: { preferredFields: number[] } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleDataGridChange = async () => {

    setLoading(true);

    const getAllUserDevicesPromise = getAllUserDevices(activeOnly ? "Active eq true" : undefined);

    const getDeviceCategoriesPromise = http.get(process.env.REACT_APP_AXIOS_URL + "/odata/DeviceCategories?%24format=json&%24top=50&%24orderby=DeviceCategory&%24count=true&_=1667965425358")
      .then((data) => data.data);

    Promise.all([getAllUserDevicesPromise, getDeviceCategoriesPromise]).then(([allUserDevices, deviceCategories]) => {
      const newrows = allUserDevices.row.map((row) => {
        const categoryObject = deviceCategories.value.find((category: any) => category.DeviceCategoryID == row.DeviceCategoryID)
        const categoryName = categoryObject == undefined ? null : categoryObject.DeviceCategory;
        return {
          LocationID: row.LocationID,
          LocationDescription: row.LocationDescription,
          FarmName: row.FarmName,
          DeviceCategoryID: categoryName,
          StartDate: row.StartDate,
          PlantingDate: row.PlantingDate,
          Active: row.Active
        }
      });
      setRows(newrows);

      let _cols: any[] = [];

      allUserDevices.columns.push({
        field: 'DeviceCategoryID',
        headerName: t('MyFields-columns.deviceCategoryHeader'),
        width: 150,
      });

      ['LocationDescription', 'FarmName', 'DeviceCategoryID', 'StartDate', 'PlantingDate', 'Active'].forEach(element => {
        _cols.push(allUserDevices.columns.find(a => a.field === element))
      });

      setColumns(_cols);

      // setColumns(allUserDevices.columns.filter((a) => ['LocationDescription', 'FarmName', 'DeviceCategoryID', 'StartDate', 'PlantingDate', 'Active'].includes(a.field)));

      // select everything if nothing is selected initially
      if (preferredFields.length == 0) {
        setSelectionModel(newrows.map(a => a.LocationID));
      }

      setLoading(false);
    })
      // TODO: handle the error make a global error catching system
      .catch(e => { }/* console.log(e)) */)
  };

  useEffect(() => {
    handleDataGridChange();
  }, [activeOnly]);

  // middleware: will be moved to somewhere else later e.g. Thunk 
  const savePreferredFields = (prefFields: number[]) => {
    setLoading(true);
    secondaryClient.mutate(
      {
        mutation: SET_PREFERRED_FIELDS,
        variables: {
          // if select all put empty array
          prefrences: prefFields.length == rows.length ? "[]" : JSON.stringify(prefFields),
          token: localStorage.getItem("access_token")?.split("\"")[1]
        },
      }).then(
        () => {
          enqueueSnackbar({
            variant: "success",
            message: t("MyFields-preferredfields.successmessage")
          })
          setLoading(false);
        },
      ).catch(
        () => {
          enqueueSnackbar({
            variant: "error",
            message: t("MyFields-preferredfields.errormessage")
          })
          setLoading(false);
        }
      )
  }

  const [selectionModel, setSelectionModel] = useState<number[]>(preferredFields);

  // Workaround for state being not initialized with preferredFields
  useEffect(() => {
    setSelectionModel(preferredFields);
  }, [preferredFields])


  const handleSaveButtonClick = () => {

    dispatch(setPreferredFields(selectionModel));

    // show error here
    if (selectionModel.length == 0) {
      enqueueSnackbar({
        variant: "error",
        message: t("MyFields-savebutton.errormessage"),
      })
      setSelectionModel(preferredFields);
    }
    // save it to database
    else
      savePreferredFields(selectionModel);
  }

  const { debug }: { debug: boolean } = useSelector((state: any) => state.user);

  const roles = useSelector((state: any) => state.user).role;
  const isAdmin = roles.find((role: string) => role == "Goanna Administrators")

  if (isAdmin && !debug) {
    return <h1 style={{ marginLeft: '90px', marginTop: '40px' }}>{t('MyFields-adminwarning')}</h1>
  }

  return (
    <div style={{ marginLeft: '20px', marginRight: '20px' }}>
      <Box marginTop='22px' marginBottom='20px'>
        <RouterBreadCrumbs />
      </Box>
      <Box display='flex' marginBottom='10px' alignItems='center' justifyContent='space-between'>
        <Typography fontWeight='600'>
          {t("MyFields-subtitle")}
        </Typography>
        {activeOnly
          ? <GoannaButton width='238px' height='38px' onClick={() => setActiveOnly(false)} disabled={!activeOnly} title="Show Active and Inactive" />
          : <Typography color="red">(Showing Active and Inactive)</Typography>
        }
      </Box>
      <ThemeProvider theme={DataGridTheme}>
        <DataGridPro
          className={styles.DataGrid}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.LocationID}
          // disableColumnSelector
          pagination
          pageSize={PageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20, 50]}
          components={{
            Toolbar: GridToolbar,
            LoadingOverlay: () => <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'baseline',
              marginTop: "20px"
            }}><CircularProgress /></div>
          }}
          componentsProps={{
            // to disable export button
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
            }
          }}

          loading={loading}
          checkboxSelection={true}
          onSelectionModelChange={(model) => { setSelectionModel(model as number[]) }}

          selectionModel={selectionModel}
        />
      </ThemeProvider>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Poppins',
        height: '48px',
        marginTop: '42px',
        marginBottom: '20px',
      }}>
        <GoannaButton title={"Save"} onClick={handleSaveButtonClick} />
      </div>
    </div>
  );
}

export default MyFields;
