import React from "react";
import { Button, createTheme, ThemeProvider, CircularProgress } from "@mui/material";

interface IGoannaProps {
  title: string;
  onClick?: () => void;
  width?: string;
  height?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean; // New loading prop
  style?: React.CSSProperties;
}

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          background: 'linear-gradient(to right, #75B9F0, #1085FD)',
          width: '200px',
          height: '48px',
          ":disabled": {
            background: '#d0d0d0',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
          }
        }
      }
    }
  }
});

function GoannaButton(props: IGoannaProps) {
  return (
    <ThemeProvider theme={theme} >
      <Button
        variant="contained"
        onClick={() => props.onClick ? props.onClick() : undefined}
        className={props.className}
        disabled={props.disabled || props.loading}
        sx={{ width: props.width, height: props.height }}
        style={{
          ...props.style
        }}
      >
        {props.loading ? <CircularProgress size={24} sx={{color: '#838383'}}/> : props.title}
      </Button>
    </ThemeProvider>
  );
}

export default GoannaButton;
