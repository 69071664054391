import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    navbarOpen: false,
}

export const navbarSlice = createSlice({
    name: 'navbar',
    initialState,
    reducers: {
        toggleNavbarOpen: (state: any) => {
            state.navbarOpen = !state.navbarOpen;
        },
        setNavbarOpen: (state: any, action) => {
            state.navbarOpen = action.payload;
        },
    }
});

export const { toggleNavbarOpen, setNavbarOpen } = navbarSlice.actions;

export default navbarSlice.reducer;