import { useCallback, useEffect, useState } from 'react';
import LoginCard from './components/LoginCard';
import LoginText from './components/LoginText';
import styles from './styles.module.css';
import ResetPasswordCard from './components/ResetPasswordCard';
import { UpdateTabTitle } from '../../utils/updateTabTitle';
import { useSearchParams } from 'react-router-dom';
import NewPasswordCard from './components/NewPasswordCard';

enum UIStateEnum {
  Login,
  resetPassword,
  newPassword
}

function Login() {
  const [UIState, setUIState] = useState<UIStateEnum>(UIStateEnum.Login);
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username") || undefined;
  const token = searchParams.get("token") || undefined;

  function showResetPasswordUI() {
    setUIState(UIStateEnum.resetPassword)
  }

  function showLogInUI() {
    setUIState(UIStateEnum.Login)
  }

  const showNewPasswordUI = useCallback(
    function showNewPasswordUI() {
      if (username && token)
        setUIState(UIStateEnum.newPassword)
      else
        showLogInUI()
    },
    [token, username],
  )

  useEffect(() => {
    showNewPasswordUI()
  }, [showNewPasswordUI])

  UpdateTabTitle('Log in')

  const currentCard = UIState === UIStateEnum.Login
    ? <LoginCard className={styles.card} showResetPasswordUI={showResetPasswordUI} loading />
    : (UIState === UIStateEnum.resetPassword
      ? <ResetPasswordCard className={styles.card} showLogInUI={showLogInUI} loading={false} />
      : (UIState === UIStateEnum.newPassword
        ? <NewPasswordCard className={styles.card} showLogInUI={showLogInUI} loading={false} token={token || "ERROR"} username={username || "ERROR"}  />
        : <></>))

  return (
    <div className={styles.wrapper}>
      <LoginText className={styles.loginText} />
      { currentCard }
    </div>
  );
}

export default Login;