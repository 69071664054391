import * as React from 'react';

import { useSelector } from "react-redux";
import RouterBreadCrumbs from '../../../../../components/BreadCrumbs/RouterBreadCrumbs';
import CustomizationButton from '../CustomizationButton/CustomizationButton';
import styles from './style.module.css';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import ShortcutAddButton from '../../../../../components/ShortcutAddButton/ShortcutAddButton';
import { RootState } from '../../../../../stores/store';
import { dateFormatter } from '../../../../../utils/numericConversions';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material';
import useDownloadPdf from '../../../../../hooks/useDownloadPdf';
import DateRangePopup from '../DateRangePopup/DateRangePopup';
import { aestToLocationLocalTimeConverter } from '../../../../../utils/DateConvertor';
import useTopBarTitle from '../../../../../hooks/TopbarHooks/useTopBarTitle';
import { useAppSelector } from '../../../../../stores/hooks';
import { DateTime } from 'luxon';
import SpaIcon from '@mui/icons-material/Spa';
import DateRangeSection from '../DateRangeSection/DateRangeSection';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DateRangeIcon from '@mui/icons-material/DateRange';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { UpdateTabTitle } from '../../../../../utils/updateTabTitle';
import PDFAddButton from '../PDFAddButton/PDFAddButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import { DatePicker, DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { json2csv } from 'json-2-csv';
import GoannaButton from '../../../../../components/GoannaButton/GoannaButton';
import { Navigate, useNavigate } from 'react-router-dom';

export interface IFieldDetailPageHeaderProps {
    detailRef: React.MutableRefObject<any>[],
}

export default function FieldDetailPageHeader(props: IFieldDetailPageHeaderProps) {

    const { soilProbesData: soilProbesDataQuery, loading, locationDevicesData: locationDevicesDataQuery, ...fieldDetail } = useFieldDetailContext();

    const soilProbesData = soilProbesDataQuery.data?.data.value[0];
    const locationDevicesData = locationDevicesDataQuery.data?.data.value[0];

    const numericSystem: string = useSelector((state: RootState) => state.user.numericSystem);
    const { downloadPdf, loading: pdfLoading } = useDownloadPdf();
    const FieldName = soilProbesData?.LocationDescription;
    const FarmName = soilProbesData?.FarmName;
    const IrrigationForecast = aestToLocationLocalTimeConverter(soilProbesData?.IrrigationDueForecast, soilProbesData?.GMTDifference);
    const debug = useAppSelector(state => state.user.debug);
    const [debugMenuAnchorEl, setDebugMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const debugMenuOpen = Boolean(debugMenuAnchorEl)
    const [debugMenuDates, setDebugMenuDates] = React.useState({ start: "", end: "" });

    const { t } = useTranslation("FieldDetail")

    const { setTopBarTitle } = useTopBarTitle();

    React.useEffect(() => {
        setTopBarTitle(FieldName ?? t('FieldDetailPageHeader-UnknownField'));
        UpdateTabTitle(FieldName ?? t('FieldDetailPageHeader-UnknownField'));
    }, [FieldName])

    const navigate = useNavigate();
    const documentTitle = (FieldName ?? t('FieldDetailPageHeader-UnknownField')) + (FarmName ? (' - ' + FarmName) : '');
    const { dateRange } = useFieldDetailContext();
    const dateFormat = numericSystem === "M" ? 'dd MMMM yyyy' : 'MMMM dd yyyy';
    const from = (dateRange[0] as DateTime)?.toFormat(dateFormat);
    const to = (dateRange[1] as DateTime)?.toFormat(dateFormat);
    const isMobile = useAppSelector(state => state.topbar.isMobile);
    const [isDropDown, setIsDropDown] = React.useState(window.innerWidth <= 350);

    React.useEffect(() => {
        const updateIsDropDown = () => {
            setIsDropDown(window.innerWidth <= 350);
        };
        updateIsDropDown();
        window.addEventListener('resize', updateIsDropDown);
        return () => {
            window.removeEventListener('resize', updateIsDropDown);
        };
    }, []);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleProbeDataExport() {
        const auth_token = localStorage.getItem('access_token')?.replaceAll("\"", '')

        var myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${auth_token}`);
        myHeaders.append("content-type", "application/json");
        myHeaders.append("dnt", "1");
        
        var requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let start = DateTime.fromISO(debugMenuDates.start).toFormat("yyyyMMdd'T'000000")
        let end = DateTime.fromISO(debugMenuDates.end).toFormat("yyyyMMdd'T'235959")

        fetch(`${process.env.REACT_APP_AXIOS_URL}/Reportdata/SoilProbeData/GetSoilTotalDatawithTempForDateRange/${fieldDetail.locationID}/${start}/${end}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const csvData = json2csv(result.slice(6), { emptyFieldValue: '' });
                const blob = new Blob([csvData], { type: 'text/csv' });
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', `${fieldDetail.locationID}_probe.csv`); // Set the file name
                document.body.appendChild(link);

                // Trigger the download
                link.click();

                // Clean up
                URL.revokeObjectURL(blobUrl);
                document.body.removeChild(link);
            })
            .catch(error => console.log('error', error));
    }

    function handleCanopyData() {
        const auth_token = localStorage.getItem('access_token')?.replaceAll("\"", '')

        var myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${auth_token}`);
        myHeaders.append("content-type", "application/json");
        myHeaders.append("dnt", "1");

        let start = DateTime.fromISO(debugMenuDates.start).toFormat("yyyy-MM-dd'T'00:00:00")
        let end = DateTime.fromISO(debugMenuDates.end).toFormat("yyyy-MM-dd'T'23:59:59")

        var graphql = JSON.stringify({
            query: `query ($unit: UnitEnum!, $location_id: ID!) {\n  canopy_stresses(\n    where: {location_id: $location_id, start_date: \"${start}\", end_date: \"${end}\"}\n    legacy: true\n    version: \"3\"\n    sampling_rate: 60\n  ) {\n    threshold\n    periods {\n      type\n      start_date\n      end_date\n      start_deficit_hours\n      end_deficit_hours\n      __typename\n    }\n    data {\n      date_time\n      temp(digits: 2, unit: $unit)\n      air_temp(digits: 2, unit: $unit)\n      deficit_hours\n      ambient_temp(digits: 2, unit: $unit)\n      rel_humid\n      stress_time\n      solar_rad\n      stress_time_above_threshold\n      stress_time_above_5_hours\n      acc_stress_time(unit: percentage, digits: 2)\n      acc_stress_time_above_threshold(digits: 2, unit: percentage)\n      __typename\n    }\n    __typename\n  }\n}`,
            variables: { "unit": "celsius", "location_id": Number(fieldDetail.locationID) }
        })

        var requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: graphql,
            redirect: 'follow'
        };

        if (process.env.REACT_APP_GQL_CANOPY_URL)
            fetch(process.env.REACT_APP_GQL_CANOPY_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const csvData = json2csv(result.data.canopy_stresses.data, { emptyFieldValue: '' });
                    const blob = new Blob([csvData], { type: 'text/csv' });
                    const blobUrl = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.setAttribute('download', `${fieldDetail.locationID}_canopy_60_minutes.csv`); // Set the file name
                    document.body.appendChild(link);

                    link.click();

                    // Clean up
                    URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(link);
                })
                .catch(error => console.log('error', error));
    }

    function handleCanopyData15Minutes() {
        const auth_token = localStorage.getItem('access_token')?.replaceAll("\"", '')

        var myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${auth_token}`);
        myHeaders.append("content-type", "application/json");
        myHeaders.append("dnt", "1");

        let start = DateTime.fromISO(debugMenuDates.start).toFormat("yyyy-MM-dd'T'00:00:00")
        let end = DateTime.fromISO(debugMenuDates.end).toFormat("yyyy-MM-dd'T'23:59:59")

        var graphql = JSON.stringify({
            query: `query ($unit: UnitEnum!, $location_id: ID!) {\n  canopy_stresses(\n    where: {location_id: $location_id, start_date: \"${start}\", end_date: \"${end}\"}\n    legacy: true\n    version: \"3\"\n    sampling_rate: 15\n  ) {\n    threshold\n    periods {\n      type\n      start_date\n      end_date\n      start_deficit_hours\n      end_deficit_hours\n      __typename\n    }\n    data {\n      date_time\n      temp(digits: 2, unit: $unit)\n      air_temp(digits: 2, unit: $unit)\n      deficit_hours\n      ambient_temp(digits: 2, unit: $unit)\n      rel_humid\n      stress_time\n      solar_rad\n      stress_time_above_threshold\n      stress_time_above_5_hours\n      acc_stress_time(unit: percentage, digits: 2)\n      acc_stress_time_above_threshold(digits: 2, unit: percentage)\n      __typename\n    }\n    __typename\n  }\n}`,
            variables: { "unit": "celsius", "location_id": Number(fieldDetail.locationID) }
        })

        var requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: graphql,
            redirect: 'follow'
        };

        if (process.env.REACT_APP_GQL_CANOPY_URL)
            fetch(process.env.REACT_APP_GQL_CANOPY_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    const csvData = json2csv(result.data.canopy_stresses.data, { emptyFieldValue: '' });
                    const blob = new Blob([csvData], { type: 'text/csv' });
                    const blobUrl = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.setAttribute('download', `${fieldDetail.locationID}_canopy_15_minutes.csv`); // Set the file name
                    document.body.appendChild(link);

                    link.click();

                    // Clean up
                    URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(link);
                })
                .catch(error => console.log('error', error));
    }

    function handleSatelliteData() {
        const auth_token = localStorage.getItem('access_token')?.replaceAll("\"", '')

        var myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${auth_token}`);
        myHeaders.append("content-type", "application/json");
        myHeaders.append("dnt", "1");
        var requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let start = DateTime.fromISO(debugMenuDates.start).toFormat("yyyyMMdd'T'000000")
        let end = DateTime.fromISO(debugMenuDates.end).toFormat("yyyyMMdd'T'235959")

        fetch(`${process.env.REACT_APP_AXIOS_URL}/reportdata/SatelliteFieldData/GetSatelliteFieldDataSummaryForDateRange/${locationDevicesData.LocationIDSatellite}/${start}/${end}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const csvData = json2csv(result, { emptyFieldValue: '' });
                const blob = new Blob([csvData], { type: 'text/csv' });
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;

                link.setAttribute('download', `${fieldDetail.locationID}_satellite.csv`); // Set the file name
                document.body.appendChild(link);
                link.click();

                // Clean up
                URL.revokeObjectURL(blobUrl);
                document.body.removeChild(link);
            })
            .catch(error => console.log('error', error));
    }

    return (
        <div className={styles.pageHeader}>
            <div className={styles.topLine}>
                {
                    !isMobile &&
                    <RouterBreadCrumbs></RouterBreadCrumbs>
                }
                <div style={
                    isMobile
                        ? { position: "fixed", top: "0px", right: "10px", zIndex: "97", display: 'flex', flexDirection: 'row' }
                        : { display: 'flex', flexDirection: 'row' }}  >

                    {
                        debug ?
                            <>
                                <GoannaButton title='Season Report' onClick={() => {navigate(`/pages/goField/endOfSeasonReport/${fieldDetail.locationID}`)}}
                                width='160px'
                                height='30px'
                                style={{
                                    alignSelf: 'center'
                                }}
                                />
                                <IconButton
                                    id="debug-menu-button"
                                    aria-controls={debugMenuOpen ? 'debug-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={debugMenuOpen ? 'true' : undefined}
                                    onClick={(event) => { setDebugMenuAnchorEl(event.currentTarget); }}
                                >
                                    <FormatAlignJustifyIcon />
                                </IconButton>
                                <Menu
                                    id="debug-menu"
                                    anchorEl={debugMenuAnchorEl}
                                    open={debugMenuOpen}
                                    onClose={() => setDebugMenuAnchorEl(null)}
                                >
                                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                                        <div style={{ paddingTop: "10px" }}>
                                            {/* <DatePicker
                                                sx={{ paddingLeft: "10px", width: "170px" }}
                                                format='dd/MM/yyyy'
                                                label="start"
                                                value={DateTime.fromISO(debugMenuDates.start)}
                                                onChange={(newValue) => {
                                                    setDebugMenuDates({ end: debugMenuDates.end, start: newValue?.toISODate() || "" })
                                                }}
                                            />
                                            <DatePicker
                                                sx={{ paddingLeft: "10px", paddingRight: "10px", width: "170px" }}
                                                format='dd/MM/yyyy'
                                                label="end"
                                                value={DateTime.fromISO(debugMenuDates.end)}
                                                onChange={(newValue) => {
                                                    setDebugMenuDates({ start: debugMenuDates.start, end: newValue?.toISODate() || "" })
                                                }}
                                            // minDate={currentDate?.plus({ days: 1 })}
                                            // maxDate={DateTime.fromISO(locationDateNow)}
                                            /> */}
                                            <DateRangePicker
                                            sx={{padding: '10px'}}
                                            value={[DateTime.fromISO(debugMenuDates.start), DateTime.fromISO(debugMenuDates.end)]}
                                            onChange={(newValue: [DateTime | null, DateTime | null]) => {
                                                setDebugMenuDates({ start: newValue[0]?.toISODate() || "", end: newValue[1]?.toISODate() || "" });
                                            }}
                                            minDate={debugMenuDates.end !== '' ? DateTime.fromISO(debugMenuDates.end).minus({days: 180}) : undefined}
                                            maxDate={debugMenuDates.start !== '' ? DateTime.fromISO(debugMenuDates.start).plus({days: 180}) : undefined}
                                            />
                                        </div>
                                    </LocalizationProvider>

                                    <MenuItem disabled={debugMenuDates.start === "" || debugMenuDates.end === ""} onClick={handleProbeDataExport}>Probe Data</MenuItem>
                                    <MenuItem disabled={debugMenuDates.start === "" || debugMenuDates.end === ""} onClick={handleCanopyData}>Canopy Data 60min</MenuItem>
                                    <MenuItem disabled={debugMenuDates.start === "" || debugMenuDates.end === ""} onClick={handleCanopyData15Minutes}>Canopy Data 15min</MenuItem>
                                    <MenuItem disabled={debugMenuDates.start === "" || debugMenuDates.end === ""} onClick={handleSatelliteData}>Satellite Data</MenuItem>


                                </Menu></>
                            : <></>
                    }
                    <DateRangePopup />
                    {
                        isDropDown ?
                            <></>
                            :
                            <ShortcutAddButton name={(FarmName ? FarmName + " - " : "") + FieldName} />
                    }
                    {
                        isMobile ?
                            <></>
                            :
                            <PDFAddButton pdfLoading={pdfLoading} loading={loading || (props.detailRef.length === 0)} onClick={() => {
                                downloadPdf(props.detailRef,
                                    FieldName ?? 'FieldDetail',
                                    documentTitle,
                                    numericSystem === 'I' ? 'Letter' : 'A4',
                                    `${t("FieldDetailPageHeader-IrrigationForecast")}${dateFormatter(numericSystem, IrrigationForecast, false, true)}`,
                                    `Date Range: ${from} - ${to}`
                                )
                            }} />
                    }

                    <CustomizationButton />
                    {
                        !isDropDown ?
                            <></>
                            :
                            <><IconButton
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                color='primary'
                            >
                                <MoreVertIcon />
                            </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <ShortcutAddButton name={(FarmName ? FarmName + " - " : "") + FieldName} isMenuItem={true} onClick={handleClose} />
                                </Menu>
                            </>
                    }
                </div>
            </div>

            {
                !isMobile && <div className={styles.Title}>
                    {FieldName ?? t('FieldDetailPageHeader-UnknownField')}
                    {FarmName && (' - ' + FarmName)}
                </div>
            }

            <div className={styles.IrrigationTitle}>
                {
                    locationDevicesData?.CropName &&
                    <Box display="flex" alignItems="flex-start">
                        <SpaIcon sx={{
                            height: '20px',
                            color: '#2D9c41',
                            marginRight: "2px"
                        }} />{locationDevicesData?.CropName}
                    </Box>
                }
                {
                    IrrigationForecast &&
                    <Box display="flex" alignItems="center">
                        <FiberManualRecordIcon sx={{
                            height: '10px',
                            color: '#0958FF',
                        }} />
                        {t("FieldDetailPageHeader-IrrigationForecast")}
                        {dateFormatter(numericSystem, IrrigationForecast, true)}
                    </Box>
                }
                {
                    locationDevicesData?.StartDate &&
                    <Box display="flex" alignItems="center">
                        <HourglassTopIcon sx={{
                            height: '20px',
                            color: '#7b1fa2',
                            marginRight: "2px"
                        }} />
                        {t('FieldDetailPageHeader-StartDate')}
                        {dateFormatter(numericSystem, aestToLocationLocalTimeConverter(locationDevicesData?.StartDate, soilProbesData?.GMTDifference), false)}
                    </Box>
                }
                <Box display="flex" alignItems="center">
                    <DateRangeIcon sx={{
                        height: '17px',
                        color: '#0958FF',
                        marginRight: "2px"
                    }} />
                    <DateRangeSection />
                </Box>
            </div>
        </div>
    );
}