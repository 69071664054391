import { Drawer, IconButton, DialogTitle, DialogContent, DialogContentText, DialogActions, Link, Divider, ListItem, Collapse, List, Typography, ListItemButton } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import styles from './style.module.css';
import React, { useState } from "react";
import useFieldDetailContext from "../../hooks/ContextHooks/useFieldDetailContext";
import { useAppSelector } from "../../stores/hooks";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

type GoannaDrawerContentType = {
  title: string;
  content: string | JSX.Element;
};

export type GoannaDrawerProps = {
  open: boolean,
  content: GoannaDrawerContentType[],
  action?: string | JSX.Element,
  closeDrawer: () => void;
}

function GoannaDrawer(props: GoannaDrawerProps) {

  const isMobilePortrait = useAppSelector(state => state.topbar.dimensions.width < 500);

  return (
    <Drawer
      anchor={isMobilePortrait ? 'bottom' : 'right'}
      variant="temporary"
      open={props.open}
      onClose={props.closeDrawer}
      PaperProps={{
        sx: {
          background: "#F4F7FC",
          width: isMobilePortrait ? "100%" : 550,
          height: isMobilePortrait ? "65%" : "100%",
          borderRadius: isMobilePortrait ? "4% 4% 0 0" : "initial"
        }
      }}
    >
      <Box>
        <Box className={styles.drawerTitle}>
          <IconButton onClick={props.closeDrawer}>
            <CloseIcon sx={{ background: "transparent radial-gradient(closest-side at 50% 50%, #E8EBEF 0%, #E3E7EB 100%) 0% 0% no-repeat padding-box", borderRadius: "50%", padding: "5px" }} fontSize='medium' />
          </IconButton>
        </Box>
        <Box className={styles.drawerContent}>
          {props.content.map(content => <GoannaDrawerContent key={content.title} title={content.title} content={content.content} />)}
        </Box>
        <DialogContent>
          {props.action}
        </DialogContent>
      </Box>
    </Drawer>
  )
}

function GoannaDrawerContent(props: GoannaDrawerContentType) {
  const [open, setOpen] = useState(true);
  return (
    <>
      <ListItemButton
        className={styles.contentTitle}
        sx={{
          color: "#5A607F",
          fontSize: 18,
          fontWeight: 600,
          fontFamily: "Poppins",
          padding: "16px 8px 16px 24px"
        }}
        onClick={() => { setOpen(prevState => !prevState) }}
      >
        <Box display={"flex"} alignItems={"center"} gap={"10px"}>
          {open ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
          <span>{props.title}</span>
        </Box>
      </ListItemButton >
      <Collapse in={open}>
        <DialogContent sx={{ padding:"0px 8px 0px 24px" }}>
          <DialogContentText fontSize="16px" fontFamily="Poppins" paragraph color={"#5A607F"} >
            {props.content}
          </DialogContentText>
        </DialogContent>
      </Collapse>
      <Divider sx={{margin: "0px 24px"}}/>
    </>
  )
}

export default GoannaDrawer

