import { SelectChangeEvent, FormControl, Select, MenuItem } from "@mui/material";
import { FunctionComponent, useState } from "react";


interface ISettingsDropDownProps {
  defaultValue: string|number;
  options: { key: string|number; displayValue: string }[];
  onChange?: (event : SelectChangeEvent) => void;
}

const SettingsDropDown: FunctionComponent<ISettingsDropDownProps> = (props) => {

  const [value, setValue] = useState(props.defaultValue); // Add default here

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);

    // invoke super method
    if (props.onChange) props.onChange(event);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120, margin: 0}} size="small">
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={String(value)}
        displayEmpty
        onChange={handleChange}
      >
        {props.options.map((item) => <MenuItem key={item.key} value={String(item.key)}>{item.displayValue}</MenuItem>)}
      </Select>
    </FormControl>
  );
}

export default SettingsDropDown;