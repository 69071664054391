import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { http } from '../../api/http';

export interface soilProbesDataType {
    data: {
        value: any[];
    }
};

const SOILPROBES_PATH: string = 'odata/SoilProbes';

const useSoilProbesData = (locationID: string, onSuccess?: ((data: soilProbesDataType) => void)) => {

    const query = SOILPROBES_PATH + `(${locationID})`;
    const fetchSoilProbesData = async () => {
        const response: soilProbesDataType = await http.get(query);
        return response;
    };

    const SoilProbesData = useQuery({ queryKey: ['SoilProbes'], queryFn: fetchSoilProbesData,  enabled: false, onSuccess });

    return SoilProbesData;
}

export default useSoilProbesData;