import * as React from 'react';

import styles from './style.module.css';

export interface IScheduleCalendarIrrigationTileProps {
    color: string
}

export default function ScheduleCalendarIrrigationTile (props: IScheduleCalendarIrrigationTileProps) {
  return (
    <div className={styles.ScheduleCalendarIrrigationTile} style={{
        backgroundColor: props.color
    }}>
    </div>
  );
}
