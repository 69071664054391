import { IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import GoannaCard from "../../../components/GoannaCard/GoannaCard";
import { AddRemoveButtonDisplay } from "../../../components/GoannaCard/GoannaCardHelper";
import { ReactComponent as SettingsIcon } from '../../../assets/images/Navbar/ic_setting.svg';
import DoneIcon from '@mui/icons-material/Done';
import ShortcutsCard from "../ShortcutsCard/ShortcutsCard";
import styles from './style.module.css';
import { setDashboardPreference as _setDashboardPreference } from "../../../stores/Slices/userSlice";
import useSoilProbesIrrigationForrecast from "../../../hooks/ApiHooks/useSoilProbesIrrigationForrecast";
import { useAppSelector } from "../../../stores/hooks";
import { RootState } from "../../../stores/store";
import useSoilProbesIrrigationForecast from "../../../hooks/ApiHooks/useSoilProbeIrrigationForecast";
import { DateTime } from "luxon";
import { AEST_Zone } from "../../../utils/DateConvertor";
import { UpdateTabTitle } from "../../../utils/updateTabTitle";

interface IDashboardIndexProps {

}

const moment = require('moment-timezone');

const GO_FIELD_CARD_ID: number = 1;
const SHORTCUTS_CARD_ID: number = 0;

const today = moment.tz("Australia/Brisbane");
const nextNineDays = { from: today.clone().add({ day: 1 }), to: today.clone().add({ day: 9 }) }

function DashboardIndex(props: IDashboardIndexProps) {

  UpdateTabTitle('Dashboard')
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation('DashboardIndex')
  const dispatch = useDispatch();
  // TODO: Figure out why pluralisation isn't working

  const navigate = useNavigate();

  const { dashBoardPreference: _dashBoardPreference }: { dashBoardPreference: number[] } = useSelector((state: any) => state.user);
  const userTimeZoneAESTDifference = useSelector((state: RootState) => state.user.timezone.AESTDifference);
  const timezoneName = useSelector((state: RootState) => state.user.timezone.name);
  // this changes backend and does the mutation in the graphql api
  const SaveDashBoardPreference = () => dispatch(_setDashboardPreference(dashBoardPreference));

  const [dashBoardPreference, setDashBoardPreference] = useState((_dashBoardPreference == null || _dashBoardPreference == undefined || _dashBoardPreference.length == 0) ? [0, 1] : _dashBoardPreference);

  const numericSystem = useAppSelector((state: RootState) => state.user.numericSystem)

  const gofieldDateFormat = numericSystem == "M" ? "(dd/MM/yyyy)" : "(MM/dd/yyyy)";

  const todayUserDateTime = DateTime.local({ zone: AEST_Zone }).plus({ hours: userTimeZoneAESTDifference });

  // Calculate start and end of day in the user's timezone
  const startOfDayUser = todayUserDateTime.startOf('day').minus({ hours: userTimeZoneAESTDifference });
  const endOfDayUser = todayUserDateTime.endOf('day').minus({ hours: userTimeZoneAESTDifference });

  // Get next nine days in user's timezone
  const nextNineDaysUserDateTime = {
    from: todayUserDateTime.startOf('day').minus({ hours: userTimeZoneAESTDifference }).plus({ days: 1 }),
    to: todayUserDateTime.endOf('day').minus({ hours: userTimeZoneAESTDifference }).plus({ days: 9 })
  };

  const { fetchData: getTodayData, irrigationFields: irrigationFieldsToday, loading: irrigationFieldsTodayLoading } = useSoilProbesIrrigationForecast(startOfDayUser, endOfDayUser);
  const { fetchData: getNineDaysData, irrigationFields: irrigationFieldsNineDays, loading: irrigationFieldsNineDaysLoading } = useSoilProbesIrrigationForecast(nextNineDaysUserDateTime.from, nextNineDaysUserDateTime.to);

  useEffect(() => {
    getTodayData();
    getNineDaysData();
  }, []);

  // leave this for future changes
  // const [goFieldsPlusCount, setGoFieldsPlusCount] = useState<number | null>(null);

  // const getAllUserDevices = useFieldTableData();

  // useEffect(() => {
  //   getAllUserDevices().then(response => {
  //     setGoFieldsPlusCount(response.count);
  //   })
  // }, [])

  // if (goFieldsPlusCount === null)
  //   var GoFieldCard = <Box sx={{ display: 'flex', height: '350px', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{ width: '110px', height: '110px' }} /></Box>
  // else 
  // if (goFieldsPlusCount <= 0) {
  //   var GoFieldCard = <GoannaCard
  //     className={styles.GoFieldCard}
  //     title={t("DashboardIndex-gofieldtitle")}
  //     emptyText={<Typography>To access the information you need to own a Go Field+ device &gt; <a href="https://www.goannaag.com.au/shop">buy it now</a></Typography>}
  //     subtitle={' '}
  //     addRemoveClickCallback={(displayIcon: AddRemoveButtonDisplay) => { handleAddRemoveClick(displayIcon, GO_FIELD_CARD_ID) }}
  //     showAddRemoveButton={
  //       editMode
  //         ? (dashBoardPreference.find(pref => pref == GO_FIELD_CARD_ID) != undefined ? AddRemoveButtonDisplay.Remove : AddRemoveButtonDisplay.Add)
  //         : AddRemoveButtonDisplay.None
  //     }
  //     onClickCallback={() => undefined} // to enable in-card Link clicks
  //     showTopBackground
  //   />
  // }
  // else {

  var GoFieldCard = <GoannaCard
    key="GoFieldPlusCard"
    loading={irrigationFieldsNineDaysLoading || irrigationFieldsTodayLoading}
    className={styles.GoFieldCard}
    title={t("DashboardIndex-gofieldtitle")}
    subtitle={t("DashboardIndex-irrigation")}
    firstText={{
      TextData: <Trans i18nKey="DashboardIndex:InnerText-gofieldfirsttext" components={[<strong />]} />,
      TextNumber: irrigationFieldsToday ?? undefined,
      dateString: todayUserDateTime.toFormat(gofieldDateFormat) //numericSystem == "M" ? "(DD/MM/YYYY)" : "(MM/DD/YYYY)"
    }}
    secondText={{
      TextData: t("InnerText-gofieldsecondtext"),
      TextNumber: irrigationFieldsNineDays ?? undefined,
      dateString: (
        //  (22/03/2023 - 30/03/3023)
        nextNineDaysUserDateTime.from.toFormat(numericSystem == "M" ? "(dd/MM/yyyy" : "(MM/dd/yyyy")
        + " - " +
        nextNineDaysUserDateTime.to.toFormat(numericSystem == "M" ? "dd/MM/yyyy)" : "MM/dd/yyyy)")
      )
    }}
    // footerText={t("DashboardIndex-gofieldfooter")}
    showFooter={true}
    footerText={'Tz: ' + timezoneName}
    onClickCallback={() => editMode ? undefined : navigate('/pages/goField')}
    showTopBackground
    showAddRemoveButton={editMode ?
      (dashBoardPreference.find(pref => pref == GO_FIELD_CARD_ID) != undefined ? AddRemoveButtonDisplay.Remove : AddRemoveButtonDisplay.Add)
      : AddRemoveButtonDisplay.None
    }
    addRemoveClickCallback={(displayIcon: AddRemoveButtonDisplay) => { handleAddRemoveClick(displayIcon, GO_FIELD_CARD_ID) }}
  />
  // }
  const PrefrenceToCardMapping: { [key: number]: JSX.Element } = {
    0: <ShortcutsCard
      key={"ShortcutsCardDashboard"}
      className={styles.LastCard}
      editMode={editMode}
      showAddRemoveIcon={
        editMode
          ? (dashBoardPreference.find(pref => pref == SHORTCUTS_CARD_ID) != undefined ? AddRemoveButtonDisplay.Remove : AddRemoveButtonDisplay.Add)
          : AddRemoveButtonDisplay.None
      }
      addRemoveClickCallback={(displayIcon: AddRemoveButtonDisplay) => { handleAddRemoveClick(displayIcon, SHORTCUTS_CARD_ID) }}
    />,

    1: GoFieldCard,
    /*
     // Go weather card not in scope at the moment will use later leave for future refrence
     <GoannaCard
        title={t("DashboardIndex-goweathertitle")}
        subtitle={t('DashboardIndex-goweathersubtitle')}
        firstText={{ TextNumber: 15, TextData: t("InnerText-goweatherfirsttext", { count: 10 }) }}
        secondText={{ TextNumber: 15, TextData: t("InnerText-goweathersecondtext") }}
        showFooter={false}
        icon={1}
        onClickCallback={() => navigate('/pages/dashboard/fields')}
        showTopBackground={navbarOpen}
        topBackgroundColor={navbarOpen ? '#80CED2' : undefined} /> */
  }

  const notSelectedKeys: string[] = Object.keys(PrefrenceToCardMapping).filter(v1 => Number(v1) !== dashBoardPreference.find(v2 => v2 == Number(v1)))

  const handleAddRemoveClick = (iconDisplay: AddRemoveButtonDisplay, key: number) => {
    switch (iconDisplay) {
      case AddRemoveButtonDisplay.Add:
        setDashBoardPreference([...dashBoardPreference, key])
        break;

      case AddRemoveButtonDisplay.Remove:
        setDashBoardPreference(dashBoardPreference.filter(item => item !== key))
        break;

      case AddRemoveButtonDisplay.None:
      default:
        break;
    }
  }
  const isMobile = useAppSelector(state => state.topbar.isMobile)

  return (
    <div className={styles.Dashboard}>
      <div className={isMobile ? undefined : styles.DashboardTop} style={isMobile ? { position: "fixed", top: "0px", right: "10px", zIndex: "97" } : undefined}>
        <IconButton onClick={() => { if (editMode) SaveDashBoardPreference(); setEditMode(!editMode) }} sx={{ "&:hover": { background: "rgba(9, 88, 255, 0.04)" } }}>
          {
            editMode
              ? <DoneIcon sx={{ fill: '#0958FF' }} fontSize={'medium'} />
              : <SettingsIcon filter="invert(23%) sepia(43%) saturate(6539%) hue-rotate(221deg) brightness(100%) contrast(102%)" />
          }
        </IconButton>
      </div>
      <div className={styles.Cards}>
        {[...dashBoardPreference].sort().reverse().map((pref: number) => PrefrenceToCardMapping[pref])}
      </div>
      <div style={{ display: editMode ? 'initial' : 'None' }} className={styles.Cards}>
        <hr style={{ marginTop: '57px', marginBottom: '0px', marginRight: '40px' }} />
        <div className={styles.Cards}>
          {notSelectedKeys.map((key: string) => PrefrenceToCardMapping[Number(key)])}
        </div>
      </div>
    </div>
  );
}

export default DashboardIndex;
