import { combineReducers, configureStore, getDefaultMiddleware, Middleware, } from '@reduxjs/toolkit';
import userReducer from './Slices/userSlice';
import navbarReducer from './Slices/navbarSlice';
import forecastReducer from './Slices/forecastSlice';
import topbarReducer from './Slices/topbarSlice';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { createStateSyncMiddleware } from 'redux-state-sync';
import { createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
    navbar: navbarReducer,
    user: userReducer,
    forecast: forecastReducer,
    topbar: topbarReducer
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'forecast']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

// dont delete this it is for debugging
// const logger: Middleware = (store) => (next) => (action) => {
//     console.log('Dispatching:', action);
//     const result = next(action);
//     console.log('Next state:', store.getState());
//     return result;
// };

// const serializableMiddleware = createSerializableStateInvariantMiddleware();

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(
            createStateSyncMiddleware({
                whitelist: ['user', 'forecast'],
                broadcastChannelOption: { type: 'localstorage' },
                blacklist: [PERSIST, REHYDRATE],
            }) // add the middleware to the store
        )
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
