import * as React from 'react';
import { RootzoneContext } from '../../context/FieldDetailsContext/RootzoneContext';

const useRootzoneContext = () => {
    const context = React.useContext(RootzoneContext);

    if (context === undefined) {
        throw new Error("useRootzoneContext was used outside of its Provider");
      }
    return context;
}

export default useRootzoneContext;