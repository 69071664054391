import * as React from 'react';
import { scheduleCalendarDataType } from './ScheduleCalendar';
import { getWeekday } from '../../../../../utils/DateConvertor';
import ScheduleCalendarTopTile from './ScheduleCalendarTopTile';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';

export interface IScheduleCalendarTopProps {
  scheduleCalendarData: scheduleCalendarDataType[],
}


export default function ScheduleCalendarTop(props: IScheduleCalendarTopProps) {

  const { t } = useTranslation("FieldList");
  return (
    <div className={styles.topTileWrapper}>
      <div className={styles.description}>
      </div>
      {props.scheduleCalendarData.map((info, index) => {
        return <ScheduleCalendarTopTile
          key={index}
          day={index === 0 ? t("Today") : ['N/A', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'][info.dateInfo.weekday ? info.dateInfo.weekday : 0]}
          date={info.dateInfo.day}
          color={index === 0 ? '#EAC858' : '#F4F7FC'}
          rain={info.weatherForecast.rain}
          temperature={info.weatherForecast.temperature} />
      })}
    </div>
  );
}
