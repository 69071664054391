import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { http } from '../../api/http';

const PATH: string = 'reportdata/BOMForecastData/GetBOMForecastDataSummaryForDateRange';

const useBOMForecastData = (locationID: string) => {
    const [dates, setDates] = React.useState<{ startDate: string, endDate: string }>();


    const fetchBOMForecastData = async ({ queryKey }: { queryKey: [string, string, string | undefined, string | undefined] }): Promise<any> => {
        const [, , startDate, endDate] = queryKey;
        if (startDate && endDate) {
            const query = `${PATH}/${locationID}/${startDate}/${endDate}`;
            const response = await http.get(query);
            return response.data;
        }
        return {
            data: []
        }
    };

    const BOMForecastData = useQuery(
        ['BOMForecast', locationID, dates?.startDate, dates?.endDate],
        fetchBOMForecastData,
        {
            enabled: (dates?.startDate !== undefined && dates?.endDate !== undefined), 
            refetchOnWindowFocus: false,
        }
    );

    // if you change the data in state and query key uses it then it refetches the data.
    const refetchDataWithDateRange = async (newStartDate: string, newEndDate: string) => {
        setDates({
            startDate: newStartDate,
            endDate: newEndDate
        });
    };


    return [BOMForecastData, refetchDataWithDateRange] as const;
};

export default useBOMForecastData;
