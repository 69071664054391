import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { http } from '../../api/http';

export interface locationDevicesDataType {
    data: {
        value: any[];
    }
};

const LOCATION_DEVICE_PATH: string = 'odata/Locations';

const useLocationDevicesData = (locationID: string, onSuccess: ((data: locationDevicesDataType) => void)) => {

    const query = LOCATION_DEVICE_PATH + `(${locationID})`;
    const fetchLocationDevicesData = async () => {
        const response: locationDevicesDataType = await http.get(query);
        return response;
    };

    const locationDeviceData = useQuery({ queryKey: ['locationDevice', locationID], queryFn: fetchLocationDevicesData, enabled: false, onSuccess});

    return locationDeviceData;
}

export default useLocationDevicesData;