import * as React from 'react';
import { SoilMoistureProfileContext } from '../../context/FieldDetailsContext/SoilMoistureProfileContext';

const useSoilMoistureProfileContext = () => {
    const context = React.useContext(SoilMoistureProfileContext);

    if (context === undefined) {
        throw new Error("useSoilMoistureProfileContext was used outside of its Provider");
      }
    return context;
}

export default useSoilMoistureProfileContext;
