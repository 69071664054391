import * as React from 'react';
import { scheduleCalendarDataType } from './ScheduleCalendar';
import ScheduleCalendarIrrigationTile from './ScheduleCalendarIrrigationTile';

import styles from './style.module.css';

export interface IScheduleCalendarIrrigationProps {
    scheduleCalendarData: scheduleCalendarDataType[],
}

export default function ScheduleCalendarIrrigation(props: IScheduleCalendarIrrigationProps) {
    const ok_Color = '#2D9C41';
    const irrigate_Color = '#1085FD';
    const stress_Color = '#E22C29';
    const null_color = '#979797';
    return (
        <div className={styles.topTileWrapper}>
            <div className={styles.ScheduleCalendarEtcDescription}>
            </div>
            {props.scheduleCalendarData.map((info, index) => {
                switch (info.irrigationForecast) {
                    case 0:
                        return <ScheduleCalendarIrrigationTile key={index} color={ok_Color} />
                    case 1:
                        return <ScheduleCalendarIrrigationTile key={index} color={irrigate_Color} />
                    case 2:
                        return <ScheduleCalendarIrrigationTile key={index} color={stress_Color} />
                    case 3:
                        return <ScheduleCalendarIrrigationTile key={index} color={null_color} />
                }
            })}
        </div>
    );
}
