import { Link, Typography } from '@mui/material'
import { MouseEventHandler, ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { redirect, useNavigate } from 'react-router'
import GoannaButton from '../../components/GoannaButton/GoannaButton'
import { Box } from '@mui/system'
import useAuth from '../../hooks/useAuth'
import { initialState, setUser } from '../../stores/Slices/userSlice'
import { useAppDispatch } from '../../stores/hooks'
import ImageOops from "../../assets/images/Error/oops.png"
import ErrorPage from './ErrorPage'
import { t } from 'i18next'
import { Trans, useTranslation } from 'react-i18next'

export interface IErrorFallback {
  error: Error,
  resetErrorBoundary: MouseEventHandler<HTMLButtonElement>,
}

export default function OopsPage(
  {
    error,
    resetErrorBoundary
  }: IErrorFallback) {

  const { t } = useTranslation("Errors")

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function GoBack() {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }

  return (
    <ErrorPage
      title={t('common.title')}
      description1={
        <>
          <Typography fontWeight="bold" fontFamily="Poppins" fontSize={18}>
            {t('common.subtitle')}
          </Typography><br />
          <Typography fontFamily="Poppins" fontSize={15}>
            <Trans i18nKey="Errors:common.desc1" components={[<Link href="#" onClick={GoBack} />]} />
            {t('common.desc2')}
          </Typography>
        </>
      }
      description2={
        <>
          <Typography fontFamily="Poppins" fontSize={15}>
            {t('common.desc3')}
          </Typography><br />
          <Typography fontWeight="bold" fontFamily="Poppins" fontSize={15}>
            {t('common.desc4')}
          </Typography>
        </>
      }
      imageURL={ImageOops}
    />
  )
}