import * as React from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { Box, TextField, TextFieldProps } from '@mui/material';

const SUBMIT_FILTER_STROKE_TIME = 0;

function InputNumberInterval(props: GridFilterInputValueProps) {
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = React.useRef<any>();
  const [filterValueState, setFilterValueState] = React.useState<[string, string]>(
    item.value ?? '',
  );

  React.useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  React.useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound: string, upperBound: string) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBound]);

      applyValue({ ...item, value: [lowerBound, upperBound] });
  };

  const handleUpperFilterChange: TextFieldProps['onChange'] = (event) => {
    const newUpperBound = event.target.value;
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange: TextFieldProps['onChange'] = (event) => {
    const newLowerBound = event.target.value;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
        pl: '20px',
      }}
    >
      <TextField
        name="lower-bound-input"
        placeholder="Min"
        label="Min"
        variant="standard"
        value={Object.is(Number(filterValueState[0]), NaN) ? 0 : Number(filterValueState[0])}
        onChange={handleLowerFilterChange}
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
      <TextField
        name="upper-bound-input"
        placeholder="Max"
        label="Max"
        variant="standard"
        value={Object.is(Number(filterValueState[0]), NaN) ? 0 : Number(filterValueState[1])}
        onChange={handleUpperFilterChange}
      />
    </Box>
  );
}

export default InputNumberInterval;