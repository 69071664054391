import { Link, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import ErrorPage from "../ErrorPage";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../../stores/hooks";
import { initialState, setUser } from "../../../stores/Slices/userSlice";
import Image401 from "../../../assets/images/Error/401.png"
import { Trans, useTranslation } from "react-i18next";

interface IError403Props {

}

const Error403: FunctionComponent<IError403Props> = () => {
    const { t } = useTranslation("Errors")
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const LogoutUser = () => {
        localStorage.clear();
        navigate('/');
        dispatch(setUser(initialState))
        window.location.reload();
    }

    return (
        <ErrorPage
            title={t('error401.title')}
            description1={
                <Typography>
                    <Trans i18nKey="Errors:error401.description" components={[<Link href="#" onClick={LogoutUser} />]} />
                </Typography>
            }
            description2={<></>}
            imageURL={Image401}
            showHelpEmail={false}
        />
    );
}

export default Error403;