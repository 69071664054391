import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    view: 'FieldTable'
}

/* 
 * TODO:
 *   This file needs to be removed however we cant do this at the moment because we don't have versioning set up
 *   removing all refrences to it for now
 *   https://stackoverflow.com/questions/53000748/how-to-update-persist-redux-state-once-after-deploy-the-client-side
 */
export const forecastSlice = createSlice({
    name: 'forecast',
    initialState,
    reducers: {
        setView: (state, action: PayloadAction<string>) => {
            state.view = action.payload;
        }
    }
});

export const { setView } = forecastSlice.actions;
export default forecastSlice.reducer;