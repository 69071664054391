
import { EChartsOption } from 'echarts';
import * as React from 'react';
import FieldDetailBody from '../../../../../components/FieldDetailPaper/FieldDetailBody';
import FieldDetailHeader from '../../../../../components/FieldDetailPaper/FieldDetailHeader';
import FieldDetailPaper from '../../../../../components/FieldDetailPaper/FieldDetailPaper';
import { ReactECharts } from '../../../../../components/ReactEcharts/ReactEcharts';
import useRootzoneContext from '../../../../../hooks/ContextHooks/useRootzoneContext';

import styles from './style.module.css';

export interface IRootzoneProps {
}

export default function Rootzone(props: IRootzoneProps) {

  const { loading, RootzoneDataGraphSeries } = useRootzoneContext();

  const option: EChartsOption = {
    tooltip: {
      textStyle: {
        fontWeight: 'normal'
      },
      valueFormatter: (value) => String(value) + '%',
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
    },
    legend: {},
    grid: {
      left: 27,
      right: -3,
      bottom: '3%',
      containLabel: true
    },
    color: [
      '#1BD183',
      '#FFF06A',
    ],
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
      min: 0,
      max: 110,
      axisLabel: {
        formatter: '{value}%',
        showMaxLabel: false,
      },

    },
    yAxis: {
      type: 'category',
    },
    dataset: {
      dimensions: ['Depth', 'Daily Use', 'Available'],
      source: RootzoneDataGraphSeries.dataSet ?? [],
    },
    series: [
      {
        type: 'bar',
        label: {
          show: true,
          position: 'right',
          formatter: '{@Daily Use}%',
        },
        barWidth: 20
      },
      {
        type: 'bar',
        label: {
          show: true,
          position: 'right',
          formatter: '{@Available}%',
        },
        barWidth: 10
      }
    ]
  };

  return (
    <FieldDetailPaper width='49%' id="RootzoneGraph">
      <FieldDetailHeader backgroundColor='#D9D7D780'>
        <div className={styles.RootzoneHeader}>
          Rootzone
        </div>
      </FieldDetailHeader>
      <FieldDetailBody className={styles.fieldDetailBody} style={{ marginLeft: '0px', marginRight: '0px', paddingLeft: '10px', paddingRight: '10px'}} loading={loading}>
        <ReactECharts option={option} style={{ height: '600px', width: '95%'}} loading={loading} />
      </FieldDetailBody>
    </FieldDetailPaper>
  );
}