import { InputAdornment, TextField } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { http } from "../../../../../api/http";
import GraphPageDataCard, { GraphPageDataCardType } from "../../../../../components/GraphPageDataCard/GraphPageDataCard";
import { FieldDetailDataType } from "../../../../../context/FieldDetailsContext/FieldDetailsContext";
import useSoilTotalDatawithTemp from "../../../../../hooks/ApiHooks/useSoilTotalDatawithTemp";
import useFieldDetailContext from "../../../../../hooks/ContextHooks/useFieldDetailContext";
import { aestToLocationLocalTimeConverter } from "../../../../../utils/DateConvertor";
import { dateFormatter, getUnitName, valueFormatter } from "../../../../../utils/numericConversions";
import HelpIconButton from "../HelpIconButton/HelpIconButton";
import styles from './style.module.css'
import React from "react";
interface IIrrigationCard {

}

export default function IrrigationCard(props: IIrrigationCard) {

  const { numericSystem }: { numericSystem: string } = useSelector((state: any) => state.user);
  const FieldDetailContext: FieldDetailDataType = useFieldDetailContext();
  const locationDevicesData = FieldDetailContext.locationDevicesData.data?.data.value[0] ?? {};
  const SoilProbesData = FieldDetailContext.soilProbesData.data?.data.value[0] ?? [];
  const SoilTotalData = FieldDetailContext.soilTotalDatawithTemp.data?.data ?? [];
  const [defaultUsage, setDefaultUsage] = React.useState(SoilProbesData.DefaultUsage)

  React.useEffect(() => {
    setDefaultUsage(SoilProbesData.DefaultUsage)
  }, [SoilProbesData]);

  const [isLoading, setIsLoading] = useState(false);
  let loading = FieldDetailContext.locationDevicesData.isLoading
    || FieldDetailContext.soilProbesData.isLoading
    || isLoading;


  const [hasChanged, setHasChanged] = useState(false);

  const handleSubmit = useCallback(() => {
    setHasChanged(false);
    setIsLoading(true);        
    const url = `odata/Locations(${locationDevicesData.LocationID})`
    let responseBody: any = locationDevicesData;
    responseBody.DefaultUsage = parseFloat(defaultUsage);
    http.put(url, responseBody)
        .then(() => {
          FieldDetailContext.soilProbesData.refetch()
          .then(() => { setIsLoading(false) })
        })


  }, [SoilProbesData, defaultUsage]);

  const LocationCard: GraphPageDataCardType = {
    title: <>
      Irrigation
      <HelpIconButton
        content={[{
          title: "Irrigation Forecast",
          content: "An irrigation forecast is a prediction of the irrigation date of a crop. Based on various factors such as weather, soil moisture levels, and crop health. The irrigation forecast is generated using an algorithm that uses forecasted weather data, NDVI satellite imagery and crop health to calculate and predict when it will reach the Refill Point."
        }]}
      />
    </>,
    items: [
      { title: "Current Usage", action: valueFormatter(numericSystem, SoilProbesData.DailyUse, "mm / day") },
      { title: "Irrigaton Forecast", action: dateFormatter(numericSystem, aestToLocationLocalTimeConverter(SoilProbesData.IrrigationDueForecast, SoilProbesData.GMTDifference), true) },
      { title: "Method", action: SoilProbesData.IrrigationMethod },
      {
        title: "Default Usage", action: <TextField
          size="small"
          type="number"
          value={(defaultUsage === undefined || defaultUsage === null) ? '' : defaultUsage}
          onChange={(e) => {
            setHasChanged(true);
            setDefaultUsage(e.target.value);
          }}
          className={styles["text-field-width"]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {getUnitName(numericSystem, "mm / day")}
              </InputAdornment>
            ),
          }}
        />
      },
      { title: "Irrigation Forecast (Default)", action: dateFormatter(numericSystem, aestToLocationLocalTimeConverter(SoilProbesData.IrrigationDueDefault, SoilProbesData.GMTDifference), true) },

    ]
  };

  return <GraphPageDataCard
    title={LocationCard.title}
    items={LocationCard.items}
    showSaveButton={hasChanged}
    loading={loading}
    onSubmit={handleSubmit}
    id="IrrigationCard" />
}
