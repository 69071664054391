import { useState } from "react";
import { http } from "../../api/http";
import { QueryFunction, QueryKey, useQuery } from "@tanstack/react-query";

const PATH: string = 'reportdata/RainEventData/GetRainEventDataSummaryForDateRange';

export type rainEventDataSummaryDataType = { DateTime: String; Rainmm?: number; RainmmForecast?: number; }[]

const useRainEventDataSummaryData = (locationID: string) => {
    const [dates, setDates] = useState<{ startDate: string, endDate: string }>();


    const fetchBOMForecastData: QueryFunction<rainEventDataSummaryDataType, QueryKey> = async ({ queryKey }: { queryKey: QueryKey }) => {
        const [, , startDate, endDate] = queryKey;
        if (startDate && endDate) {
            const query = `${PATH}/${locationID}/${startDate}/${endDate}/1`;
            const response = await http.get(query);
            if (response.data) return response.data;
        }
        return [] as rainEventDataSummaryDataType;
    };

    const dataQuery = useQuery<rainEventDataSummaryDataType>(
        ['rainEventDataSummary', locationID, dates?.startDate, dates?.endDate],
        fetchBOMForecastData,
        {
            enabled: (dates !== undefined && dates.startDate !== undefined && dates.endDate !== undefined),
            refetchOnWindowFocus: false
        }
    );

    // if you change the data in state and query key uses it then it refetches the data.
    const refetchDataWithDateRange = async (newStartDate: string, newEndDate: string) => {
        setDates({
            startDate: newStartDate,
            endDate: newEndDate
        })
    };


    return [dataQuery, refetchDataWithDateRange] as const;
};

export default useRainEventDataSummaryData;