import { Route, Routes } from "react-router-dom";
import DashboardIndex from "./DashboardIndex/DashboardIndex";
interface IDashboardProps {
    
}

function Dashboard(props: IDashboardProps) {

    return (
        <>
            <Routes>
                <Route index element={<DashboardIndex />} />
            </Routes>
        </>
    )
}

export default Dashboard;