import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { http } from '../../api/http';

export interface LocationCropReportSummaryByLocationIDType {
    data: any[]
};

const LOCATION_CROP_REPORT_SUMMARY_BY_LOCATION_ID_PATH: string = 'reportdata/LocationData';

const useLocationCropReportSummaryByLocationIDData = (locationID: string) => {

    const query = LOCATION_CROP_REPORT_SUMMARY_BY_LOCATION_ID_PATH + `/${locationID}/LocationCropReportSummary`;
    const fetchLocationCropReportSummaryByLocationID = async () => {
        const response: LocationCropReportSummaryByLocationIDType = await http.get(query);
        return response;
    };

    const LocationCropsByLocationID = useQuery({ queryKey: ['LocationCropReportSummaryByLocationID'], queryFn: fetchLocationCropReportSummaryByLocationID, enabled: false });

    return LocationCropsByLocationID;
}

export default useLocationCropReportSummaryByLocationIDData;