import * as React from 'react';
import { SoilTotalContext } from '../../context/FieldDetailsContext/SoilTotalContext'; 

const useSoilDetailContext = () => {
    const context = React.useContext(SoilTotalContext);

    if (context === undefined) {
        throw new Error("useSoilDetailContext was used outside of its Provider");
      }
    return context;
}

export default useSoilDetailContext;