import { Stack, createTheme, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface IAppProps {
    className: string
}


const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    }
});

function LoginText(props: IAppProps) {
    const { t } = useTranslation("LoginText")
    return (
        <div className={props.className}>
            <ThemeProvider theme={theme}>
                <Stack direction='column' spacing={1}>
                    <div style={{
                        font: 'normal normal 500 36px Montserrat',
                        color: '#FFFFFF',
                    }}>{t("LoginText-title")}</div>
                    <div id="rectangle" style={{
                        width: '50px',
                        height: '4px',
                        background: '#0F68FD'
                    }}></div>
                    <div style={{
                        fontWeight: 'lighter',
                        color: '#FFFFFF',
                        font: '15px Montserrat'
                    }}>{t("LoginText-subtitle")}</div>
                    <div style={{
                        fontWeight: 'lighter',
                        color: '#000000',
                        font: '500 15px Montserrat'
                    }}>{process.env.REACT_APP_VERSION}</div>
                </Stack>
            </ThemeProvider>
        </div>
    );
}

export default LoginText;
