import { BrowserRouter, redirect } from 'react-router-dom';
import RouterComponent from './router/routerComponent';
import './App.css';

// import i18n (needs to be bundled ;)) 
import './locales/i18n'
import { ErrorBoundary } from 'react-error-boundary';
import { PermissionProvider } from "react-permission-role";
import OopsPage from './pages/Error/Oops';
import CacheBuster from 'react-cache-buster';

import getVersionFromLocalStorage from './utils/Versioning';

function App() {

  return (
    <CacheBuster
      currentVersion={getVersionFromLocalStorage()}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={process.env.REACT_APP_ENV !== "prod"} //If true, the library writes verbose logs to console.
      metaFileDirectory={"."}
      reloadOnDowngrade={true}
      onCacheClear={(refreshCacheAndReload: Function) => {
        process.env.REACT_APP_ENV !== "prod" && console.log("CACHE CLEARED");

        fetch('/meta.json')
          .then((res) => res.json())
          .then((jsonData: { version: string }) => {
            const { version: metaVersion } = jsonData;
            localStorage.setItem("version", metaVersion);
            refreshCacheAndReload();
          }).catch((e) => { console.error("Something went wrong with cache. " + e) });
      }}
    >
      <div className='App'>
        <PermissionProvider>
          <BrowserRouter>
            <ErrorBoundary FallbackComponent={OopsPage}>
              <RouterComponent />
            </ErrorBoundary>
          </BrowserRouter>
        </PermissionProvider>
      </div>
    </CacheBuster>
  );
}

export default App;