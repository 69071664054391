import pjson from "../../package.json";

export default function getVersionFromLocalStorage() {
    let version = localStorage.getItem("version");
    const versions = pjson.version.split(".")
    if(versions.length === 4) {
        versions.splice(2, 2, `${versions[2]}${versions[3]}`)
        version = versions.join('.')
    }
    
    if (version) { 
        return version;
    }

    localStorage.setItem("version", pjson.version);
    return version
}