import { Switch, Typography } from "@mui/material";
import { FunctionComponent, useEffect } from "react";
import SettingsDropDown from "./SettingsDropDown";
import TextActionList from "../../../components/TextListIcon/TextActionList";

import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from "react-redux";
import { setDateRange, setDebug, setLanguage, setNumericSystem, setTimeZone, userState } from "../../../stores/Slices/userSlice";
import useAuth from "../../../hooks/useAuth";
import { gql } from "@apollo/client";
import i18n from "../../../locales/i18n";
import { http } from "../../../api/http";
import { secondaryClient } from "../../../api/apollo";
import useTimeZones from "../../../hooks/ApiHooks/useTimezones";
import { useAppSelector } from "../../../stores/hooks";
import { useTranslation } from "react-i18next";
import pjson from "../../../../package.json";


const SET_DATE_RANGE = gql(`
mutation ($prefrences: String!) {
  UpdateUserSetting (
    input: {
        dateRange: $prefrences
      authToken: ${localStorage.getItem("access_token")}
    }
  ) {
    dateRange
  }
}
`);

const SET_NUMERIC_SYSTEM = gql(`
mutation ($prefrences: String!, $accessToken: String!) {
  UpdateUserSetting (
    input: {
      numericSystem: $prefrences
      authToken: $accessToken
    }
  ) {
    numericSystem
  }
}
`);

const SET_LANGUAGE = gql(`
mutation ($prefrences: String!) {
  UpdateUserSetting (
    input: {
      language: $prefrences
      authToken: ${localStorage.getItem("access_token")}
    }
  ) {
    language
  }
}
`);


const SET_TIMEZONE = gql(`
mutation ($prefrences: String!, $accessToken: String!) {
  UpdateUserSetting (
    input: {
      timezone: $prefrences
      authToken: $accessToken
    }
  ) {
    timezone
  }
}
`);


interface ISettingsProps {

}

const TIMEZONE_ABBREVIATION_WIDTH = 720;
const MYFIELDS_MIN_SHRINK_WIDTH = 600;
const SettingsIndex: FunctionComponent<ISettingsProps> = () => {
  const { t } = useTranslation("Settings")

  const user = useAppSelector(state => state.user);
  const TimeZonesQuery = useTimeZones();

  useEffect(() => {
    TimeZonesQuery.refetch().then((a) => {
      if (a.isError) {
        onLogout()
      }
    });
  }, []);

  const items2 = [
    // { name: "Terms and Conditions", action: <Typography> </Typography>, showSideArrow: true },
    // { name: "Privacy Policy", action: <Typography> </Typography>, showSideArrow: true },
    {
      name: "About",
      action: <Typography>
        <span onDoubleClick={() => alert("Heyy, you discovered the easter egg! Congratulations!")}>
          {t("Settings-Version") + pjson.version}
        </span>
      </Typography>,
      showSideArrow: false
    }
  ];

  // TODO optimize by removing the save function and using the mutate function directly 
  const saveDateRange = (value: string) => {
    secondaryClient.mutate({
      mutation: SET_DATE_RANGE,
      variables: { prefrences: value }
    }).then(() => {
      dispatch(setDateRange(value));
    }
    )
  }

  const saveNumericSystem = (value: string) => {
    Promise.all([
      secondaryClient.mutate({
        mutation: SET_NUMERIC_SYSTEM,
        variables: { prefrences: value, accessToken: localStorage.getItem('access_token')?.replaceAll("\"", '') }
      }).then(() => {
        dispatch(setNumericSystem(value));
      }),
      http.post("api/userProfileDetailed/userProfileDetailed", {
        MetricImperial: value == "M" ? 0 : 1
      })]).catch(e => { })
  }

  const saveLanguage = (value: string) => {
    i18n.changeLanguage(value);
    secondaryClient.mutate({
      mutation: SET_LANGUAGE,
      variables: { prefrences: value }
    }).then(() => {
      dispatch(setLanguage(value));
    })
  }

  const saveTimeZone = (value: userState["timezone"]) => {
    secondaryClient.mutate({
      mutation: SET_TIMEZONE,
      variables: { prefrences: value.ID, accessToken: localStorage.getItem('access_token')?.replaceAll("\"", '') }
    }).then(() => {
      dispatch(setTimeZone(value));
    })
  }

  const dimensions = useAppSelector(state => state.topbar.dimensions)
  const isMobile = useAppSelector(state => state.topbar.isMobile)

  const TimeZoneData: Array<any> = TimeZonesQuery.data
    ? TimeZonesQuery.data?.data?.value
      .filter((a: any) => a.TimeZoneCode !== "SERVER")
      .map((a: any) => ({
        key: a.TimeZoneID,
        displayValue: (dimensions.width <= TIMEZONE_ABBREVIATION_WIDTH) ? `${a.TimeZoneCode}` : `${a.TimeZoneCode} - ${a.TimeZoneName}`
      }))
    : [];

  const timeZoneDropdown = TimeZoneData.length > 0 ? <SettingsDropDown
    defaultValue={user.timezone.ID ?? 28} // default to USCST
    options={TimeZoneData}
    onChange={(e) => {
      const timeZoneData: Array<userState["timezone"]> = TimeZonesQuery.data?.data?.value.map((a: any): userState["timezone"] => ({
        name: a.TimeZoneName,
        code: a.TimeZoneCode,
        AESTDifference: a.GMTDifference,
        ID: a.TimeZoneID
      }))
      const foundTimeZone = timeZoneData.find(a => a.ID === Number(e.target.value))
      if (foundTimeZone) saveTimeZone(foundTimeZone)
    }}
  /> : <></>

  const langDropdown = <SettingsDropDown
    defaultValue={user.language}
    options={[
      { key: "enAU", displayValue: "En-Aust" },
      { key: "enUS", displayValue: "En-USA" },
    ]}
    onChange={(e) => { saveLanguage(e.target.value); }}
  />

  const dateRangeDropdown = <SettingsDropDown
    defaultValue={user.dateRange}
    options={[
      { key: "-1", displayValue: "Since Start" },
      { key: "7", displayValue: "7 Days" },
      { key: "30", displayValue: "30 Days" },
      { key: "60", displayValue: "60 Days" },
      { key: "180", displayValue: "180 Days" },
    ]}
    onChange={(e) => { saveDateRange(e.target.value) }}
  />

  const numericSystemDropdown = <SettingsDropDown
    defaultValue={user.numericSystem}
    options={[
      { key: "I", displayValue: "Imperial" },
      { key: "M", displayValue: "Metric" },
    ]}
    onChange={(e) => { saveNumericSystem(e.target.value) }}
  />


  let items: { name: string; action: JSX.Element; navigateTo?: string; showSideArrow?: boolean, hoverOverEffect?: boolean, customHeight?: string }[] = [
    { name: "Numeric System", action: numericSystemDropdown },
    { name: "Date Range", action: dateRangeDropdown },
    { name: "Time Zone", action: timeZoneDropdown },
    // { name: "Language", action: langDropdown },
    // { name: "Notifications", action: <Typography> </Typography>, showSideArrow: true },
  ]

  const dispatch = useDispatch();
  const { role: roles }: { role: string[] } = useSelector((state: any) => state.user);
  const isNotAdmin = roles.findIndex((role: string) => role == "Goanna Administrators") == -1;

  const { debug }: { debug: boolean } = useSelector((state: any) => state.user);
  const { preferredFields }: { preferredFields: number[] } = useSelector((state: any) => state.user);

  if (isNotAdmin || debug)
    items.splice(3, 0, {
      name: "My Fields",
      action: <Typography width={dimensions.width < MYFIELDS_MIN_SHRINK_WIDTH ? "140px" : "100%"}>{preferredFields.length} field{preferredFields.length > 1 || preferredFields.length == 0 ? "s" : undefined} will be shown in overview</Typography>,
      showSideArrow: true,
      navigateTo: 'myfields',
      hoverOverEffect: true,
    });

  const { onLogout } = useAuth();


  return (
    <>
      {
        isNotAdmin
          ? null
          : <TextActionList items={[
            {
              name: "DEBUG",
              action: <Switch
                sx={{ marginRight: "-8px" }}
                checked={debug}
                onChange={() => dispatch(setDebug(!debug))}
                name="loading"
                color="primary"
              />
            }
          ]} />
      }
      {/* <TextActionList items={[{ name: "Organization", action: <Typography>Members (3)</Typography>, showSideArrow: true }]} /> */}
      <TextActionList items={items} />
      {/* <TextActionList items={[{ name: "Password", action: <Typography> </Typography>, showSideArrow: true }]} /> */}
      <TextActionList items={items2} />
      <TextActionList items={[{ name: "Log Out", action: <LogoutIcon htmlColor="#0958ff" />, onClickCallback: onLogout, hoverOverEffect: true }]} />
    </>
  );
}

export default SettingsIndex;


