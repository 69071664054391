import * as React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import styles from './style.module.css';
import Tile from './tile';
import Crop from './Crop';
import { Link } from 'react-router-dom';

export interface IIrrigationForecastItemProps {
    locationID: number,
    cropType: string,
    varietyName: string
    fieldName: string,
    farmName: string,
    irrigationForecast: number[],
}

export default function IrrigationForecastItem(props: IIrrigationForecastItemProps) {

    const ok_Color = '#2D9C41';
    const irrigate_Color = '#1085FD';
    const stress_Color = '#E22C29';
    const null_color = '#979797';
    const fieldName = props.fieldName !== '' ? props.fieldName : 'N/A';
    const farmName = props.farmName !== '' ? props.farmName : 'N/A';

    return (
        <div className={styles.irrigationForcastItemwrapper}>
            <div className={styles.titleWrapper}>
                {
                    props.cropType ?
                        <Crop crop={props.cropType} variety={props.varietyName} /> : <></>
                }
                <Link
                    to={`../Fields/${String(props.locationID)}`}
                    className={styles.fieldDetailLinkWrapper}
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className={styles.fieldDetailLink}>{fieldName}</div>
                    {farmName === 'N/A' ? <></> : <div className={styles.farmName}>{'(' + farmName + ')'}</div>}
                    <div><ChevronRightIcon className={styles.rightIcon}/></div>
                </Link>
            </div>
            <div className={styles.wrapper}>
                {props.irrigationForecast.map((c, index) => {
                    switch (c) {
                        case 0:
                            return <Tile key={index} color={ok_Color} />
                        case 1:
                            return <Tile key={index} color={irrigate_Color} />
                        case 2:
                            return <Tile color={stress_Color} />
                        case 3:
                            return <Tile color={null_color} />
                    }
                })}
            </div>
        </div>
    );
}
