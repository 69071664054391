import * as React from 'react';
import { init, getInstanceByDom } from "echarts";
import type { CSSProperties } from "react";
import type { EChartsOption, ECharts, SetOptionOpts } from "echarts";

import { useSelector } from 'react-redux';

export interface ReactEChartsProps {
    option: EChartsOption;
    style?: CSSProperties;
    settings?: SetOptionOpts;
    onLegendChange?: Function;
    loading?: boolean;
    theme?: "light" | "dark";
    className?: string;
    onEvents?: Record<string, (params: any) => void>; 
  }

  export function ReactECharts({
    option,
    style,
    settings,
    loading,
    className,
    theme,
    onLegendChange,
    onEvents
  }: ReactEChartsProps): JSX.Element {
    const chartRef = React.useRef<HTMLDivElement>(null);

    const { navbarOpen }: {navbarOpen: boolean} = useSelector((state: any) => state.navbar);
    React.useEffect(() => {
      setTimeout(() => {
        if(chartRef.current !== null) {
          const chart = getInstanceByDom(chartRef.current);
          chart?.resize();
        }
      }, 300)
    }, [navbarOpen]);
    
  React.useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme, { renderer: 'canvas' });
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart);

    function handleLegendClick(params: any) {
      
      if (onLegendChange) {
        onLegendChange(params)
      }
    }

    chart?.on("legendselectchanged", handleLegendClick);

    if (onEvents && chart) {
      for (const eventName in onEvents) {
        chart.on(eventName, onEvents[eventName]);
      }
    }

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
      chart?.off("legendselectchanged", handleLegendClick);

      if (onEvents && chart) {
        for (const eventName in onEvents) {
          chart.off(eventName, onEvents[eventName]);
        }
      }
    };
  }, [theme, onEvents]);

  React.useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart?.setOption(option, settings);
    }
  }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  React.useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      loading === true ? chart?.showLoading() : chart?.hideLoading();
    }
  }, [loading, theme]);

    return <div ref={chartRef} style={{ width: "100%", height: "100px", ...style }} className={className}/>;
  }