import { useAppSelector } from "../stores/hooks";
import { RootState } from "../stores/store";
import { ImperialTypesSupported, MetricTypesSupported, valueRounder } from "./numericConversions";
import { DateTime } from "luxon";

export function valueFormatter(value: number | string, unit: string) {
    return typeof value !== 'string' ? `${valueRounder(value)} ${unit}` : `${value} ${unit}`
}

export function getDateTimeFormatForGraphs(numericSystem: string, showTime: boolean = true) {
    return (numericSystem === "M" ? "dd/MM/yyyy" : "MM/dd/yyyy") + (showTime ? " HH:mm:ss" : "")
}

export function useTooltipFormatterWithDateFormat(
    unitMapping: {
        [key: string]: string | MetricTypesSupported | ImperialTypesSupported
    },
    showTime: boolean = true,
    showNullValues: boolean = true
) {

    const numericSystem = useAppSelector((state: RootState) => state.user.numericSystem);
    const datetimeFormat = getDateTimeFormatForGraphs(numericSystem, showTime);

    const formatter = (param: any) => {
        let _dateTime = DateTime.fromISO(param[0].axisValueLabel)

        if (!_dateTime.isValid) {
            _dateTime = DateTime.fromFormat(param[0].axisValueLabel, numericSystem === "M" ? "dd/MM/yyyy HH:mm:ss" : "MM/dd/yyyy HH:mm:ss")
        }

        if (!_dateTime.isValid) {
            console.error("DateTime conversion failed in useTooltipFormatterWithDateFormat")
        }

        let returnValue = `
            <div style="margin-bottom:2px;">
                ${_dateTime.isValid ? _dateTime.toFormat(datetimeFormat) : param[0].axisValueLabel}
            </div>`;

        for (var i = 0; i < param.length; i++) {
            if (param[i].data && (param[i].data.length === 2)) {
                if ((param[i].data[1] === null && showNullValues) || param[i].data[1] !== null)
                returnValue +=
                    `<div>
                        <span style="display:flex;flex-direction:row;justify-content:space-between">
                            <span>
                                <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param[i].color};"></span>
                                ${param[i].seriesName}:&nbsp &nbsp &nbsp &nbsp
                            </span>
                            <span>${(param[i].data[1] !== null ? valueFormatter(param[i].data[1], unitMapping[param[i].seriesName]) : '-')}&nbsp </span>
                        </span>
                    </div>`;
            }
        }
        return returnValue;
    }

    return formatter;

}