import * as React from 'react';
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomDialog from '../../../../../components/CustomDialog/CustomDialog';

interface SelectedPoint {
    name: string;
    value: number;
}

export interface ISatelliteImageDialogProps {
    open: boolean;
    onClose: () => void;
    winURL: string;
    isLoading: boolean;
}

export default function SatelliteImageDialog({ open, onClose, winURL, isLoading }: ISatelliteImageDialogProps) {

    const [showLoadingSign, setShowLoadingSign] = React.useState(true)

    React.useEffect(() => {
        //give a few seconds delay to cover the period before iframe is loaded
        if (!isLoading) {
            setTimeout(() => {
                setShowLoadingSign(false)
            }, 5000);
        }
        else setShowLoadingSign(true);
    }, [isLoading])


    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            title="Satellite Image"
            isLoading={showLoadingSign}
            content={
                <>
                    {isLoading && winURL ? (
                        <></>
                    ) :
                        <>
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <iframe src={winURL} width="100%" height="100%" title="Satellite Image" style={{ border: 'none', margin: 'auto' }}></iframe>
                            </div>
                        </>
                    }
                </>
            }
        />
    );
}


