import * as React from 'react';

import styles from './style.module.css';

export interface ITopTileProps {
  day: string,
  date: number,
  color: string,
  totalIrrigation: number
}

export default function TopTile(props: ITopTileProps) {

  return (
    <div className={styles.topTile}
      style={{
        backgroundColor: props.color
      }}>
      <div className={`${styles.topTileItem} ${styles.days}`}>{props.day}</div>
      <div className={`${styles.date} ${styles.topTileItem}`}>{props.date}</div>
      {props.totalIrrigation === 0 ?
        <div className={`${styles.totalIrrigationNoneDisplay} ${styles.topTileItem}`}>{'  '}</div> :
        <div className={`${styles.totalIrrigation} ${styles.topTileItem}`}>
          <div className={styles.rainDropBall}></div>
          {props.totalIrrigation}
        </div>}
    </div>
  );
}
