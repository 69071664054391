import * as React from 'react';
import styles from './style.module.css';

export interface IScheduleCalendarEtcTileProps {
    Etc: string,
    color: string,
}

export default function ScheduleCalendarEtcTile (props: IScheduleCalendarEtcTileProps) {
  return (
    <div className={styles.ScheduleCalendarEtcTile}
      style={{
        backgroundColor: props.color
      }}>
      <div>{props.Etc}</div>
    </div>
  );
}
