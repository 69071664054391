import { Drawer } from '@mui/material';
import * as React from 'react';

export interface IFieldDetailHeaderProps {
  backgroundColor: string,
  children: React.ReactNode,
  className?: string
}

export default function FieldDetailHeader(props: IFieldDetailHeaderProps) {
  return (
    <div className={props.className ?? ''} style={{
      width: '100%',
      background: `${props.backgroundColor}`,
      height: "84px",
      display: "flex",
      alignItems: "center",
    }}>
      {props.children}
    </div >
  );
}
