import React from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IPopUpDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  isLoading: boolean;
  content: React.ReactNode;
}

export default function CustomDialog({
  open,
  onClose,
  title,
  isLoading,
  content,
}: IPopUpDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" PaperProps={{ style: { width: '100vw', height: '100vh' } }}>
      <DialogTitle
        style={{
          padding: '16px 24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '38px',
          paddingRight: '28px'
        }}
      >
        {title}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          width: '95%',
          height: 'calc(100% - 64px)', // Subtracting the DialogTitle height
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: '0px',
          paddingRight: '0px'
        }}
      >
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress />
          </div>
        )}
        <div style={{ width: '100%', height: '100%' }}>
          {content}
        </div>
      </DialogContent>
    </Dialog>
  );
}