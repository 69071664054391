import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface IResetDialogProps {
  date: DateTime | null,
  setDate: Function,
  currentResetDate: DateTime | null,
  open: boolean,
  resetLoading: boolean,
  handleClose: () => void,
  handleReset: () => void,
  locationDateNow: string,
}

export default function ResetDialog({ date, setDate, currentResetDate, open, resetLoading, handleClose, handleReset, locationDateNow }: IResetDialogProps) {

  const { t } = useTranslation("ResetDialog");

  const [hour, setHour] = useState(0);

  const hours = React.useMemo(
    () => {
      let baseHours = Array.from({ length: 24 }, (_, i) => i);
      let isoLocationDate = DateTime.fromISO(locationDateNow);

      if(!date || !currentResetDate)
        return baseHours

      if (date.toISODate() === isoLocationDate.toISODate())
        baseHours = baseHours.filter(hr => hr <= isoLocationDate.hour)
      else if (date.toISODate() === currentResetDate.toISODate())
        baseHours = baseHours.filter(hr => hr >= currentResetDate.hour)
      
      return baseHours;
    },
    [ currentResetDate, locationDateNow, date ]
  )

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>{t("title")}</DialogTitle>
      <DialogContent>
        {
          (resetLoading && !(currentResetDate && locationDateNow))
            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80px', minWidth: '258px' }}>
              <CircularProgress />
            </div>
            : <>
              <DialogContentText>
                {t("question")}
              </DialogContentText>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  format='dd/MM/yyyy'
                  label=""
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue?.set({hour: hour, minute: 0, second: 0}));
                    setHour(hour);
                  }}
                  sx={{width: "120px"}}
                  minDate={currentResetDate?.plus({ days: 1 })}
                  maxDate={DateTime.fromISO(locationDateNow)}
                />
                <Select
                  value={hour}
                  onChange={(event) => {
                    setDate(date?.set({hour: Number(event.target.value), minute: 0, second: 0}));
                    setHour(Number(event.target.value));
                  }}
                  style={{ marginLeft: '16px' }}
                >
                  {hours.map((hour) => (
                    <MenuItem key={hour} value={hour}>
                      {hour}:00
                    </MenuItem>
                  ))}
                </Select>
              </LocalizationProvider>
            </>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset}>{t("reset")}</Button>
        <Button onClick={handleClose}>{t("cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}
