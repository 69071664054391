import { CircularProgress, IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

export interface IPDFAddButtonProps {
    isMenuItem?: boolean,
    pdfLoading: boolean,
    loading: boolean,
    onClick: () => void,
    onClose?: () => void,
}

export default function PDFAddButton({ isMenuItem, pdfLoading, loading,  onClick, onClose }: IPDFAddButtonProps) {

    return (
        <>
            {
                !isMenuItem
                    ?
                    pdfLoading ? <CircularProgress size={24} style={{ padding: '8px' }} />
                        :
                        <IconButton aria-label="share" color='primary' onClick={onClick} disabled={loading}>
                            <DownloadIcon />
                        </IconButton>
                    :
                    <MenuItem
                        onClick={() => {
                            onClick();
                            onClose && onClose();
                        }}
                    >
                        <ListItemIcon>
                            {
                                pdfLoading ? <CircularProgress size={24} style={{ padding: '8px' }} />
                                    :
                                    <IconButton aria-label="share" color='primary' disabled={loading}>
                                        <DownloadIcon />
                                    </IconButton>
                            }
                        </ListItemIcon>
                        <ListItemText>Dowload PDF</ListItemText>
                    </MenuItem>
            }

        </>
    );
}
