import { Link, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import ErrorPage from "../ErrorPage";
import Image404 from "../../../assets/images/Error/404.png"
import { useNavigate } from "react-router";
import { Trans, useTranslation } from "react-i18next";

interface IError404Props {

}

const Error404: FunctionComponent<IError404Props> = () => {
    const { t } = useTranslation("Errors")
    const navigate = useNavigate();

    function GoBack() {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
        }
    }

    return (
        <ErrorPage
            title={t('error404.title')}
            description1={
                <>
                    <Typography fontWeight="bold" fontFamily="Poppins" fontSize={18}>
                        {t('error404.subtitle')}
                    </Typography><br />
                    <Typography fontFamily="Poppins" fontSize={15}>
                        <Trans i18nKey="Errors:common.desc1" components={[<Link href="#" onClick={GoBack} />]} />
                        {t('common.desc2')}
                    </Typography>
                </>
            }
            description2={
                <>
                    <Typography fontFamily="Poppins" fontSize={15}>
                        {t('common.desc3')}
                    </Typography><br />
                    <Typography fontWeight="bold" fontFamily="Poppins" fontSize={15}>
                        {t('common.desc4')}
                    </Typography>
                </>
            }
            imageURL={Image404}
        />
    );
}

export default Error404;