import { Box } from '@mui/material';
import * as React from 'react';
import GoannaCard from '../../../components/GoannaCard/GoannaCard';
import { AddRemoveButtonDisplay } from '../../../components/GoannaCard/GoannaCardHelper';
import BasicCard from './BasicCard';
import Banner from './Banner';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { secondaryClient } from '../../../api/apollo';
import DataTable from './DataTable';
import CircleStats from './CircleStats';
import GaugeStats from './GaugeStats';
import ReadOutStats from './ReadOutStats';

export interface IEndOfSeasonReportProps {
}

export default function EndOfSeasonReport(props: IEndOfSeasonReportProps) {

  const params = useParams();
  const [locationID, setLocationID] = React.useState<number>(parseInt(params.locationID ?? ''));

  const END_OF_SEASON_REPORT = gql(`
  query ($locationId: Int!, $year: Int!) {
  seasonReport(where: { locationId: $locationId, year: $year }) {
      locationID
      year
      locationDescription
      plantingDate
      floweringDate
      maturityDate
      firstIrrigationDate
      lastIrrigationDate
      irrigationsCount
      irrigAvgDaysBetween
      rainTotalmm
      rainDays
      avgSoilTotalInc
      minSoilTotalInc
      maxSoilTotalInc
      cummulativeDayDegrees
      totalWaterUsedML
      stressTimeAboveThreshold
      addtheStressImpact
      stressImpact
      waterloggingDays
      firstWaterloggingDate
      lastWaterloggingDate
      avgDailyImpact
      totalSeasonImpactmm
      totalSeasonImpactkg
      totalSeasonImpactDollar
      plantToFlower
      kCMaturityDate
      avgMaturity
      plantToMaturity
      approved
  }
}
  `)

  const endOfSeasonReportData = React.useMemo(async () => {

    return await secondaryClient.query({
      query: END_OF_SEASON_REPORT,
      variables: {
        locationId: locationID,
        year: 2022
      }
    })
  }, [locationID, END_OF_SEASON_REPORT])

  React.useEffect(() => {
    console.log('got data', endOfSeasonReportData)
  }, [endOfSeasonReportData])


  return (
    <div>
      <Banner />
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        flexDirection={"row"}
        gap={"40px"}
        alignItems="center"
        justifyContent="center"
        style={{
          padding: "3rem 1rem 3rem 1rem",
          background: "#f4f7fc",
          fontFamily: 'poppins'
        }}
      >
        <BasicCard
          title={<h1
            style={{
              fontSize: "1.2rem"
            }}
          >Data Table</h1>}
        >
          <DataTable />
        </BasicCard>
        <BasicCard
          title={<h1
            style={{
              fontSize: "1.2rem"
            }}
          >Water Applied</h1>}
        >
          <CircleStats
            center="810mm"
            // color={["#012677", "#005bc5", "#00b4fc"]}
            name="Water type applied"
            data={[
              { value: 80, name: "Starting Water", itemStyle: { color: "#012677" } },
              { value: 800, name: "Irrigation Applied", itemStyle: { color: "#005bc5" } },
              { value: 120, name: "In Season Rain", itemStyle: { color: "#00b4fc" } }
            ]}
          ></CircleStats>
        </BasicCard>
        <BasicCard
          title={<h1
            style={{
              fontSize: "1.2rem"
            }}
          >Growing Degree Days</h1>}
        >
          <GaugeStats
            data="20"
            color={["#e02130", "#fab243", "#2b5166", "#429867"]}
          ></GaugeStats>
        </BasicCard>
        <BasicCard
          title={<h1
            style={{
              fontSize: "1.2rem"
            }}
          >Overall Outcomes</h1>}
        >
          <ReadOutStats />
        </BasicCard>
        <BasicCard
          title={<h1
            style={{
              fontSize: "1.2rem"
            }}
          >Test</h1>}
        >
          <div>test</div>
        </BasicCard>
        <BasicCard
          title={<h1
            style={{
              fontSize: "1.2rem"
            }}
          >Test</h1>}
        >
          <div>test</div>
        </BasicCard>
        <BasicCard
          title={<h1
            style={{
              fontSize: "1.2rem"
            }}
          >Test</h1>}
        >
          <div>test</div>
        </BasicCard>
        <BasicCard
          title={<h1
            style={{
              fontSize: "1.2rem"
            }}
          >Test</h1>}
        >
          <div>test</div>
        </BasicCard>
      </Box>
    </div>
  );
}
