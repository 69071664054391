import { Alert, Button, Card, CardContent, CardHeader, CircularProgress, IconButton, InputAdornment, Link, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from '../styles.module.css';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { newPasswordFormSchema as validationSchema } from '../../../utils/validations';
import { http } from '../../../api/http';
import { useEffect, useState } from 'react';
import { VisibilityOff, Visibility } from '@mui/icons-material';

export interface IAppProps {
  className: string
  showLogInUI: () => void
  loading: boolean,
  token: string,
  username: string
}

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  components: {
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontFamily: 'Poppins'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          background: 'linear-gradient(to right, #75B9F0, #1085FD)'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          marginRight: "7px",
          color: '#333',
        },
      },
    },
  }
});

function NewPasswordCard(props: IAppProps) {
  const [status, setStatus] = useState({ message: "", statusCode: 0 });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isResetTokenValid, setIsResetTokenValid] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);


  function togglePasswordVisibility() {
    setShowPassword(state => !state);
  }

  function toggleConfirmPasswordVisibility() {
    setShowConfirmPassword(state => !state);
  }

  async function validatePasswordResetToken(username: string, token: string) {
    setStatus({ message: "Loading...", statusCode: 200 })
    setIsResetTokenValid(false);

    let response = await http.get(`/api/validatePasswordResetToken?username=${username}&token=${token}`, {
      validateStatus: null // stops interceptor to handle error
    });

    if (response.data === "VALID") {
      setIsResetTokenValid(true);
      setStatus({ message: "", statusCode: 0 });
    }
    else {
      setStatus({ message: response.data, statusCode: response.status })
    }
  }

  useEffect(() => {
    validatePasswordResetToken(props.username, props.token)
  }, [props.token, props.username])

  const formik = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      setStatus({ message: "", statusCode: 0 })

      let response = await http.get(`/api/setNewPassword?username=${props.username}&token=${props.token}&newPassword=${values.password}`, {
        validateStatus: null // stops interceptor to handle error
      });

      setStatus({ message: response.data, statusCode: response.status })
      setLoading(false);
      setIsSubmitButtonDisabled(response.status === 200);
    }
  });

  const { t } = useTranslation("Login");

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ borderRadius: '10px', boxShadow: 0.5 }} className={props.className} variant='outlined'>
        <CardHeader className={styles.cardHeader} sx={{ paddingBottom: 0 }} title={t("Login-reset")} />
        <form onSubmit={formik.handleSubmit}>
          <CardContent className={styles.cardContent} sx={{ marginBottom: 0 }}>
            {isResetTokenValid
              ? <><div className={styles.loginTextField}>
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>,
                  }}
                />
              </div>
                <div className={styles.loginTextField}>
                  <TextField
                    fullWidth
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle Confirm Password visibility"
                          onClick={toggleConfirmPasswordVisibility}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>,
                    }}
                  />
                </div>
              </>
              : <></>
            }
            {
              status.statusCode > 0
                ? <div className={styles.loginTextField}>
                  <ThemeProvider theme={theme}>
                    <Alert style={{ paddingLeft: "11.34px" }} severity={status.statusCode > 200 ? 'error' : 'success'}>
                      {status.message}
                    </Alert>
                  </ThemeProvider>
                </div>
                : <></>
            }
            <div className={styles.loginTextField}>
              <Link href='#' onClick={() => props.showLogInUI()} style={{textDecoration: "none"}}>{t("Login-login")}</Link>
            </div>
            {
              isResetTokenValid
                ? <div className={styles.loginTextField}>
                  <Button
                    color="primary" variant="contained"
                    fullWidth type="submit"
                    disableElevation sx={{ "&:hover": { boxShadow: 3 } }}
                    size='large' disabled={loading || isSubmitButtonDisabled}
                  >
                    {
                      loading
                        ? <CircularProgress size={25} sx={{ color: 'white' }} />
                        : <span>{t("Login-reset")}</span>
                    }
                  </Button>
                </div>
                : <></>
            }
          </CardContent>
        </form>
      </Card>
    </ThemeProvider>
  );
}

export default NewPasswordCard;

