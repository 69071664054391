import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUnitName } from '../../../../../utils/numericConversions';
import { scheduleCalendarDataType } from './ScheduleCalendar';
import ScheduleCalendarEtcTile from './ScheduleCalendarEtcTile';
import styles from './style.module.css';

export interface IScheduleCalendarEtcProps {
  scheduleCalendarData: scheduleCalendarDataType[],
}

export default function ScheduleCalendarEtc(props: IScheduleCalendarEtcProps) {

  const { t } = useTranslation("scheduleCalendar")

  const numericSystem: string = useSelector((state: any) => state.user).numericSystem;
  return (
    <div className={styles.topTileWrapper}>
      <div className={styles.ScheduleCalendarEtcDescription}>
        {'Etc (' + getUnitName(numericSystem, "mm") + ")"}
      </div>
      {props.scheduleCalendarData.map((info, index) => {
        return <ScheduleCalendarEtcTile key={index} Etc={(info.EtcInfo === -999 || info.EtcInfo === undefined || info.EtcInfo < 0) ? t("scheduleCalendar-na") : String(info.EtcInfo)} color={index === 0 ? '#EAC858' : '#F4F7FC'} />
      })}
    </div>
  );
}
