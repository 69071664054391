import * as React from 'react';

import { FieldListComponentContext } from '../../pages/Dashboard/FieldList/FieldListComponent';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { rowsStateInitializer } from '@mui/x-data-grid/internals';
import { Button, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { GridFilterInputDate } from '@mui/x-data-grid';
import { styled, lighten, darken } from '@mui/system';

import styles from './style.module.css';
import { useTranslation } from 'react-i18next';

interface GroupingType {
  category: string;
  value: string;
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  fontWeight: 'bold',
  backgroundColor: '#F1F0F0'
}));

const GroupItems = styled('ul')({
  padding: 0,
});

export interface ISearchBarProps {
}

export default function SearchBar(props: ISearchBarProps) {


  const { preferedFields, fieldList, farmList, handleFilterApply, filterObject, setFilterObject } = React.useContext(FieldListComponentContext);
  /* console.log('========', preferedFields); */

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const didMount = React.useRef(false);
  const [optionList, setOptionList] = React.useState<any[]>([]);
  const [preferedFieldsList, setPreferedFieldsList] = React.useState<any[]>([]);
  const [recommendList, setRecommendList] = React.useState<any[]>([]);
  const [input, setInput] = React.useState<string>('');
  const [Click, setClick] = React.useState<boolean>(true);
  const { t } = useTranslation("FieldListTopBar");

  const buildRecommendationList = () => {
    if (!localStorage.getItem('searchHistory')) {
      localStorage.setItem('searchHistory', JSON.stringify([]));
    }
    const searchHistory = JSON.parse(localStorage.getItem('searchHistory') || '[]') as string[];
    const tmp: GroupingType[] = [];
    if (preferedFields.length !== 0) {
      /* console.log('runnign'); */
      preferedFields.map((item) => {
        const groupingItem = {
          category: 'Suggestions',
          value: item
        }
        tmp.push(groupingItem);
      })
    }
    if (searchHistory.length !== 0) {
      searchHistory.map((item) => {
        const groupingItem = {
          category: 'Recent Searches',
          value: item
        }
        tmp.push(groupingItem);
      })
    }
    setRecommendList(tmp);
  };

  const handleLocalStorageUpdate = () => {
    buildRecommendationList()
  };

  const handleClick = () => {
    // console.log('click input is', input)
    if (input === '') {
      setFilterObject((prevFilter: any) => ({
        ...prevFilter,
        field: ['All'],
      }));
    }
    else {
      setFilterObject((prevFilter: any) => ({
        ...prevFilter,
        field: [input]
      }));
    }
    setClick(!Click);
    const searchHistory = localStorage.getItem('searchHistory');
    let tmp;
    if (searchHistory && input !== '') {
      tmp = (JSON.parse(searchHistory) as string[]);
      if (!tmp.includes(input)) {
        if (tmp.length < 5) {
          tmp.unshift(input);
        }
        else {
          tmp.unshift(input);
          tmp.pop();
        }
        localStorage.setItem('searchHistory', JSON.stringify(tmp));
      }
      handleLocalStorageUpdate();
    }

  }

  const handlePressEnter = (e: any) => {
    // console.log('enter input is', input)
    if (e.keyCode == 13) {
      // console.log('value', e.target.value);
      if (input === '') {
        setFilterObject((prevFilter: any) => ({
          ...prevFilter,
          field: ['all'],
        }));
      }
      else {
        setFilterObject((prevFilter: any) => ({
          ...prevFilter,
          field: [input]
        }));
      }
      setClick(!Click);
      const searchHistory = localStorage.getItem('searchHistory');
      let tmp;
      if (searchHistory && input !== '') {
        tmp = (JSON.parse(searchHistory) as string[]);
        if (!tmp.includes(input)) {
          if (tmp.length < 5) {
            tmp.unshift(input);
          }
          else {
            tmp.unshift(input);
            tmp.pop();
          }
          localStorage.setItem('searchHistory', JSON.stringify(tmp));
        }
        handleLocalStorageUpdate();
      }

    }
  }

  React.useEffect(() => {
    if (input === '') {
      setFilterObject((prevFilter: any) => (
        {
          ...prevFilter,
          field: ['All'],
        }
      ))
      setClick(!Click);
    }

  }, [input]);

  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    // console.log('filter object is now: ', filterObject);
    handleFilterApply(filterObject);
  }, [Click]);

  React.useEffect(() => {
    buildRecommendationList();
  }, [preferedFields]);

  React.useEffect(() => {
    let tmp: GroupingType[] = [];

    if (fieldList.length !== 0) {
      fieldList.map((item) => {
        const groupingItem = {
          category: 'Field',
          value: item
        }
        tmp.push(groupingItem);
      })
    }
    if (farmList.length !== 0) {
      farmList.map((item) => {
        const groupingItem = {
          category: 'Farm',
          value: item
        }
        tmp.push(groupingItem);
      })
    }
    setOptionList(tmp);
  }, [fieldList.length, farmList.length]);


  return (
    <div className={styles.searchBarWrapper}>
      <Autocomplete
        className={styles.autocomplete}
        fullWidth
        freeSolo
        disableClearable
        id="search-bar"
        options={input ? optionList : recommendList}
        groupBy={(option) => option.category}
        sx={{ backgroundColor: 'white', height: '2.5rem' }}
        getOptionLabel={(option) => option.value ?? option}
        onInputChange={(event, value) => {
          setInput(value);
        }}
        filterOptions={(options, state) => {
          return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
        }}
        onKeyDown={handlePressEnter}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            size='small'
            placeholder={t("SearchBar-SearchPlaceHolder")}
            sx={{ backgroundColor: 'white', }}
            autoFocus={false}
            InputProps={{
              ...params.InputProps,
              inputMode: 'search',
              type: 'search',
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
          />
        )}
        renderGroup={(params) => (
          <li>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
      />
      <Button
        className={styles.button}
        onClick={handleClick}
        sx={{ fontWeight: 'bold' }}>
        {t("SearchBar-SearchButton")}
      </Button>
    </div>
  );
}
