import { CircularProgress, createTheme, IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import { List } from '@mui/material';
import {
    DragDropContext,
    Droppable,
    DropResult
} from '@hello-pangea/dnd';
import { setTablePreference } from '../../../../../stores/Slices/userSlice';
import { gql } from '@apollo/client';
import { secondaryClient } from '../../../../../api/apollo';
import { useAppDispatch, useAppSelector } from '../../../../../stores/hooks';
import { useEffect, useState } from 'react';
import DraggableItem from '../../../FieldDetail/components/CustomizationButton/DraggableItem';
import useForecastContext from '../../../../../hooks/ContextHooks/useForecastContext';
import { ReactComponent as SettingIcon } from '../../../../../assets/images/Navbar/ic_setting.svg';

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins',
                    background: 'linear-gradient(to right, #75B9F0, #1085FD)',
                    width: '10rem',
                    height: '2.5rem',
                    marginTop: '0.3rem',
                    marginBottom: '0.3rem',
                    marginRight: '0.5rem',
                    marginLeft: '0.5rem'
                }
            }
        }
    }
});

/** names used for displaying in the menu */
const tablePrefrences: { [key: string]: string } = {
    "active": "Active",
    "cropName": "Crop",
    "cuEtc": "Cummulative ETc",
    "curr": "Current",
    "currUsage": "Current Usage",
    "etc": "Etc",
    "farm": "Farm",
    "field": "Field",
    "forecast": "Forecast",
    "fp": "Full Point",
    "irrforecast": "IrrigationForecast",
    "lst_update": "Last Update",
    "region": "Region",
    "rp": "Refill Point",
    "tdd": "Total Day Degrees",
    "today": "Today",
    "tz": "Time Zone",
    "variety": "Variety",
    "startDate": "Start Date",
    "plantingDate": "Planting Date",
    "accountName": "Account Name",
    "defaultUsage": "Default Usage",
    "irroforecastDefault": "Irrigation Forecast (Default)",
}

/** Maps the field names from columns to the id of the above display
 * we didnt use these values because we also need to initialize the above values in the user slice 
 * and that wasnt possible without having 2 different mappings. */
export const tablePrefrencesColumnsMapping: { [key: string]: string } = {
    "active": "Active",
    "cropName": "CropName",
    "currUsage": "DailyUse",
    "lst_update": "DateTime",
    "tdd": "DayDegrees",
    "etc": "DayWaterUse",
    "farm": "FarmName",
    "fp": "Fullpoint",
    "irrforecast": "IrrigationDueActual",
    "forecast": "IrrigationDueForecast",
    "field": "LocationDescription",
    "rp": "Refillpoint",
    "region": "RegionName",
    "curr": "SoilTotal",
    "tz": "TimeZoneCode",
    "cuEtc": "TotalWaterUse",
    "variety": "VarietyName",
    "today": "currentStatus",
    "startDate": "StartDate",
    "plantingDate": "PlantingDate",
    "accountName": "AccountName",
    "defaultUsage": "DefaultUsage",
    "irroforecastDefault": "IrrigationDueDefault"
}

export interface ICustomizationButtonProps {
}

const SET_TABLE_PREFERENCE = gql(`
mutation ($prefrences: String!) {
  UpdateUserSetting (
    input: {
        tablePreference: $prefrences
      authToken: ${localStorage.getItem("access_token")}
    }
  ) {
    tablePreference
  }
}
`);

const reorder = (
    list: any[],
    startIndex: number,
    endIndex: number
) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/*
 * 0 - Not Selected
 * 1 - Selected
 * 2 - RESERVED (for future)
 * 3 - Always Selected
*/

export default function CustomizationButton(props: ICustomizationButtonProps) {

    const dispatch = useAppDispatch();
    const { columns, setColumns } = useForecastContext()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const isPopoverOpen = Boolean(anchorEl);
    const gql_tablePref = useAppSelector(state => state.user.tablePreference)
    const [localItems, setLocalItems] = useState<{ name: string; checked: number; }[]>(gql_tablePref["ProbeTable"] || []);
    const [hasChanged, setHasChanged] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);

    const id = isPopoverOpen ? 'simple-popover' : undefined;
    const openPopoverMenu = (event: React.MouseEvent<HTMLElement>) => {
        // Refresh local items to match the redux becuase it can be changed in other places
        setLocalItems(gql_tablePref["ProbeTable"])

        // open the menu
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (columns.length > 0 && initialLoad) {
            setInitialLoad(false)

            setColumns(localItems.map(
                (item) => columns.find(a => a.field == tablePrefrencesColumnsMapping[item.name])
            ))
            setLoading(false)
        }
    }, [columns]);

    // reset the data
    const handleClose = () => {
        setAnchorEl(null);
        if (hasChanged) handleSave();
    };

    // save clicked, save it to redux and graphql
    const handleSave = () => {
        setLoading(true);
        setColumns(localItems.map(
            (item) => columns.find(a => a.field == tablePrefrencesColumnsMapping[item.name])
        ))

        const updatedtablePreference = {
            ...gql_tablePref,
            "ProbeTable": localItems
        }

        secondaryClient.mutate({
            mutation: SET_TABLE_PREFERENCE,
            variables: { prefrences: JSON.stringify(updatedtablePreference) },
        })
            .catch((e) => console.error(e))
            .finally(() => setLoading(false))

        dispatch(setTablePreference(updatedtablePreference))
        setAnchorEl(null);
    }

    const onDragEnd = ({ destination, source }: DropResult) => {

        // dropped outside the list
        if (!destination) return;

        const newItems = Array.from(localItems);
        const [reorderedItem] = newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, reorderedItem);
        setLocalItems(newItems);
        setHasChanged(true);
    };

    const handleItemSelect = (itemName: string) => {
        const itemsClone: typeof localItems = JSON.parse(JSON.stringify(localItems));
        const index = itemsClone.findIndex(item => item.name === itemName)

        // These are error for devs, in production if this is happening you fu**ed up.
        if (index === -1) {
            console.error("No Item Found with that id when selecting graphPrefrence");
            return;
        }

        if (itemsClone[index].checked === 3) {
            console.error("Uneditable field when selecting graphPrefrence");
            return;
        }

        itemsClone[index].checked = itemsClone[index].checked === 1 ? 0 : 1;
        setLocalItems(itemsClone);
        setHasChanged(true);
    }

    return (
        <>
            <IconButton
                id="customization-button"
                color="primary"
                aria-controls={isPopoverOpen ? 'customization-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isPopoverOpen ? 'true' : undefined}
                onClick={openPopoverMenu}
                sx={{ fontSize: 24, backgroundColor: isPopoverOpen ? "#0958ff0a" : "#FFFFFF00" }}
                disabled={loading}
            >{loading ? <CircularProgress size={24} /> : <SettingIcon filter="invert(23%) sepia(43%) saturate(6539%) hue-rotate(221deg) brightness(100%) contrast(102%)" />}
            </IconButton>
            <Popover
                id={id}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List sx={{ width: '372px', maxHeight: '75vh', maxWidth: 600, bgcolor: 'background.paper' }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable-list">
                            {(provided: any) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                        localItems.map((element, index) => {
                                            const labelId = `checkbox-list-label-${index}`;

                                            return <DraggableItem
                                                handleItemSelect={handleItemSelect}
                                                key={element.name}
                                                displayValue={tablePrefrences[element.name]}
                                                internalID={element.name}
                                                labelId={labelId}
                                                index={index}
                                                checked={element.checked}
                                                totalItems={localItems.length}
                                            />
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </List>
            </Popover>
        </>
    );
}

interface ICustomizationButtonWrapperProps {
    Children: React.ReactNode;
}
function CustomizationButtonWrapper (props: ICustomizationButtonWrapperProps) {
    return 
}
