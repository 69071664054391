import { Card, CardContent, CardActions, Button, CardHeader, createTheme, ThemeProvider, IconButton, CircularProgress } from '@mui/material';
import styles from './style.module.css';
import { renderAddRemoveIcon, getIconFromProps, AddRemoveButtonDisplay, SideIcon, IInternalText } from './GoannaCardHelper';
import { InnerText } from './InnerText';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';
import { useAppSelector } from '../../stores/hooks';

const theme = createTheme({
    components: {
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    font: '600 18px/27px Poppins'
                }
            }
        },

    }
});

export interface IGoannaCardProps {
    // required
    title: String;
    subtitle: String;
    addRemoveClickCallback: (iconDisplay: AddRemoveButtonDisplay) => void;

    // optional
    firstText?: IInternalText;
    secondText?: IInternalText;
    showTopBackground?: boolean;
    topBackgroundColor?: string;
    showAddRemoveButton: AddRemoveButtonDisplay;
    showFooter?: boolean;
    icon: SideIcon | Number;
    footerText?: String;
    onClickCallback: Function;
    className?: string;
    editMode?: boolean;
    emptyText?: JSX.Element;
    loading?: boolean;
}


function GoannaCard(props: IGoannaCardProps) {

    const { t } = useTranslation('GoannaCard');

    return (
        <div className={props.className}>
            <ThemeProvider theme={theme}>
                <Card id={styles.GoannaCard} onClick={() => {
                    props.onClickCallback()
                }}>
                    <CardHeader
                        sx={{
                            font: 'bold 14px/20px Poppins',
                            transition: 'all .3s',
                            backgroundColor: props.showTopBackground ? props.topBackgroundColor : '#FFFFFF'
                        }}
                        title={
                            <div style={{
                                color: '#131523',
                                paddingTop: props.showTopBackground ? '14px' : '0px',
                                transition: 'all .3s',
                            }}>
                                {props.title}
                            </div>
                        }
                        subheader={<div style={{ font: 'bold 14px/20px Poppins', color: '#131523' }}>{props.subtitle}</div>}
                        action={<IconButton onClick={() => props.addRemoveClickCallback(props.showAddRemoveButton)}>{renderAddRemoveIcon(props.showAddRemoveButton)}</IconButton>}
                    />
                    <CardContent sx={{ padding: '0px' }}>
                        {
                            props.loading
                                ? <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "257px"
                                }}><CircularProgress sx={{ color: "#0958FF" }} /></div>
                                : <div className={styles.Content}>
                                    <div className={styles.ContentTextContainer}>
                                        {props.firstText === undefined ? <></> : <InnerText data={props.firstText} numberColor='#1085FD' />}
                                        {props.secondText === undefined ? <></> : <InnerText data={props.secondText} numberColor='#2D9C41' />}
                                        {props.emptyText}
                                    </div>
                                    <button className={styles.SideIconButton}                                    >
                                        {getIconFromProps(props.icon)}
                                    </button>
                                </div>
                        }
                    </CardContent>
                    <CardActions className={styles.Footer} style={props.showFooter ? {} : { display: 'none' }}>
                        {/* <Button size="small" variant='outlined'>{t('GoannaCard-updated')}</Button> */}
                        <div>{props.footerText}</div>
                    </CardActions>
                </Card>
            </ThemeProvider>
        </div>
        // <>
        //     <h2>Home</h2>
        //     <button onClick={onLogout}>Logout</button>
        // </>
    );
}

GoannaCard.defaultProps = {
    showAddRemoveButton: AddRemoveButtonDisplay.None,
    topBackgroundColor: '#AAC8FB',
    icon: SideIcon.WaterIcon,
    showFooter: false,
}

export default GoannaCard;
