import { Card, CardContent, CircularProgress, Typography } from "@mui/material";
import { useContext } from "react";
import FieldDetailBody from "../FieldDetailPaper/FieldDetailBody";
import FieldDetailHeader from "../FieldDetailPaper/FieldDetailHeader";
import FieldDetailPaper from "../FieldDetailPaper/FieldDetailPaper";
import GoannaButton from "../GoannaButton/GoannaButton";

import styles from './style.module.css';

export type GraphPageDataCardType = {
  title: JSX.Element | string;
  items: GraphPageDataCardItemType[];
  showSaveButton?: boolean;
  onSubmit?: () => void;
  loading?: boolean;
}

export type GraphPageDataCardItemType = {
  title: string;
  action?: JSX.Element | string;
}

// doing this to stay with the design of other components, not really needed just use the type if you want
interface IGraphPageDataCard extends GraphPageDataCardType {
  id: string;
}

/**
 * `GraphPageDataCard` is a React component that displays a card with a title,
 * a list of items, and an optional save button. It is used to display various
 * types of data in a structured format.
 *
 * Props:
 * - `title` (string): The title of the card, displayed at the top of the card.
 * - `items` (GraphPageDataCardItemType[]): An array of `GraphPageDataCardItemType`
 *   objects, which represents the list of items to be displayed within the card.
 * - `showSaveButton` (boolean, optional): Whether to show the save button or not.
 *   If set to `true`, a save button will be displayed in the header section of the card.
 *   Default value is `false`.
 * - `onSubmit` (() => void, optional): A callback function that will be called when
 *   the save button is clicked. This prop is only required if `showSaveButton` is set to `true`.
 * - `loading` (boolean, optional): Whether the component should display a loading spinner.
 *   If set to `true`, a circular progress indicator will be displayed instead of the items.
 *   Default value is `false`.
 *
 * Example usage:
 * ```jsx
 * <GraphPageDataCard
 *   title="Card Title"
 *   items={[
 *     { title: "Item 1", action: <button>Click me</button> },
 *     { title: "Item 2", action: "Text only" },
 *     { title: "Item 3", action: <h1>Header</h1> },
 *   ]}
 *   showSaveButton={true}
 *   onSubmit={() => console.log("Save button clicked")}
 *   loading={false}
 * />
 * ```
 */
function GraphPageDataCard(props: IGraphPageDataCard) {

  return (
    <FieldDetailPaper width='100%'
      id={props.id}
    >
      <FieldDetailHeader backgroundColor='#D9D7D780'>
        <div className={styles.headder}>
          <div>{props.title}</div>
          {props.showSaveButton ? <GoannaButton title="Save" onClick={props.onSubmit} width="100px" height="42px" style={{ marginRight: "30px" }} /> : undefined}
        </div>
      </FieldDetailHeader>
      <FieldDetailBody style={{
        marginLeft: '0px',
        marginRight: '0px',
        paddingLeft: "28px",
        paddingRight: "8px",
        paddingTop: '10px',
        paddingBottom: '10px',
      }}
        loading={props.loading ?? false}>
        <div className={styles.CardItems}>
          {
            props.items.map((item, index) =>
              <div key={item.title + index}>
                <Typography fontSize="0.9rem" fontWeight="700">{item.title}</Typography>
                <div className={styles.ItemAction}>{item.action ?? "-"}</div>
              </div>
            )
          }
        </div>
      </FieldDetailBody>
    </FieldDetailPaper>
  );
}

export default GraphPageDataCard;