import * as React from 'react';

import styles from './style.module.css';

export interface ITileProps {
    color: string
}

export default function Tile (props: ITileProps) {
  return (
    <div className={styles.tile} style={{
        backgroundColor: props.color
    }}>
    </div>
  );
}
