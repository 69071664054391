import { Typography } from '@mui/material';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import { DateTime } from 'luxon';
import { useAppSelector } from '../../../../../stores/hooks';

export default function DateRangeSection() {
    const { dateRange } = useFieldDetailContext();

    const numericSystem = useAppSelector(state => state.user.numericSystem);

    const dateFormat = numericSystem === "M" ? 'dd/MM/yyyy' : 'MM/dd/yyyy';

    const from = (dateRange[0] as DateTime)?.toFormat(dateFormat);
    const to = (dateRange[1] as DateTime)?.toFormat(dateFormat);

    return (
            <>{`Date Range: ${from} - ${to}`}</>
    );
}