import * as React from 'react';
import useFieldDetailContext from '../../hooks/ContextHooks/useFieldDetailContext';
import { valueRounder } from '../../utils/numericConversions';
import { aestToLocationLocalTimeConverter } from '../../utils/DateConvertor';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/store';

export interface SoilTotalContextDataType {
  loading: boolean,
  legend: string[],
  soilTotalGraphSeries: SoilTotalGraphSeriesType,
};

const SoilTotalContext = React.createContext({} as SoilTotalContextDataType);

export interface EChartsSeriesType {
  type: 'line' | 'bar',
  data: any[]
};

export interface ISoilTotalProviderProps {
  children: React.ReactNode,
}

export interface SoilTotalGraphSeriesType {
  SoilToal: EChartsSeriesType,
  RainEvent: EChartsSeriesType,
  RainEventForecast: EChartsSeriesType,
  DefaultUsage: any,
  MaxRain: number,
  RefillPoint: number,
  FullPoint: number,
  IrrigationForecastDefault: EChartsSeriesType,
  IrrigationForecastActial: EChartsSeriesType,
  IrrigationForecastEtc: EChartsSeriesType,
  CurrentUseIrrigate: EChartsSeriesType,
  dailyUsageMap: Map<any, any>,
};

function SoilTotalProvider(props: ISoilTotalProviderProps) {

  const { soilTotalDatawithTemp, rainEventDataSummaryData, soilProbesData, BOMForecastData } = useFieldDetailContext();
  const [soilTotalGraphSeries, setSoilTotalGraphSeries] = React.useState<SoilTotalGraphSeriesType>({} as SoilTotalGraphSeriesType);
  const { numericSystem }: { numericSystem: string } = useSelector((state: RootState) => state.user);
  // const [legend, setLegend] = React.useState<Set<string>>(new Set());

  const legend = new Set(['Soil Total', 'Rainfall', '10 days rain forecast', numericSystem === 'M' ? 'Daily Usage' : 'Daily Usage (inch/10)', 'Refill Point', 'Full Point', 'Default Usage', 'Default Use Irrigate', 'Current Usage Irrigate', 'Irrigation Forecast', 'Irrigation Forecast (Default)']);

  //update soil total serie
  React.useEffect(() => {
    if (soilTotalDatawithTemp.data) {
      const { serie, dailyUsageMap } = convertsoilTotalDataSerie();
      setSoilTotalGraphSeries((prevState) => {
        return {
          ...prevState,
          SoilToal: serie,
          dailyUsageMap: dailyUsageMap
        }
      })
    }
  }, [soilTotalDatawithTemp.data]);

  //update Rain data serie
  React.useEffect(() => {
    if (rainEventDataSummaryData.data) {
      const { serie, currentMaxRain } = convertRainEventSerie();
      const { serie: serie2, currentMaxRainForecast } = convertRainEventRainForecastDataSerie();
      setSoilTotalGraphSeries((prevState) => {
        return {
          ...prevState,
          RainEvent: serie,
          RainEventForecast: serie2,
          MaxRain: Math.max(currentMaxRain, currentMaxRainForecast)
        }
      })
    }
  }, [rainEventDataSummaryData.data]);

  //update location device data
  React.useEffect(() => {
    if (soilProbesData.data && soilProbesData.data.data.value.length > 0) {
      const refillPoint = convertRefillPoint();
      const fullPoint = convertFullPoint();
      const currentUseDefault = convertCurrentUseDefault()
      setSoilTotalGraphSeries((prevState) => {
        return {
          ...prevState,
          RefillPoint: refillPoint.data,
          FullPoint: fullPoint.data,
          CurrentUseIrrigate: currentUseDefault
        }
      })
    }
  }, [soilProbesData.data]);

  //update irrigation forecasts
  React.useEffect(() => {
    if (soilTotalGraphSeries.SoilToal && soilTotalGraphSeries.SoilToal.data.length > 0) {
        const ETCIrrigation = convertIrrigationForecastETC(soilTotalGraphSeries.SoilToal.data[soilTotalGraphSeries.SoilToal.data.length - 1]);
        setSoilTotalGraphSeries((prevState) => {
          return {
            ...prevState,
            IrrigationForecastEtc: ETCIrrigation
          }
        })

      if(soilTotalGraphSeries.RefillPoint) {
        const DefaultUsage = convertDefaultUsage();
        const defaultIrrigation = convertIrrigationForecastDefault(soilTotalGraphSeries.SoilToal.data[soilTotalGraphSeries.SoilToal.data.length - 1]);
        const actualIrrigation = convertIrrigationForecastActual(soilTotalGraphSeries.SoilToal.data[soilTotalGraphSeries.SoilToal.data.length - 1]);
        setSoilTotalGraphSeries((prevState) => {
          return {
            ...prevState,
            DefaultUsage: DefaultUsage,
            IrrigationForecastActial: actualIrrigation,
            IrrigationForecastDefault: defaultIrrigation
          }
        })
      }
    }
  }, [soilTotalGraphSeries.SoilToal, soilTotalGraphSeries.RefillPoint]);

  //process data to SoilToal serie
  const convertRainEventRainForecastDataSerie = () => {
    const serie: EChartsSeriesType = {
      type: 'bar',
      data: [],
    }

    const uniquefiedData = uniquefy(rainEventDataSummaryData.data ?? []);
    let currentMaxRainForecast = 0;
    uniquefiedData.forEach(element => {
      if (element.DateTime && element.RainmmForecast) {
        if (element.RainmmForecast > currentMaxRainForecast) {
          currentMaxRainForecast = valueRounder(element.RainmmForecast);
        }
        const tmp = [element.DateTime, valueRounder(element.RainmmForecast)];
        serie.data.push(tmp);
      }
    });


    return { serie, currentMaxRainForecast };
  };

  //process data to SoilToal serie
  const convertsoilTotalDataSerie = () => {
    const serie: EChartsSeriesType = {
      type: 'line',
      data: []
    }
    const dailyUsageMap = new Map();
    const uniquefiedData = uniquefy(soilTotalDatawithTemp.data?.data ?? []);
    uniquefiedData.forEach((element, index) => {
      if (element.DateTime && element.soiltotal) {
        const tmp = [element.DateTime, element.soiltotal];
        serie.data.push(tmp);
        //remove the if condition to include every daily usage including the current usage
        // if (element.notes && uniquefiedData.length - 1 !== index)
        const dailyUsage = numericSystem === 'M' ? element.notes : element.notes ? valueRounder(element.notes * 10, 2) : element.notes;
        dailyUsageMap.set(element.DateTime, dailyUsage);
      }
    });
    console.log(dailyUsageMap);
    
    return { serie, dailyUsageMap };
  };

  //process data to RainEvent serie
  const convertRainEventSerie = () => {
    const serie: EChartsSeriesType = {
      type: 'line',
      data: []
    }
    let currentMaxRain = 0
    const uniquefiedData = uniquefy(rainEventDataSummaryData.data ?? []);
    uniquefiedData.forEach(element => {
      if (element.DateTime && element.Rainmm) {
        if (element.Rainmm > currentMaxRain) currentMaxRain = valueRounder(element.Rainmm);
        const tmp = [element.DateTime.replace('00:00:00', "23:59:59"), valueRounder(element.Rainmm)];
        //moving the rain value to the end of the day on the graph
        serie.data.push(tmp);
      }
    });
    return { serie, currentMaxRain };
  };

  //process data to refill point
  const convertRefillPoint = () => {
    const markLine = {
      name: 'Refill Point',
      data: soilProbesData.data?.data.value[0].Refillpoint ?? null
    }
    return markLine;
  };
  //process data to fullpoint
  const convertFullPoint = () => {

    const markLine = {
      name: 'Full Point',
      data: soilProbesData.data?.data.value[0].Fullpoint ?? null
    }
    return markLine;
  };

  const convertDefaultUsage = () => {

    const dailyUse = soilProbesData.data?.data.value[0].DailyUse ? numericSystem === 'M' ?  soilProbesData.data?.data.value[0].DailyUse : soilProbesData.data?.data.value[0].DailyUse * 10 : null;
    const markPoint = {
      name: 'Default Usage',
      data: dailyUse,
      xAxis: undefined,
      yAxis: undefined,
    }
    if (soilTotalGraphSeries.SoilToal && soilTotalGraphSeries.SoilToal && soilTotalGraphSeries.SoilToal.data.length > 0) {
      markPoint.xAxis = soilTotalGraphSeries.SoilToal.data[soilTotalGraphSeries.SoilToal.data.length - 1][0] ?? null;
      markPoint.yAxis = soilTotalGraphSeries.SoilToal.data[soilTotalGraphSeries.SoilToal.data.length - 1][1] ?? null;
    }
    return markPoint;
  };

  const convertIrrigationForecastDefault = (lastPoint: [any, any]) => {
    const serie: EChartsSeriesType = {
      type: 'line',
      data: [
        lastPoint, [
          aestToLocationLocalTimeConverter(
            soilProbesData.data?.data.value[0].IrrigationDueDefault ?? null,
            soilProbesData.data?.data.value[0].GMTDifference
          ),
           soilTotalGraphSeries.RefillPoint]
    ]
    }
    return serie;
  };

  const convertIrrigationForecastActual = (lastPoint: [any, any]) => {
    const serie: EChartsSeriesType = {
      type: 'line',
      data: [lastPoint, [soilProbesData.data?.data.value[0].IrrigationDueActual ?? null, soilTotalGraphSeries.RefillPoint]]
    }
    return serie;
  };

  const convertIrrigationForecastETC = (lastPoint: [any, any]) => {
    const serie: EChartsSeriesType = {
      type: 'line',
      data: [
        lastPoint, [
          aestToLocationLocalTimeConverter(
            soilProbesData.data?.data.value[0].IrrigationDueForecast ?? null,
            soilProbesData.data?.data.value[0].GMTDifference
          ),
          soilTotalGraphSeries.RefillPoint
        ]
      ]
    }

    return serie;
  };
  const convertCurrentUseDefault = () => {
    const serie: EChartsSeriesType = {
      type: 'line',
      data: [soilProbesData.data?.data.value[0].Fullpoint ?? null]
    }
    return serie;
  };

  const uniquefy = (array: any[]) => {
    var seen: any = {};
    return array.filter((item) => {
      return seen.hasOwnProperty(item.DateTime) ? false : (seen[item.DateTime] = true);
    })
  }

  return (
    <SoilTotalContext.Provider value={{ legend: Array.from(legend), soilTotalGraphSeries: soilTotalGraphSeries, loading: soilTotalDatawithTemp.isLoading || rainEventDataSummaryData.isLoading || soilProbesData.isLoading }}>
      {props.children}
    </SoilTotalContext.Provider>
  );
}

export { SoilTotalProvider, SoilTotalContext };