import { useState } from "react";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { http } from '../../api/http';

export interface soilTotalDatawithTemp {
    data: any[];
};

const SOILTOTAL_PATH: string = 'Reportdata/SoilProbeData/GetSoilTotalDatawithTempForDateRange';

const useSoilTotalDatawithTemp = (locationID: string, queryID: string, onSuccess?: (data: soilTotalDatawithTemp) => void, onError?: (err: any) => void) => {
    const [dates, setDates] = useState<{ startDate: string, endDate: string }>();

    const fetchSoilTotalDatawithTemp = async () => {
        if (dates?.startDate === undefined || dates?.endDate === undefined) {
            throw Error("No Start Date or End Date Specified")
        }

        const query = `${SOILTOTAL_PATH}/${locationID}/${dates?.startDate}/${dates?.endDate}`;

        try {
            const response: soilTotalDatawithTemp = await http.get(query);
            if (response === undefined) return {} as soilTotalDatawithTemp;
            return response;
        }
        catch (e: any) {
            throw new Error(e.message)
        }
    };
    const soilTotalDatawithTempQuery = useQuery<soilTotalDatawithTemp>(
        ['soilTotalDatawithTemp' + queryID, dates],
        fetchSoilTotalDatawithTemp,
        {
            refetchOnMount: false,
            enabled: dates !== undefined && dates.startDate !== undefined && dates.endDate !== undefined,
            refetchOnWindowFocus: false,
            retry: false,
            onSuccess,
            onError
        }
    );

    // if you change the data in state and query key uses it then it refetches the data.
    const refetchDataWithDateRange = async (newStartDate: string, newEndDate: string) => {
        setDates({
            startDate: newStartDate,
            endDate: newEndDate
        });
    };

    return [soilTotalDatawithTempQuery, refetchDataWithDateRange] as const;
}

export default useSoilTotalDatawithTemp;
