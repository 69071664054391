import { Box, Divider, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ReactComponent as GTIcon } from '../../assets/images/TextActionList/greater_than.svg'
import { useAppSelector } from "../../stores/hooks";
import styles from "./style.module.css"
import { UpdateTabTitle } from "../../utils/updateTabTitle";

interface ITextIconListProps {
  items: {
    id?: string;
    name: string | JSX.Element;
    action: JSX.Element;
    navigateTo?: string;
    showSideArrow?: boolean,
    onClickCallback?: () => void;
    hoverOverEffect?: boolean;
    customHeight?: string;
  }[]
}

function TextActionList({ items }: ITextIconListProps) {
  const navigate = useNavigate();

  const handleItemClick = (navigateTo?: string, onClickCallback?: () => void) => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    else if (onClickCallback) {
      onClickCallback();
    }
  }

  return (
    <Paper className={styles.textActionList}>
      {items.map((item, index) => {

        // default value is false
        const _showSideArrow = item.showSideArrow == undefined ? false : item.showSideArrow;

        const showCursor = !((item.navigateTo == undefined) && (item.onClickCallback == undefined));

        const height = item.customHeight ? item.customHeight : items.length < 2 ? "78px" : "100px";

        const itemStylesWithoutHover = {
          height: height,
          cursor: showCursor ? "pointer" : "auto",
        };

        const itemStylesWithHover  = {
          height: height,
          cursor: showCursor ? "pointer" : "auto",
          transition: 'background-color 0.3s', // Smooth transition on hover
          '&:hover': {
            backgroundColor: '#e0e0e0', // Change background color on hover
          },
        }

        return (
          <>
            <Box
              className={styles.innerBox}
              sx={item.hoverOverEffect ? itemStylesWithHover : itemStylesWithoutHover}
              id={item.id && item.id}
              onClick={() => { handleItemClick(item.navigateTo, item.onClickCallback) }}
            >
              {
                typeof item.name === 'string' ?
                  <Typography>
                    <strong>{item.name}</strong>
                  </Typography> :
                  item.name
              }
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "#131523" }}>
                {item.action}
                {_showSideArrow ? <GTIcon style={{ marginLeft: "10px" }} /> : undefined}
              </Box>
            </Box>
            {index === items.length - 1 ? undefined : <Divider sx={{ marginRight: "8px" }} />}
          </>
        );
      })}
    </Paper>
  );
}

export type { ITextIconListProps as itemsType };

export default TextActionList;
