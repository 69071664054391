import { IconButton, ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import { useEffect, useState } from 'react';
import { shortCutPagesValueType } from '../../stores/Slices/userSlice';
import useShortcutPages from '../../hooks/ApiHooks/useShortcutPages';

interface IShortcutAddButtonProps extends shortCutPagesValueType {
  isMenuItem?: boolean,
  onClick?: Function,
}

const TOOLTIP_TIMEOUT_MS = 1000;  // 1s

const getLocation = () => {
  const path = window.location.toString();
  return path.substring(path.indexOf("/pages"));
};

function ShortcutAddButton({ name, isMenuItem, onClick }: IShortcutAddButtonProps) {

  const [
    saveShortCutPages,
    { data, loading, error },
    shortCutPages
  ] = useShortcutPages();

  const location = getLocation();

  const isSelected = shortCutPages.findIndex((item: shortCutPagesValueType) => item.path == location) != -1;

  const [open, setOpen] = useState(false);

  const handleToolTipOpen = () => {
    setOpen(true);
    setTimeout(() => setOpen(false), TOOLTIP_TIMEOUT_MS)
  }

  const [tooltipTitle, setTooltipTitle] = useState("Add to Shortcuts");

  useEffect(() => {
    setTooltipTitle(!isSelected ? "Removed from Shortcuts" : "Added to Shortcuts");
  }, [isSelected]);

  /**
   * Toggles the given item in the user's list of shortcut pages. If the item is already in the list,
   * it is removed. If it is not in the list, it is added to the beginning of the list.
   * 
   * @param item The name of item to toggle in the list of shortcut pages.
   */
  const ToggleToShortcuts = (item: shortCutPagesValueType): void => {
    // Make a copy of the existing shortcut pages
    let shortCutPagesClone: shortCutPagesValueType[] = JSON.parse(JSON.stringify(shortCutPages.slice()));

    // Get the current URL path
    const path: string = window.location.toString();

    // Create a new shortcut object with the item name and current URL path
    const newShortcut: shortCutPagesValueType = {
      name: item.name,
      path: path.substring(path.indexOf("/pages")),
    }

    // Check if the new shortcut is already in the list
    const foundIndex = shortCutPagesClone.findIndex((_item) => _item.name == newShortcut.name)

    // If the new shortcut is not in the list, add it to the beginning
    if (foundIndex == -1) {
      shortCutPagesClone = [newShortcut, ...shortCutPages];
    }
    // If the new shortcut is already in the list, remove it
    else {
      shortCutPagesClone.splice(foundIndex, 1);
    }

    // Save the updated shortcut pages to the database
    saveShortCutPages(shortCutPagesClone);
  }

  return (
    <>
      {
        !isMenuItem
          ?
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={tooltipTitle}
          >
            <IconButton
              aria-label="favourite"
              color="primary"
              onClick={() => {
                handleToolTipOpen();
                if (name) ToggleToShortcuts({ name })
              }}
              disabled={(name === 'undefined') && (shortCutPages !== undefined)}
            >
              {
                isSelected
                  ? <StarIcon />
                  : <StarOutlineIcon />
              }
            </IconButton>
          </Tooltip>
          :
          <MenuItem
            onClick={() => {
              handleToolTipOpen();
              if (name) ToggleToShortcuts({ name });
              onClick && onClick()
            }}
            disabled={(name === 'undefined') && (shortCutPages !== undefined)}
          >
            <ListItemIcon color='primary' >
              {
                isSelected
                  ? <StarIcon color='primary' />
                  : <StarOutlineIcon />
              }
            </ListItemIcon>
            <ListItemText>Add to shortcuts</ListItemText>
          </MenuItem>
      }
    </>
  );
}

export default ShortcutAddButton;