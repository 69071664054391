import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type DimensionsType = {
    height: number;
    width: number;
};

type TopBarState = {
    title: string,
    isMobile: boolean,
    dimensions: DimensionsType
}

const initialState: TopBarState = {
    title: '',
    isMobile: Math.min(window.innerWidth, window.innerHeight) <= 500,
    dimensions: {
        height: window.innerHeight,
        width: window.innerWidth
    },
}

export const topbarSlice = createSlice({
    name: 'topbar',
    initialState,
    reducers: {
        setTitle: (state: TopBarState, action) => {
            state.title = action.payload
        },
        setDimensions: (state: TopBarState, action: PayloadAction<DimensionsType>) => {
            state.dimensions = {
                width: action.payload.width,
                height: action.payload.height,
            }

            // Width here is min length of width and height
            state.isMobile = Math.min(action.payload.width, action.payload.height) <= 500;
        }
    }
});

export const { setTitle, setDimensions } = topbarSlice.actions;

export default topbarSlice.reducer;