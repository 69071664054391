import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import HelpImage from "../../assets/images/HelpImage.png"
import { useAppSelector } from "../../stores/hooks";
import { Route, Routes } from "react-router-dom";
import TextActionList from "../../components/TextListIcon/TextActionList";

const ContactUs: FunctionComponent = () => {
    const { t } = useTranslation("Help")

    const { isMobile, dimensions } = useAppSelector(state => state.topbar);

    return (
        <Box sx={{
            display: "flex",
            justifyContent: dimensions.width < 500 ? "flex-start" : "center",
            alignItems: "center",
            height: isMobile ? "calc(100vh - 100px);" : "100vh",
            flexDirection: dimensions.width < 500 ? "column" : "row",
            gap: isMobile ? "10px" : "156px",
            margin: isMobile ? "20px" : 0,
        }}>
            <Box display="flex" justifyContent={"center"}>
                <div style={{
                    backgroundImage: `url(${HelpImage})`,
                    backgroundSize: "contain",
                    backgroundBlendMode: "multiply",
                    backgroundColor: "#F4F7FC",
                    height: dimensions.width < 500 ? "70vw" : (dimensions.height < 500 ? "80vh" : "500px"),
                    width: dimensions.width < 500 ? "70vw" : (dimensions.height < 500 ? "80vh" : "500px")
                }} />
            </Box>
            <Box display="flex" flexDirection="column" width={dimensions.width < 500 ? "100%" : "500px"}>
                <Typography
                    align={isMobile ? "center" : "left"}
                    variant="h4"
                    variantMapping={{ "h4": "h1" }}
                    fontSize="36px"
                    fontFamily="Montserrat"
                    fontWeight="bold"
                    color="#FA6980"
                    marginBottom="18px"
                    sx={{ textDecoration: "underline", textDecorationColor: "#0F68FD", textUnderlineOffset: "10px" }}
                >
                    {t("Help-Title")}
                </Typography>
                <Typography fontWeight="bold">
                    {t("Help-Content1")}
                </Typography>
                <ul style={{ marginLeft: "-20px" }}>
                    <li>
                        <Typography fontWeight="bold">Email: <Link href="mailto:help@goannaag.com.au">help@goannaag.com.au</Link></Typography>
                    </li>
                    <li>
                        <Typography fontWeight="bold">Phone: <Link href="tel:0749713790">07 4671 3790</Link></Typography>
                    </li>
                </ul>
                <Typography fontWeight="bold">
                    {t("Help-Content2")}
                </Typography>
            </Box>
        </Box>
    );
}

export default ContactUs;