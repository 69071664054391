import { Alert, Button, Card, CardContent, CardHeader, CircularProgress, Link, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from '../styles.module.css';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { resetPasswordFormSchema as validationSchema } from '../../../utils/validations';
import { http } from '../../../api/http';
import { useState } from 'react';

export interface IAppProps {
  className: string
  showLogInUI: () => void
  loading: boolean
}

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  components: {
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontFamily: 'Poppins'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          background: 'linear-gradient(to right, #75B9F0, #1085FD)'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          marginRight: "7px",
          color: '#333',
        },
      },
    },
  }
});

function ResetPasswordCard(props: IAppProps) {
  const formik = useFormik({
    initialValues: { username: "" },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      setStatus({msg: "", statusCode: 0})

      let response = await http.get(`/api/passwordReset?username=${values.username}`, {
        validateStatus: null // stops interceptor to handle error
      });

      setStatus({msg: response.status === 200 ? "Please check your inbox" : response.data, statusCode: response.status})
      setLoading(false);
      setIsSubmitButtonDisabled(response.status === 200);
    }
  });

  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState({ msg : "", statusCode: 0 });
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  // const errorTypes = {
  //   '401': 'Username Or Password Is Invalid',
  //   '403': 'Error: Access forbidden',
  //   '425': 'Error: Too many Requests',
  //   '500': 'Error: Internal ServerError'
  // };

  const { t } = useTranslation("Login");

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ borderRadius: '10px', boxShadow: 0.5 }} className={props.className} variant='outlined'>
        <CardHeader className={styles.cardHeader} sx={{ paddingBottom: 0 }} title={t("Login-reset")} />
        <form onSubmit={formik.handleSubmit}>
          <CardContent className={styles.cardContent} sx={{ marginBottom: 0 }}>
            <div className={styles.loginTextField}>
              <TextField
                fullWidth
                id="username"
                name="username"
                label="Username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
              />
            </div>
            {
              status.statusCode > 0 ?
              <div className={styles.loginTextField}>
                  <ThemeProvider theme={theme}>
                    <Alert style={{ paddingLeft: "11.34px" }} severity={ status.statusCode > 200 ? 'error' : 'success'}>
                      { status.msg }
                    </Alert>
                  </ThemeProvider>
              </div>
              : <></>
            }
            <div className={styles.loginTextField}>
              <Link href='#' onClick={() => props.showLogInUI()} style={{textDecoration: "none"}}>Log In</Link>
            </div>
            <div className={styles.loginTextField}>
              <Button
                color="primary" variant="contained"
                fullWidth type="submit"
                disableElevation sx={{ "&:hover": { boxShadow: 3 } }}
                size='large' disabled={loading || isSubmitButtonDisabled}
              >
                {
                  loading
                    ? <CircularProgress size={25} sx={{ color: 'white' }} />
                    : <span>{t("Login-reset")}</span>
                }
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    </ThemeProvider>
  );
}

export default ResetPasswordCard;

