import styles from './style.module.css';
import { IInternalText } from './GoannaCardHelper';

interface IInnerText {
  data: IInternalText
  numberColor?: string
}

export function InnerText({ data, numberColor }: IInnerText) {
  return (
    <div className={styles.ContentText}>
      <span className={styles.ContentTextNumber} style={{ color: numberColor }}>
        {data.TextNumber ? data.TextNumber.toString() : '0'}
      </span>
      <div className={styles.ContentTextAndDate}>
        <div className={styles.ContentTextData}>
          {data.TextData}
        </div>
        <div className={styles.ContentTextDate}>
          {data.dateString}
        </div>
      </div>
    </div>
  )
}

export default InnerText;