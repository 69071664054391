import * as React from 'react';
import { CanopyStressContext } from '../../context/FieldDetailsContext/CanopyStressContext';

const useCanopyStressContext = () => {
    const context = React.useContext(CanopyStressContext);

    if (context === undefined) {
        throw new Error("useCanopyStressContext was used outside of its Provider");
      }
    return context;
}

export default useCanopyStressContext;