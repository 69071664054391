import * as React from 'react';

import Sun from '../../../../../assets/icons/SunIcon.svg';
import Rain from '../../../../../assets/icons/RainIcon.svg';

import styles from './style.module.css';
import { valueFormatter, valueRounder } from '../../../../../utils/numericConversions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export interface IScheduleCalendarTopTileProps {
  day: string,
  date: number,
  color: string,
  rain: number,
  temperature: number
}

export default function ScheduleCalendarTopTile(props: IScheduleCalendarTopTileProps) {

  const { t } = useTranslation("scheduleCalendar");


  const { numericSystem }: { numericSystem: string } = useSelector((state: any) => state.user);

  const rainDisplay = (rain: number) => {
    switch (rain) {
      case undefined:
      case -999:
        return <div className={`${styles.naIcon}`}>{t("scheduleCalendar-na")}</div>;
      case 0:
        return <img className={`${styles.sunIcon}`} src={Sun} />;
      default:
        return <div className={`${styles.rainIcon}`}><span><img src={Rain}></img></span><span className={styles.rainText}> {" " + valueFormatter(numericSystem, rain, "mm")}</span></div>;
    }
  };
  const temperatureDisplay = (temperature: number) => {
    switch (temperature) {
      case undefined:
      case -999:
        return <div>{t("scheduleCalendar-na")}</div>
      default:
        return <div>{valueFormatter(numericSystem, temperature, "°C")}</div>
    }
  };

  return (
    <div className={styles.topTile}
      style={{
        backgroundColor: props.color
      }}>
      <div className={`${styles.topTileItem} ${styles.days}`}>{props.day}</div>
      <div className={`${styles.date} ${styles.topTileItem}`}>{props.date}</div>
      <div className={`${styles.topTileItem}`}>{
        rainDisplay(valueRounder(props.rain))
      }</div>
      <div className={`${styles.topTileItem} ${styles.temperature}`}>
        {temperatureDisplay(props.temperature)}
      </div>
    </div>
  );
}
