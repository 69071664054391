import { useQuery } from '@tanstack/react-query';
import { http } from '../../api/http';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

type EditableFields = {
  CropID: string | null,
  LocationCropID: number | string;
  LocationID: number | string;
  PlantDate?: string | null;
  EndDate?: string | null;
  SalePrice?: number | string | null;
  Yield?: number | string | null;
}

export type LocationCropsByLocationIDDataType = {
  CostPrice: any,
  CropID: any,
  EndDate?: any,
  LocationCropID: any,
  LocationID: any,
  LocationIDRain: any,
  LocationIDSolar: any,
  LocationIDTemp: any,
  PlantDate?: any,
  SalePrice: any,
  StartDate: any,
  VarietyID: any,
  WeatherStationID: any,
  Yield: any
}

export interface LocationCropsByLocationIDAPIReturnType {
  data: {
    value: LocationCropsByLocationIDDataType[];
  }
};

const LOCATION_CROPS_BY_LOCATION_ID_PATH: string = 'odata/LocationCropsByLocationID';

const useLocationCropsByLocationID = (locationID: string) => {

  const query = LOCATION_CROPS_BY_LOCATION_ID_PATH + `(${locationID})`;
  const fetchLocationCropsByLocationIDData = async () => {
    const response: LocationCropsByLocationIDAPIReturnType = await http.get(query);
    return response;
  };

  const LocationCropsByLocationID: any = useQuery({ queryKey: ['LocationCropsByLocationID'], queryFn: fetchLocationCropsByLocationIDData, enabled: false });

  return LocationCropsByLocationID;
}

export default useLocationCropsByLocationID;

export const usePutLocationCropsByLocationIDData = (): [typeof putLocationCropsByLocationIDData, { loading: boolean; data?: AxiosResponse<any, any> | undefined; error?: any; }] => {

  const mutation = useMutation(
    (requestBody: any) => {
      return http.put(
        `${LOCATION_CROPS_BY_LOCATION_ID_PATH}(${requestBody.LocationCropID})`,
        JSON.stringify(requestBody),
        {
          headers: {
            "content-type": "application/json;IEEE754Compatible=true"
          }
        }
      );
    },
  );

  const putLocationCropsByLocationIDData = async (
    locationCropID: number,
    locationID: number | string,
    changedData: EditableFields,
    originalData: LocationCropsByLocationIDDataType,
    onSuccessCallback?: (response: AxiosResponse<string, any>) => void
  ) => {
    const { PlantDate, EndDate, SalePrice, Yield, CropID } = changedData;

    // Create the `requestBody` object with default values of `undefined` using the nullish coalescing operator
    const requestBody: LocationCropsByLocationIDDataType = {
      CostPrice: originalData.CostPrice,
      CropID: CropID?.toString(),
      EndDate: EndDate,
      LocationCropID: locationCropID.toString(),
      LocationID: locationID.toString(),
      LocationIDRain: originalData.LocationIDRain,
      LocationIDSolar: originalData.LocationIDSolar,
      LocationIDTemp: originalData.LocationIDTemp,
      PlantDate: PlantDate,
      SalePrice: SalePrice?.toString() ?? originalData.SalePrice,
      StartDate: originalData.StartDate,
      VarietyID: originalData.VarietyID,
      WeatherStationID: originalData.WeatherStationID,
      Yield: Yield?.toString() ?? originalData.Yield,
    };

    // Check if something was actually changed

    if ([PlantDate, EndDate, SalePrice, Yield].every((value) => value === undefined)) {
      // should never reach
      console.error("Nothing was changed [PlantDate, EndDate, SalePrice, Yield]: ", [PlantDate, EndDate, SalePrice, Yield])
      return;
    }

    // Call `mutation.mutate()` with `requestBody`
    mutation.mutate(requestBody, {
      onSuccess:
        (response) => {
          if (onSuccessCallback) onSuccessCallback(response);
        }
    });
  };

  return [
    putLocationCropsByLocationIDData,
    {
      loading: mutation.isLoading,
      data: mutation.data,
      error: mutation.isError ? mutation.error : false
    }
  ];
};