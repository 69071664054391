import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../stores/hooks';
import { setTitle } from '../../stores/Slices/topbarSlice';
import { UpdateTabTitle } from '../../utils/updateTabTitle';
const useTopBarTitle = () => {
    
    const dispatch = useAppDispatch();
    const title = useAppSelector((state) => state.topbar.title);
    const setTopBarTitle = (title: string) => {
        dispatch(setTitle(title));
    }

    const { pathname } = useLocation();
    const pageName = pathname.split('/').pop();
    const TITLE_MAPPING: { [key: string]: string } = {
        'dashboard': 'Dashboard',
        'fieldTable': 'Field Table',
        'fieldList': 'Field List',
        'help': 'Help',
        'settings': 'Settings'
    };

    useEffect(() => {
        if (!pathname.includes('fields')) {
            const updatedTitle =
              pageName && TITLE_MAPPING[pageName] ? TITLE_MAPPING[pageName] : '';
            setTopBarTitle(updatedTitle)
            UpdateTabTitle(updatedTitle)
          }
    }, [pathname, pageName]);

    return { title, setTopBarTitle };
};

export default useTopBarTitle;