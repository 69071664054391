import * as React from 'react';
import SpaIcon from '@mui/icons-material/Spa';

import styles from './style.module.css';

export interface ICropProps {
    crop: string,
    variety: string
}

export default function Crop(props: ICropProps) {
    return (
        <div className={styles.Crop} style={{

        }}>
            <SpaIcon sx={{
                height: '1rem',
                color: '#2D9c41'
            }} />
            <span style={{
                fontFamily: 'poppins',
                fontWeight: 200,
                fontSize: '0.8rem',
                marginRight: '0.5rem'
            }}>{props.crop}</span>
            {props.variety ? <span style={{
                fontFamily: 'poppins',
                fontWeight: 200,
                fontSize: '0.8rem',
                marginRight: '0.5rem'
            }}>{props.crop}</span> : <></>}
        </div >
    );
}
