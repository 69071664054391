import * as React from 'react';
import { useQuery, QueryObserverResult } from '@tanstack/react-query';
import { http } from '../../api/http';

export interface DevicesDataType {
    value: any[];
};

const DEVICE_PATH: string = 'odata/Devices';

const useDevicesData = () => {
    const [deviceID, setDeviceID] = React.useState<string | undefined>(undefined);

    const fetchDevicesData = async ({ queryKey }: { queryKey: [string, string | undefined] }): Promise<DevicesDataType> => {
        const [, id] = queryKey;

        if (id) {
            const query = `${DEVICE_PATH}(${id})?&%24count=true`;
            const response = await http.get<DevicesDataType>(query);
            return response.data;
        }

        return {
            value: []
        }
    };

    const locationDeviceData: QueryObserverResult<DevicesDataType, Error> = useQuery(
        ['Device', deviceID],
        fetchDevicesData,
        {
            enabled: (deviceID !== undefined),
            refetchOnWindowFocus: false,
        }
    );

    const refetchDataWithDeviceId = async (newDeviceId: string) => {
        setDeviceID(newDeviceId);
    };

    return [locationDeviceData, refetchDataWithDeviceId] as const;
}

export default useDevicesData;
