import { DateTime } from "luxon";

export const AEST_Zone = "Australia/Brisbane"

export const CURRENT_TIME = new Date();
export const CURRENT_TIME_AEST = DateTime.local({
  zone: AEST_Zone
})

/**
 * Converts a given time string to the local time in the AEST time zone.
 * @param time The time string to convert, in ISO format.
 * @param aestDifference The hour difference between the local time and AEST time.
 * @returns The local time in the AEST time zone, in ISO format.
 */
export function aestToLocationLocalTimeConverter(time: string, aestDifference: any, returnObj = false) {
  // Check if the time parameter is valid. If not, return it as is.
  if (!time) return time;

  // Check if the aestDifference parameter is undefined. If so, return it as is.
  if (aestDifference === undefined) return aestDifference;

  // Convert the given time to the local time in the AEST time zone and return it in ISO format.
  if (returnObj) {
    return DateTime.fromISO(
      time, { zone: AEST_Zone, setZone: true }
    ).plus(
      { hour: aestDifference }
    )
  }
  else {
    return DateTime.fromISO(
      time, { zone: AEST_Zone, setZone: true }
    ).plus(
      { hour: aestDifference }
    ).toISO();
  }
}

/**
 * Converts a given local time string to the AEST time zone.
 * @param time The local time string to convert, in ISO format.
 * @param aestDifference The hour difference between the local time and AEST time.
 * @returns The AEST time, in ISO format.
 */
export function locationLocalToAESTTimeConverter(time: string, aestDifference: any) {
  // Check if the time parameter is valid. If not, return it as is.
  if (!time) return time;

  // Check if the aestDifference parameter is undefined. If so, return it as is.
  if (aestDifference === undefined) return aestDifference;

  // Convert the given local time to the AEST time zone and return it in ISO format.
  return DateTime.fromISO(time, { zone: AEST_Zone })
            .minus({ hour: aestDifference }).toISO();
}

//Number 0 in the tile array means OK, 1 means irrigate, 2 means stress
export function tileArrayGenerator(
  currentDate: DateTime,
  irrigationDate: DateTime | null
) {
  const tileArray: number[] = [3, 3, 3, 3, 3, 3, 3, 3, 3, 3];
  if (!irrigationDate) {
    return tileArray;
  }
  const diffObj = irrigationDate.startOf("day").diff(currentDate.startOf("day"), ['days']).toObject();
  const diff = diffObj.days && Math.round(diffObj.days);
  if (diff !== undefined) {
    if (diff < 0) {
      return [2, 2, 2, 2, 2, 2, 2, 2, 2, 2];
    } else if (diff >= 10) {
      return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    } else {
      for (let i = 0; i <= 9; i++) {
        if (i < diff) {
          tileArray[i] = 0;
        } else if (i === diff) {
          tileArray[i] = 1;
        } else {
          tileArray[i] = 2;
        }
      }
    }
  }

  return tileArray;
}

export function dateArrayGenerator(date: Date, days: number) {
  const dateArray = [];

  for (let i = 0; i < days; i++) {
    dateArray.push(addDays(date, i));
  }

  return dateArray;
}

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function addDaysLuxon(date: DateTime, days: number) {
  // Use the plus method to add days
  const result = date.plus({ days: days });
  return result;
}

export function calcDateDifference(startDate: DateTime | null, endDate: DateTime | null) {
  if (!startDate || !endDate) {
    return null;
  }
  const diffObj = endDate.diff(startDate, ['days']).toObject();
  const diff = diffObj.days;
  return diff;
}

//generate an random date before and after the start date, the param differences is to set the days before and after the start date
export function generateRandomDate(startDate: Date, difference: number) {
  const result = new Date(startDate);
  var randomN = Math.floor(Math.random() * (difference * 2 + 1)) - difference;
  result.setDate(result.getDate() + randomN);
  return result;
}

export const getWeekday = (date: Date) => ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'N/A'][date ? date.getDay() : 7];

export const getDaysBefore = (date: DateTime, days: number) => {
  return date.minus({ days: days });
};

export const getDaysAfter = (date: DateTime | null, days: number) => {
  return date ? date.plus({ days: days }) : null;
};