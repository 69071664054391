import * as React from 'react';
import { Paper } from '@mui/material';

export interface IFieldDetailPaperProps {
  children: React.ReactNode;
  width: string;
  id: string;
}

export default function FieldDetailPaper(props: IFieldDetailPaperProps) {
  return (
    <Paper
      sx={{
        width: `${props.width}`,
        borderRadius: '15px',
        fontFamily: 'poppins',
        marginBottom: '60px',
        overflow: 'hidden'
      }}
      elevation={2}
      id={props.id}
    >
      {props.children}
    </Paper>
  );
}
