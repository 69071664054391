import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { http } from '../../api/http';


const GET_SATELLITE_FIELD_DATA_SUMMARY_FOR_DATE_RANGE_PATH: string = 'reportdata/SatelliteFieldData/GetSatelliteFieldDataSummaryForDateRange';

const useGetSatelliteFieldDataSummaryForDateRange = (satelliteID: string, startDate: string, endDate: string) => {

    const query = GET_SATELLITE_FIELD_DATA_SUMMARY_FOR_DATE_RANGE_PATH + `/${satelliteID}/${startDate}/${endDate}`;
    const fetchGetSatelliteFieldDataSummaryForDateRange = async () => {
        const response: any = await http.get(query);
        return response;
    };

    const GetSatelliteFieldDataSummaryForDateRange = useQuery({ queryKey: ['SatelliteFieldDataSummaryForDateRange'], queryFn: fetchGetSatelliteFieldDataSummaryForDateRange, enabled: false });

    return GetSatelliteFieldDataSummaryForDateRange;
}

export default useGetSatelliteFieldDataSummaryForDateRange;