import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { secondaryClient } from '../../api/apollo';

const GET_SOIL_PROBE_IRRIGATION_FORECAST = gql`
  query ($access_token: String!, $start_time: String, $end_time: String) {
    SoilProbeIrrigationForecast(
      where: {
        authToken: $access_token
        deviceCategoryId: [15, 3]
        startTime: $start_time
        endTime: $end_time
      }
    ) {
      irrigationFields
    }
  }
`;

/**
 * `useSoilProbesIrrigationForecast` is a custom React Hook that fetches soil probe irrigation forecast data
 * between the provided start and end times.
 *
 * @param {DateTime} startTime - The start time of the desired data range in Luxon DateTime format.
 * @param {DateTime} endTime - The end time of the desired data range in Luxon DateTime format.
 * @returns {{
*   fetchData: () => Promise<any>,
*   loading: boolean,
*   error?: ApolloError,
*   irrigationFields: number | null
* }} An object containing:
*   - fetchData: A function that fetches the irrigation forecast data.
*   - loading: A boolean indicating whether the query is loading.
*   - error: An ApolloError object containing error information if an error occurs, undefined otherwise.
*   - irrigationFields: The number of irrigation fields if the query was successful, null otherwise.
*
 * @example
 * // Usage inside a React component
 * const MyComponent = () => {
 *   const { 
 *     fetchData: getNineDaysData, 
 *     irrigationFields: irrigationFieldsNineDays 
 *   } = useSoilProbesIrrigationForecast(
 *     DateTime.fromISO('2023-04-04T00:00:00'), 
 *     DateTime.fromISO('2023-04-12T23:59:59')
 *   );
 *
 *   useEffect(() => {
 *     getNineDaysData()
 *       .then(data => console.log('Irrigation fields:', data))
 *       .catch(err => console.error('Error:', err));
 *   }, [getNineDaysData]);
 *
 *   return (
 *     // Your component JSX
 *   );
 * };
 */
const useSoilProbesIrrigationForecast = (
  startTime?: DateTime,
  endTime?: DateTime
): {
  fetchData: () => Promise<any>;
  loading: boolean;
  error?: ApolloError;
  irrigationFields: number | null;
} => {
  const [getSoilProbesIrrigationForecast, result] = useLazyQuery(
    GET_SOIL_PROBE_IRRIGATION_FORECAST,
    {
      client: secondaryClient,
    }
  );

  const fetchData = async (): Promise<any> => {
    if (!(startTime || endTime)) throw Error("Hook not defined properly! Please provide either start_time or end_time")

    const access_token = localStorage['access_token']?.replaceAll('"', '');

    await getSoilProbesIrrigationForecast({
      variables: {
        access_token: access_token,
        start_time: startTime?.toFormat("yyyy-MM-dd HH:mm:ss"),
        end_time: endTime?.toFormat("yyyy-MM-dd HH:mm:ss"),
      },
    });
    if (result.loading) {
      throw Error('Query is still loading');
    } else if (result.error) {
      throw result.error;
    } else {
      return result.data?.irrigationFields;
    }
  };

  return {
    fetchData,
    loading: result.loading,
    error: result.error,
    irrigationFields: result.data?.SoilProbeIrrigationForecast?.irrigationFields ?? null
  };
};

export default useSoilProbesIrrigationForecast;
