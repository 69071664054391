import * as React from 'react';

import styles from './style.module.css';

export interface ILegendButtonProps {
    onClick: React.MouseEventHandler;
    isActive: boolean;
    className?: string;
}

export default function LegendButton(props: ILegendButtonProps) {

    return (
            <button className={props.isActive ? `${props.className} ${styles.button} ${styles.buttonOn}` : `${props.className} ${styles.button} ${styles.buttonOff}`} type="button" onClick={props.onClick}>
                <svg className={props.isActive ? `${styles.svgIcon} ${styles.svgIconOn}` : `${styles.svgIcon} ${styles.svgIconOff}`} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 35 35">
                    <g id="Icon_feather-layers" data-name="Icon feather-layers" transform="translate(-0.988 -1.5)">
                        <path id="Path_612" data-name="Path 612" d="M18,3,3,10.5,18,18l15-7.5Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                        <path id="Path_613" data-name="Path 613" d="M3,25.5,18,33l15-7.5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                        <path id="Path_614" data-name="Path 614" d="M3,18l15,7.5L33,18" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                    </g>
                </svg>
            </button>
    );
}
