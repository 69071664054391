import { FunctionComponent } from "react";
import { Routes, Route } from "react-router-dom";
import MyFields from "./MyFields/MyFields";
import SettingsIndex from "./SettingsIndex/SettingsIndex";
import Error404 from "../Error/404/404";



interface ISettingsProps {

}

const Settings: FunctionComponent<ISettingsProps> = () => {
    return (
        <Routes>
            <Route path='*' element={<Error404 />} />
            <Route index element={<SettingsIndex />} />
            <Route path="myfields/*" element={<MyFields />} />
        </Routes>
    );
}

export default Settings;