import { Close } from "@mui/icons-material";
import { Dialog, DialogTitle, Box, IconButton, DialogContent, Typography, DialogActions, Button, ThemeProvider, createTheme, ClickAwayListener } from "@mui/material";
import { useState } from "react";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          background: 'linear-gradient(to right, #75B9F0, #1085FD)',
          width: '200px',
          height: '48px',
        }
      }
    }
  }
});


interface IConfirmDialogProps {
  open: boolean // default false / closed
  confirmAction: Function,
  title: string,
  closeDialogBox: () => void,
}

const ConfirmDialog = (props: IConfirmDialogProps) => {

  return (
    <ThemeProvider theme={theme}>
        <Dialog open={props.open} maxWidth="sm" fullWidth onClose={ props.closeDialogBox }>
          <Box position="absolute" top={0} right={0}>
            <IconButton onClick={ props.closeDialogBox }>
              <Close />
            </IconButton>
          </Box>
          <DialogContent>
            <Typography variant="h5" fontFamily="Poppins" fontSize="18px">{props.title}</Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            {/* <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={closeDialog}
            sx={{ background: 'linear-gradient(to right, #d78382, #e22c29)' } }
          >
            Cancel
          </Button> */}
            <Button
              variant="contained"
              onClick={() => props.confirmAction()}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
    </ThemeProvider>
  );
};

ConfirmDialog.defaultProps = {
  open: true,
  confirmAction: () => alert("Confirm Dialog has no action!")
}

export default ConfirmDialog;