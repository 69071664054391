import * as React from 'react';

import TopTile from './TopTile';
import styles from './style.module.css';
import { CURRENT_TIME, dateArrayGenerator, getWeekday } from '../../utils/DateConvertor';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../stores/hooks';
import { RootState } from '../../stores/store';

const moment = require('moment-timezone')

export interface IIrrigationForecastTopProps {
    irrigationTotal: number[]
}

export default function IrrigationForecastTop (props: IIrrigationForecastTopProps) {

  const { t } = useTranslation("FieldList");
  const userCurrentAESTDifference =  useAppSelector((state: RootState) => state.user.timezone.AESTDifference)
  const currentUserTime = moment.tz("Australia/Brisbane").add({hour: userCurrentAESTDifference});
  const [dateInfo, setDateInfo] = React.useState(dateArrayGenerator(currentUserTime.toDate(), 10));

  return (
    <div className={styles.topTileWrapper}>
      {dateInfo.map((info, index) => {
        return <TopTile key={index} day={index === 0 ? t("Today") : getWeekday(info)} date={info.getDate()} color={index === 0 ? '#EAC858' : '#F4F7FC'} totalIrrigation={props.irrigationTotal[index]}></TopTile>
      })}
    </div>
  );
}
