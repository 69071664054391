import * as React from 'react';
import { EtcCropWaterUseContext } from '../../context/FieldDetailsContext/EtcCropWaterUseContext';

const useEtcCropWaterUseContext = () => {
    const context = React.useContext(EtcCropWaterUseContext);

    if (context === undefined) {
        throw new Error("useEtcCropWaterUseContext was used outside of its Provider");
      }
    return context;
}

export default useEtcCropWaterUseContext;
