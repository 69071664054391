import { IconButton } from '@mui/material'
import React from 'react'
import useGoannaDrawerContext from '../../../../../hooks/ContextHooks/useGoannaDrawerContext';
import HelpIcon from '@mui/icons-material/Help';
import { GoannaDrawerProps } from '../../../../../components/GoannaDrawer/GoannaDrawer';

interface IHelpIconButtonProps {
    content: GoannaDrawerProps["content"];
    action?: GoannaDrawerProps["action"];
}

export default function HelpIconButton({ action, content }: IHelpIconButtonProps) {
    const { openDrawer } = useGoannaDrawerContext();

    return (
        <IconButton onClick={() => openDrawer(action, content)}
            style={{ marginLeft: "5px" }}>
            <HelpIcon fontSize="medium" style={{ color: "#000" }} />
        </IconButton>
    )
}
