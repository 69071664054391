import { DateTime } from 'luxon';
import * as React from 'react';
import useFieldDetailContext from '../../hooks/ContextHooks/useFieldDetailContext';
import { useAppSelector } from '../../stores/hooks';
import { valueRounder } from '../../utils/numericConversions';
import { EChartsSeriesType } from './SoilTotalContext';

export interface CumulativeDatDegreesGraphSeriesType {
    MaxAirTemp: EChartsSeriesType,
    MinAirTemp: EChartsSeriesType,
    AvgAirTemp: EChartsSeriesType,
    DayDegreesCumulative: EChartsSeriesType,
    AirTempAvgForecast: EChartsSeriesType,
    AirTempMinForecast: EChartsSeriesType,
    AirTempMaxForecast: EChartsSeriesType,
    GrowthStages: GrowthStagesType,
    OpenBoll: any,
    FirstFlower: any,
    FirstSquare: any,
    BaseTemperature: any,
    DayDegreesCumulativeForecast: EChartsSeriesType,
    heatShock: number,
    coldShock: number,
}

export type GrowthStagesType = {
    [key: string]: number;
}

export interface CumulativeDayDegreesContextDataType {
    CumulativeDayDegreesGraphSeries: CumulativeDatDegreesGraphSeriesType,
    loading: boolean,
}


export interface ICumulativeDayDegreesProviderProps {
    children: React.ReactNode,
}

const CumulativeDayDegreesContext = React.createContext({} as CumulativeDayDegreesContextDataType);

export default function CumulativeDayDegreesProvider(props: ICumulativeDayDegreesProviderProps) {

    const { satelliteFieldDataSummary, soilProbesData, BOMForecastData, locationDevicesData } = useFieldDetailContext();
    const [CumulativeDayDegreesDataGraphSeries, setCumulativeDayDegreesDataGraphSeries] = React.useState({} as CumulativeDatDegreesGraphSeriesType);
    const numericSystem = useAppSelector((state) => state.user.numericSystem);

    //update cdd amd temp data
    React.useEffect(() => {
        if (satelliteFieldDataSummary.data) {
            const series = converCumulativeDayDegreesDataSerie();
            setCumulativeDayDegreesDataGraphSeries((prevState) => {
                return {
                    ...prevState,
                    MaxAirTemp: series.MaxAirTemp,
                    MinAirTemp: series.MinAirTemp,
                    AvgAirTemp: series.AvgAirTemp,
                    DayDegreesCumulative: series.DayDegreesCumulative,
                    heatShock: series.heatShcok,
                    coldShock: series.coldShock
                }
            })
        }
    }, [satelliteFieldDataSummary.data, locationDevicesData.data]);

    //update temp forecast data
    React.useEffect(() => {
        if (BOMForecastData.data) {
            const series = convertBOMForecast();
            setCumulativeDayDegreesDataGraphSeries((prevState) => {
                return {
                    ...prevState,
                    AirTempAvgForecast: series.AirTempAvgForecast,
                    AirTempMinForecast: series.AirTempMinForecast,
                    AirTempMaxForecast: series.AirTempMaxForecast
                }
            })
        }
    }, [satelliteFieldDataSummary.data, BOMForecastData.data])
    
    //update growth data and base Temp
    React.useEffect(() => {
        if (soilProbesData.data && soilProbesData.data.data.value.length > 0) {
            
          const series = convertGrowthStages();

          
          setCumulativeDayDegreesDataGraphSeries((prevState) => {
            return {
              ...prevState,
              FirstSquare: series.FirstSquare,
              FirstFlower: series.FirstFlower,
              OpenBoll: series.OpenBoll,
              GrowthStages: series,
              BaseTemperature: soilProbesData.data?.data.value[0].BaseTemperature ?? null
            }
          })
        }
      }, [soilProbesData.data]);
    

      React.useEffect(() => {
        if (BOMForecastData.data && CumulativeDayDegreesDataGraphSeries.DayDegreesCumulative && CumulativeDayDegreesDataGraphSeries.BaseTemperature) {
            const series = convertCumulativeDayDegressForecast(CumulativeDayDegreesDataGraphSeries.DayDegreesCumulative.data[CumulativeDayDegreesDataGraphSeries.DayDegreesCumulative.data.length -1]);
            setCumulativeDayDegreesDataGraphSeries((prevState) => {
                return {
                    ...prevState,
                    DayDegreesCumulativeForecast: series.DayDegreesCumulativeForecast,
                }
            })
        }
      }, [CumulativeDayDegreesDataGraphSeries.DayDegreesCumulative, CumulativeDayDegreesDataGraphSeries.BaseTemperature, BOMForecastData.data]);

    const converCumulativeDayDegreesDataSerie = () => {
        const series = {
            MaxAirTemp: {
                type: 'line' as any,
                name: 'Max Soil Temp',
                data: new Array<any>()
            },
            MinAirTemp: {
                type: 'line' as any,
                name: 'Min Soil Temp',
                data: new Array<any>()
            },
            AvgAirTemp: {
                type: 'line' as any,
                name: 'Avg Temp',
                data: new Array<any>()
            },
            DayDegreesCumulative: {
                type: 'line' as any,
                name: 'Cumulative Day Degrees',
                data: new Array<any>()
            },
            heatShcok: 0,
            coldShock: 0
        };
        let plantingDate: DateTime | null = null;
        let endDate: DateTime | null = null;
        let tmpHeatShock = 0;
        let tmpColdShock = 0;

        //if planting date exist 
        if (locationDevicesData.data) {
            plantingDate = locationDevicesData.data?.data.value[0].PlantingDate ? DateTime.fromISO(locationDevicesData.data?.data.value[0].PlantingDate) : null;
            endDate = locationDevicesData.data?.data.value[0].EndDate ? DateTime.fromISO(locationDevicesData.data?.data.value[0].EndDate) : null;
        }
        satelliteFieldDataSummary.data?.data.forEach((element: any) => {
            if (element.Date && element.DayDegreesCumulative && element.DayDegreesCumulative !== -999) {
                const tmp = [element.Date, element.DayDegreesCumulative];
                series.DayDegreesCumulative.data.push(tmp);
            }
            if (element.Date && element.MaxAirTemp && element.MaxAirTemp !== -999) {
                const dateTime = DateTime.fromISO(element.Date);
                if (isHeatShock(element.MaxAirTemp, dateTime, plantingDate, endDate)) tmpHeatShock++;
                const tmp = [element.Date, element.MaxAirTemp];
                series.MaxAirTemp.data.push(tmp);
            }
            if (element.Date && element.MinAirTemp && element.MinAirTemp !== -999) {
                const dateTime = DateTime.fromISO(element.Date);
                if (isColdShock(element.MinAirTemp, dateTime, plantingDate, endDate)) tmpColdShock++;
                const tmp = [element.Date, element.MinAirTemp];
                series.MinAirTemp.data.push(tmp);
            }
            if (element.Date && element.AvgAirTemp && element.AvgAirTemp !== -999) {
                const tmp = [element.Date, element.AvgAirTemp];
                series.AvgAirTemp.data.push(tmp);
            }
        })
        series.coldShock = tmpColdShock;
        series.heatShcok = tmpHeatShock;
        return series;
    };

    const convertBOMForecast = () => {
        const serie = {
            AirTempAvgForecast: {
                type: 'line' as any,
                name: 'Avg Temp Forecast',
                data: new Array<any>()
            },
            AirTempMinForecast: {
                type: 'line' as any,
                name: 'Min Temp Forecast',
                data: new Array<any>()
            },
            AirTempMaxForecast: {
                type: 'line' as any,
                name: 'Max Temp Forecast',
                data: new Array<any>()
            }
        }

        BOMForecastData.data.forEach((element: any) => {
            if (element.Date && element.AirTempAvg && element.AirTempAvg !== -999) {
                const etc = [element.Date, element.AirTempAvg];
                serie.AirTempAvgForecast.data.push(etc);
            }
            if (element.Date && element.AirTempMin && element.AirTempMin !== -999) {
                const etc = [element.Date, element.AirTempMin];
                serie.AirTempMinForecast.data.push(etc);
            }
            if (element.Date && element.AirTempMax && element.AirTempMax !== -999) {
                const etc = [element.Date, element.AirTempMax];
                serie.AirTempMaxForecast.data.push(etc);
            }
        });
        return serie;
    };

    const convertGrowthStages = () => {
        const series: any = {
            FirstSquare: {
                name: 'First Square',
                type: 'line',
                data: null
            },
            FirstFlower: {
                name: 'First Flower',
                type: 'line',
                data: null
            },
            OpenBoll: {
                name: 'Open Boll',
                type: 'line',
                data: null
            },
        }
        const result = searcher(soilProbesData.data?.data.value[0].GrowthStages);
        Object.keys(series).forEach((key) => {
            if (result.hasOwnProperty(key)) {
                series[key].data = result[key];
            }
        })
        return result;
    };

    const searcher = (str: any) => {
        const series: GrowthStagesType = {}
        if (str && typeof str === 'string') {
            const test = str.split(/[\n]/gm).filter(n => n);

            if (test.length > 0) {
                test.forEach((element: any) => {
                    const tmp = element.split(',');
                    if (tmp.length === 2 && !isNaN(tmp[1]) && !isNaN(tmp[1])) {
                        series[tmp[0]] = numericSystem === "M" ? Number(tmp[1]) : Math.round(Number(tmp[1]) * 1.8 + 32);
                    }
                })
            }
        }

        return series
    }

    const convertCumulativeDayDegressForecast = (lastPoint: [any, any]) => {
        const serie = {
            DayDegreesCumulativeForecast: {
                type: 'line' as any,
                name: 'Cumulative Day Degree',
                data:  new Array<any>()
            },
        }

        if (CumulativeDayDegreesDataGraphSeries.BaseTemperature && CumulativeDayDegreesDataGraphSeries.BaseTemperature > 0) {
            var baseTemp = CumulativeDayDegreesDataGraphSeries.BaseTemperature;
            var currentCumulativeDayDegree: number = lastPoint ? lastPoint[1] : null;
                BOMForecastData.data.forEach((element: any) => {
                    if (element.Date && element.AirTempMin && element.AirTempMax && currentCumulativeDayDegree !== null) {

                        var mintemp = element.AirTempMin;
                        var maxtemp = element.AirTempMax;
                        var daydegrees = 0;
                        var diffmax = ((maxtemp < baseTemp) ? 0 : maxtemp - baseTemp);
                        var diffmin = ((mintemp < baseTemp) ? 0 : mintemp - baseTemp);
                        daydegrees = ((diffmax) + (diffmin)) / 2;
                        currentCumulativeDayDegree += daydegrees;
                        const tmp: [any, any] = [element.Date, valueRounder(currentCumulativeDayDegree)];
                        serie.DayDegreesCumulativeForecast.data.push(tmp);
                    }
                });
        }
        return serie;
    };

    const isHeatShock = (temp: number, dateTime: DateTime, plantingDate: DateTime | null, endDate: DateTime | null) => {

       if (plantingDate !== null && plantingDate >= dateTime) return false;
       if (endDate !== null && endDate <= dateTime) return false;
       return numericSystem === 'M' ? temp >= 35 : numericSystem === 'I' && temp >= 95;
    }
    const isColdShock = (temp: number, dateTime: DateTime, plantingDate: DateTime | null, endDate: DateTime | null) => {

        if (plantingDate !==null && plantingDate >= dateTime) return false;
        if (endDate !== null && endDate <= dateTime) return false;
        return numericSystem === 'M' ? temp <= 11 : numericSystem === 'I' && temp <= 51.8
    }

    return (
        <CumulativeDayDegreesContext.Provider value={{ CumulativeDayDegreesGraphSeries: CumulativeDayDegreesDataGraphSeries, loading: BOMForecastData.isLoading || soilProbesData.isLoading }}>
            {props.children}
        </CumulativeDayDegreesContext.Provider>
    );
}

export { CumulativeDayDegreesContext, CumulativeDayDegreesProvider };
