import * as React from 'react';
import { FieldDetailContext } from '../../context/FieldDetailsContext/FieldDetailsContext';

const useFieldDetailContext = () => {
    const context = React.useContext(FieldDetailContext);

    if (context === undefined) {
        throw new Error("useFieldDetailContext was used outside of its Provider");
      }
    return context;
}

export default useFieldDetailContext;