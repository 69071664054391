import * as React from 'react';
import useFieldDetailContext from '../../hooks/ContextHooks/useFieldDetailContext';
import { useAppSelector } from '../../stores/hooks';
import { RootState } from '../../stores/store';
import { valueRounder } from '../../utils/numericConversions';
import { EChartsSeriesType } from './SoilTotalContext';

export interface EtcCropWaterUseGraphSeriesType {
  EvapoTranspiration: EChartsSeriesType,
  EvapoTranspirationCumulative: EChartsSeriesType,
  Etc_Forecast: EChartsSeriesType,
  Etc_Forecast_Cumulative: EChartsSeriesType,
  NDVIDate: { [key: string]: any };
}

export interface EtcCropWaterUseContextDataType {
  EtcCropWaterUseGraphSeries: EtcCropWaterUseGraphSeriesType,
  loading: boolean,
}


export interface IEtcCropWaterUseProviderProps {
  children: React.ReactNode,
}

const EtcCropWaterUseContext = React.createContext({} as EtcCropWaterUseContextDataType);

export default function EtcCropWaterUseProvider(props: IEtcCropWaterUseProviderProps) {

  const [EtcCropWaterUseData, setEtcCropWaterUseData] = React.useState({} as EtcCropWaterUseGraphSeriesType);
  const { satelliteFieldDataSummary, BOMForecastData } = useFieldDetailContext();
  const numericSystem = useAppSelector((state: RootState) => state.user.numericSystem)

  React.useEffect(() => {
    if (satelliteFieldDataSummary.data) {
      const { series, NDVIDate } = convertEtcDataSerie();
      setEtcCropWaterUseData((prevState) => {
        return {
          ...prevState,
          EvapoTranspirationCumulative: series.EvapoTranspirationCumulative,
          EvapoTranspiration: series.EvapoTranspiration,
          NDVIDate,
        }
      })

    }
  }, [satelliteFieldDataSummary.data]);

  React.useEffect(() => {
    if (EtcCropWaterUseData.EvapoTranspirationCumulative && BOMForecastData.data) {
      const series = convertEvapoTranspirationForecastData(EtcCropWaterUseData.EvapoTranspirationCumulative.data[EtcCropWaterUseData.EvapoTranspirationCumulative.data.length - 1]);
      setEtcCropWaterUseData((prevState) => {
        return {
          ...prevState,
          Etc_Forecast: series.Etc_Forecast,
          Etc_Forecast_Cumulative: series.Etc_Forecast_Cumulative
        }
      })
    }
  }, [EtcCropWaterUseData.EvapoTranspirationCumulative, BOMForecastData.data])

  const convertEtcDataSerie = () => {
    const series = {
      EvapoTranspiration: {
        type: 'line' as any,
        name: 'EvapoTranspiration',
        data: new Array<any>()
      },
      EvapoTranspirationCumulative: {
        type: 'line' as any,
        name: 'EvapoTranspirationCumulative',
        data: new Array<any>()
      }
    };

    const NDVIDateSet = new Set<string>();
    const NDVIDate: { [key: string]: any } = {};
    satelliteFieldDataSummary.data?.data.forEach((element: any) => {
      if (element.NDVIDate && !NDVIDate.hasOwnProperty(element.NDVIDate)) {
        NDVIDate[element.NDVIDate] = null;
      }
      if (element.Date && element.EvapoTranspiration && element.EvapoTranspiration !== -999) {
        const tmp = [element.Date, element.EvapoTranspiration];
        series.EvapoTranspiration.data.push(tmp);
        if (NDVIDate.hasOwnProperty(element.Date) && NDVIDate[element.Date] === null) {
          NDVIDate[element.Date] = tmp;
        }
      }
      if (element.Date && element.EvapoTranspirationCumulative && element.EvapoTranspirationCumulative !== -999) {
        const tmp = [element.Date, element.EvapoTranspirationCumulative];
        series.EvapoTranspirationCumulative.data.push(tmp);
      }
    });
    series.EvapoTranspiration.data.forEach((element: any) => {
      if (NDVIDateSet.has(element[0])) {
        NDVIDate[element[0]] = element[1]
      }
    });

    return { series, NDVIDate };
  };

  const convertEvapoTranspirationForecastData = (lastPoint: [any, any]) => {
    const series = {
      Etc_Forecast: {
        type: 'line' as any,
        name: 'Etc_Forecast',
        data: new Array<any>()
      },
      Etc_Forecast_Cumulative: {
        type: 'line' as any,
        name: 'Etc_Forecast_Cumulative',
        data: new Array<any>()
      },
    }
    let currentTotal: number = lastPoint ? lastPoint[1] : null;
    BOMForecastData.data.forEach((element: any) => {
      if (element.Date && element.Etc && element.Etc !== -999 && currentTotal !== null && element.Etc >= 0) {
        const etcValueReverseConverted = numericSystem == "M" ? element.Etc : valueRounder(element.Etc * 62.765 / 25.4);
        currentTotal += etcValueReverseConverted;

        series.Etc_Forecast_Cumulative.data.push([element.Date, valueRounder(currentTotal)]);
        series.Etc_Forecast.data.push([element.Date, etcValueReverseConverted]);
      }
    });
    return series;
  };

  return (
    <>
      <EtcCropWaterUseContext.Provider value={{ EtcCropWaterUseGraphSeries: EtcCropWaterUseData, loading: satelliteFieldDataSummary.isLoading || BOMForecastData.isLoading }}>
        {props.children}
      </EtcCropWaterUseContext.Provider>
    </>
  );
}

export { EtcCropWaterUseContext, EtcCropWaterUseProvider };