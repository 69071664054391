
import * as React from 'react';
import logo from '../../img/GoannaAG_Logo_RGB.png'
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function Banner() {
    let report = "Seasonal Field Irrigation Report";
    return (
        <div style={{
            textAlign: 'center',
            marginTop: '40px',
        }}>
            <Typography variant="h6" color="black" component="div">
                {report}
            </Typography>
        </div>

    );
}

export default Banner;