import * as React from 'react';
import { ForecastContext } from '../../context/ForecastContext/ForecastContext';

const useForecastContext = () => {
    const context = React.useContext(ForecastContext);

    if (context === undefined) {
        throw new Error("useForecastContext was used outside of its Provider");
    }
    return context
}

export default useForecastContext;