import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import TextActionList, { itemsType } from "../../components/TextListIcon/TextActionList";
import ContactUs from "./ContactUs";
import PopularLinks from "./PopularLinks";
import { useTranslation } from "react-i18next";
import Error404 from "../Error/404/404";
import { useAppSelector } from "../../stores/hooks";

interface IHelpProps { }

const Help: FunctionComponent<IHelpProps> = () => {
    return <>
        <Routes>
            <Route path='*' element={<Error404 />} />
            <Route index element={<HelpIndex />} />
            <Route path="contactUs/*" element={<ContactUs />} />
        </Routes>
    </>
}

// right now all locations lead to the same page but will be changed later
const urlMap = {
    'AU': 'https://www.goannaag.com.au/gftraus',
    'US': 'https://www.goannaag.com/us-gftr'
}

const HelpIndex = () => {
    const { t } = useTranslation("HelpPage")
    const numericSystem = useAppSelector((state) => state.user.numericSystem);
    
    const items: itemsType["items"] = [
        {
            name: t('trainingAndResources'),
            showSideArrow: true,
            onClickCallback: () => { 
                if (numericSystem === 'M') {
                    window.open(urlMap.AU, '_blank') 
                }
                else {
                    window.open(urlMap.US, '_blank') 
                }
            },
            action: <></>,
            hoverOverEffect: true
        },
        {
            name: t("contactUs"),
            showSideArrow: true,
            navigateTo: 'contactUs',
            action: <></>,
            hoverOverEffect: true
        }
    ]

    return <TextActionList items={items} />
}

export default Help;