import { useState, useCallback, useEffect } from 'react';
import { toBlob } from 'html-to-image';
import jsPDF from 'jspdf';
import { font as regularFont } from '../assets/fonts/Poppins-Regular-normal.js';
import { font as lightFont } from '../assets/fonts/Poppins-Light-normal.js';
import textLogo from '../assets/images/textlogo-tranparent.png';

const screenHeight = window.innerHeight;

const useDownloadPdf = () => {
  const [loading, setLoading] = useState(false);

  const logo = textLogo;
  const title = 'Document Title'; // Your document title

  const downloadPdf = useCallback(async (
    printRefs: Array<React.MutableRefObject<any>>,
    fileName: string,
    title: string = 'Document',
    pageSize: 'A4' | 'Letter' = 'A4',
    irrigationForecast: string ='',
    dateRange: string = '',
  ) => {
    setLoading(true);
    const pageDimensions = {
      A4: { width: 210, height: 297 },
      Letter: { width: 216, height: 279 },
    };

    const { width: pageWidth, height: pageHeight } = pageDimensions[pageSize];
    const pdf = new jsPDF('p', 'mm', pageSize, true);

    pdf.addFileToVFS('Poppins-Regular-normal.ttf', regularFont);
    pdf.addFileToVFS('Poppins-Light-normal.ttf', lightFont);
    pdf.addFont('Poppins-Regular-normal.ttf', 'Poppins-Regular', 'normal');
    pdf.addFont('Poppins-Light-normal.ttf', 'Poppins-Light', 'normal');
    let currentPageHeight = pageHeight - 25; // Subtract 25 for header and footer space

    if (printRefs.length === 0) {
      pdf.setFont('Poppins-Regular', "normal");
      pdf.setFontSize(10);
      pdf.text('No graphs selected.', pageWidth / 2, 15, { align: 'center' }); // Adjust vertical position      pdf.setFont('Poppins-Regular', 'normal');
    }

    let pageNo = 1;
    for (let i = 0; i < printRefs.length; i++) {
      const element = printRefs[i].current;

      if (!element) continue;

      const blob = await toBlob(element);
      if (blob) {
        const url = URL.createObjectURL(blob);
        const img = new Image();
        img.src = url;

        await new Promise((resolve) => {
          img.onload = resolve;
        });

        const imgWidth = pageWidth - 20; // Subtracting 10 for padding
        const imgHeight = (img.height * imgWidth) / img.width;
        const position = 10; // Padding of 10

        // Check if component can fit on the current page (including footer space)
        if (imgHeight + 20 > currentPageHeight) { // Adding 20 for footer space
          pdf.addPage();
          currentPageHeight = pageHeight - 25; // Subtract 25 for header and footer space
          pageNo += 1;
        }

        // Header
        if (pageNo === 1) {
          // pdf.setFont('Poppins', 'normal');
          pdf.setFont('Poppins-Regular', "normal");
          pdf.setFontSize(10);
          pdf.text(title, pageWidth / 2, 15, { align: 'center' }); // Adjust vertical position      pdf.setFont('Poppins-Regular', 'normal');
          pdf.setFont('Poppins-Light', "normal", 400);
          pdf.setFontSize(8);
          pdf.text(irrigationForecast, 10, 25, { align: 'left' });
          pdf.text(dateRange, 10, 30, { align: 'left' });
        }

        // Add header dividing line
        pdf.setLineWidth(0.01); // Set line width
        pdf.setDrawColor(160, 160, 160); // Set line color to gray
        pdf.line(10, 20, pageWidth - 10, 20); // Draw line

        // Body
        pdf.addImage(
          url,
          'JPEG',
          position,
          pageHeight - currentPageHeight + position,
          imgWidth,
          imgHeight,
          undefined,
          'FAST'
        );

        const borderOffset = 0.5; // Adjust this value as needed
        const removeLength = screenHeight > 720 ? 8 : 10;
        const borderTopY = pageHeight - currentPageHeight + position - borderOffset + 2;
        const borderBottomY = pageHeight - currentPageHeight + position + imgHeight + borderOffset - removeLength;

        // Left border
        pdf.line(position, borderTopY, position, borderBottomY);

        // Right border
        pdf.line(position + imgWidth, borderTopY, position + imgWidth, borderBottomY);

        currentPageHeight -= imgHeight + position; // Reduce remaining height by the added component's height

        // Footer
        pdf.setFont('Poppins-Light', 'normal', 400);
        pdf.setFontSize(8);
        pdf.setTextColor('#707070');
        pdf.text(`Page ${pageNo}`, pageWidth - 20, pageHeight - 6, { align: 'right' });
        pdf.addImage(logo, 'PNG', pageWidth / 2.2, pageHeight - 10, 20, 6); // Adjust vertical position
        // Add footer dividing line
        pdf.setLineWidth(0.01); // Set line width
        pdf.setDrawColor(160, 160, 160); // Set line color to gray
        pdf.line(10, pageHeight - 15, pageWidth - 10, pageHeight - 15); // Draw line

        // Revoke the url
        URL.revokeObjectURL(url);
      } else {
        console.error('Failed to create blob from element');
      }
    }

    pdf.save(`${fileName + ' ' + dateRange}.pdf`);
    pdf.output("dataurlnewwindow", { filename: `${fileName + ' ' + dateRange}.pdf` });
    setLoading(false);
  }, []);

  return { downloadPdf, loading };
};

export default useDownloadPdf;