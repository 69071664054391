import { Checkbox, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Draggable } from '@hello-pangea/dnd';
export interface IDraggableItemProps {
    labelId: string,
    internalID: string,
    displayValue: string,
    handleItemSelect: (item: string) => void,
    index: number,
    checked: number,
    totalItems: number,
}

export default function DraggableItem({ labelId, internalID: value, displayValue: item, handleItemSelect, index, checked,totalItems }: IDraggableItemProps) {
    const isLastItem = index === totalItems - 1;
    return (
        <Draggable draggableId={item} index={index}>
            {(provided, snapshot) => (
                <>
                    <ListItem
                        key={value}
                        secondaryAction={
                            <DragIndicatorIcon fontSize='small' color='action' />
                        }
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        disablePadding
                        sx={{ height: '40px' }}
                    >
                        <ListItemButton
                            role={undefined}
                            onClick={() => handleItemSelect(value)}
                            sx={{ height: '40px' }}
                        >
                            <ListItemIcon sx={{ marginRight: '-22px' }}>
                                <Checkbox
                                    edge="start"
                                    checked={checked === 1 || checked === 3}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    disabled={checked === 3} // always selected
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${item}`} />
                        </ListItemButton>
                    </ListItem>
                    {!isLastItem && <Divider variant="middle" sx={{ marginLeft: 'auto', marginRight: 'auto', width: '95%' }} {...provided.draggableProps} />}
                </>
            )}
        </Draggable>
    );
}
