import * as React from 'react';
import { Route, Routes, useNavigate, Navigate, useLocation, Location } from "react-router-dom";
import ForecastContextProvider from '../../context/ForecastContext/ForecastContext';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { enUS } from '@mui/x-data-grid-pro';

import ShortcutAddButton from '../../components/ShortcutAddButton/ShortcutAddButton';
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

import styles from './style.module.css';
import FieldListComponent from '../Dashboard/FieldList/FieldListComponent';
import FieldTableComponent from '../Dashboard/FieldTable/NewFieldTable';
import ComingSoon from '../ComingSoon';
import FieldDetail from '../Dashboard/FieldDetail/FieldDetail';
import { useDispatch } from 'react-redux';
import CustomizationButton from '../Dashboard/FieldTable/components/CustomizationButton/CustomizationButton';
import useDownloadPdf from '../../hooks/useDownloadPdf';
import { CircularProgress } from "@mui/material";
import { useAppSelector } from '../../stores/hooks';
import { UpdateTabTitle } from '../../utils/updateTabTitle';
import Error404 from '../Error/404/404';
import GoannaButton from '../../components/GoannaButton/GoannaButton';
import EndOfSeasonReport from '../Dashboard/EndOfSeasonReport/EndOfSeasonReport';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#0958FF' },
    },
  },
  enUS,
);

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: 3,
    backgroundColor: '#ffffff',
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export interface IForecastProps {
}

export default function Forecast(props: IForecastProps) {

  const navigate = useNavigate();
  const location = useLocation();

  const toggleOptions = ['fieldTable', 'fieldList', 'fieldMap'];
  const tableRef = React.useRef<any>();
  const listRef = React.useRef<any>();
  const [view, setView] = React.useState(location.pathname.split('/')[location.pathname.split('/').length - 1]);

  const titleSwitch = (): string => {
    switch (view) {
      case 'fieldList':
        return 'Field List';
      case 'fieldTable':
        return 'Field Table';
      case 'fieldMap':
        return 'Field Map';
      default:
        return ''
    };
  };

  React.useEffect(() => {
    switch (view) {
      case 'fieldList':
        UpdateTabTitle('Field List');
        break;
      case 'fieldTable':
        UpdateTabTitle('Field Table');
        break;
      case 'fieldMap':
        UpdateTabTitle('Field Map');
        break;
      default:
        UpdateTabTitle('');
        break;
    };
  }, [view])

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newField: string | null
  ) => {
    if (newField !== null) {
      navigate(newField);
    }
  };

  const [fetchedActiveOnly, setFetchedActiveOnly] = React.useState(true);
  const [status, setStatus] = React.useState('Active');

  const toggleStatus = () => {
    switch (status) {
      case 'Active':
        if (fetchedActiveOnly) getActiveAndInactive();
        setStatus('Active and inactive');
        break;
      case 'Inactive':
        setStatus('Active');
        break;
      case 'Active and inactive':
        setStatus('Inactive');
        break;
    }
  };

  //logic is current status is Active then the next status will be active and inactive by clicking the button
  const titleMap: {[key: string]: string} = {
    "Active": "active and inactive",
    "Active and inactive": "inactive only",
    "Inactive": "active only",
  }

  const getActiveAndInactive = () => {
    setFetchedActiveOnly(false);
  }

  //updating the forecast toggle position
  React.useEffect(() => {
    let pathName = location.pathname.slice(location.pathname.lastIndexOf('/') + 1, location.pathname.length);
    if (pathName !== "goField") setView(pathName);
  }, [location.pathname])

  const isMobile = useAppSelector(state => state.topbar.isMobile)

  return (
    <>
      <ForecastContextProvider fetchedActiveOnly={fetchedActiveOnly} status={status}>
        {
          toggleOptions.includes(view) &&
          <ThemeProvider theme={theme}>
            <div className={styles.wrapper}>
              <div className={styles.pageHeader}>
                <div className={styles.topLine}>
                  <div style={isMobile ? { position: "fixed", top: "0px", right: "10px", zIndex: "97" } : undefined}>
                    <ShortcutAddButton name={titleSwitch()} />
                    {(view === "fieldTable") && <CustomizationButton />}
                  </div>
                </div>
                {!isMobile && <div className={styles.Title}>{titleSwitch()}</div>}
                <div className={styles.toggleButton}>
                  <Box sx={{
                    boxShadow: 1,
                    borderRadius: 2
                  }}>
                    <StyledToggleButtonGroup
                      value={view}
                      color="primary"
                      exclusive
                      onChange={handleToggleChange}
                      aria-label="toggle button"
                      size="small"
                    >
                      <ToggleButton
                        value="fieldTable"
                        aria-label="Field Table"
                        selected={view === 'fieldTable'}
                      >
                        <ViewModuleIcon />
                      </ToggleButton>
                      <ToggleButton
                        value="fieldList"
                        aria-label="Field List"
                        selected={view === 'fieldList'}
                      >
                        <ViewListIcon />
                      </ToggleButton>
                      {/* <ToggleButton
                        value="FieldMap"
                        aria-label="Field Map"
                        selected={view === 'FieldMap'}
                      >
                        <MapIcon />
                      </ToggleButton> */}
                    </StyledToggleButtonGroup>
                  </Box>
                  <span style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '20px'
                  }}>
                  <Typography style={{color: '#FF0000'}}>{'(Showing ' + status + ')'}</Typography>
                  <GoannaButton width='238px' height='38px' onClick={toggleStatus}  title={`Show ${titleMap[status]}`} />
                  </span>
                </div>
              </div>
            </div>
          </ThemeProvider>
        }

        <Routes>
          <Route path='*' element={<Error404 />} />
          <Route path='fieldTable' element={<div className={styles.subrouteWrapper} ref={tableRef}>
            <FieldTableComponent />
          </div>} />
          <Route path='fieldList' element={<div className={[styles.subrouteWrapper, styles.fieldList].join(" ")} ref={listRef}>
            <FieldListComponent />
          </div>} />
          <Route path='fieldMap' element={<div className={styles.subrouteWrapper}>
            <ComingSoon />
          </div>} />
          <Route path='fields/' element={<Navigate to='../fieldTable' replace />} />
          <Route path='/' element={<Navigate to={toggleOptions.includes(view) ? view : 'fieldTable'} replace />} />
          <Route
            path='fields/:locationID'
            element={
              <div className={styles.subrouteWrapper}>
                <FieldDetail />
              </div>
            }
          />
          <Route
            path='endOfSeasonReport/:locationID'
            element={
              <div className={styles.subrouteWrapper}>
                <EndOfSeasonReport />
              </div>
            }
          />
        </Routes>
      </ForecastContextProvider >

    </>
  );
}