/* This file contains information about the validation schemas used in the application
 * Date Created: 12/09/2023
 * Author: @charliedua
 */

import * as yup from "yup";

export const basicTextBoxRegEx: RegExp = /^[a-zA-Z0-9@._\-'& ]+$/;

const usernameValidation = yup.string().matches(/^[-.@_a-z0-9 +]+$/gi, 'Invalid Charater').required('Username is required')
const passwordValidation = yup.string().required('Password is required')

// FIXME: change email to username because it is actually username
export const loginFormSchema = yup.object({
    email: usernameValidation,
    password: yup.string().required('Password is required'),
});

export const resetPasswordFormSchema = yup.object({ username: usernameValidation });
export const newPasswordFormSchema = yup.object({ password: passwordValidation, confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')});