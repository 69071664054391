import { gql, useLazyQuery } from "@apollo/client";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";
import { initialState, setFilterModel, setGraphPreference, setShortCutPages, setSortModel, setTablePreference, setUser, shortCutPagesValueType, userState } from "../../stores/Slices/userSlice";
import { updatePreferences } from "../../utils/dataTransformation";
import { UpdateTablePrefrenceInDB } from "../../pages/Dashboard/FieldTable/NewFieldTable";
import useTimeZones from "./useTimezones";
import { http } from "../../api/http";
import LogRocket from "logrocket";
import { secondaryClient } from "../../api/apollo";
import { Credentials } from "../../api/services/login";
import i18n from "../../locales/i18n";

/* Pseudocode for login with token
 *  LoginWithToken(token): 
 *    set auth_token in localstorage
 *    get numeric system from v1
 *    set IsV2User to true (default)
 *    get user from V2 Graphql
 *      then: get timezone
 *      then: AfterGetUser(gqlData, token, numericSystem)
 *        navigate to dashboard
 * 
 *  AfterGetUser(gqlData, token, numericSystem):
 *    check if userID returned from gql Yes:
 *      parse gql data;
 *      logrocket identify (maybe make it a variable)
 *    No:
 *      use defaults
 *      CREATE_USER_PROFILE
 *      set up roles
 *    save user;
 */

const GET_USER = gql`
query ($accesstoken: String!) {
    SearchUserSetting (where: {authToken: $accesstoken})
    {
        userID
        preferredFields
        tablePreference
        graphPreference
        dashBoardPreference
        shortCutPages
        numericSystem
        dateRange
        timezone
        language
        filterModel
        sortModel
    }
}
`;

async function getV1User() {
  const data: any = await http.get("api/userProfileDetailed/userProfileDetailed")
  // Only use V1 Numeric system for this gql is not used
  return data.data.MetricImperial == 0 ? "M" : "I";
}

function useUserSettings() {
  const dispatch = useAppDispatch();
  const [getUserFromGraphQL, { loading, error, data }] = useLazyQuery(GET_USER, { fetchPolicy: 'no-cache' });

  const TimeZonesQuery = useTimeZones();

  const graphPreference = useAppSelector(state => state.user.graphPreference);

  function afterGetUser(data: { SearchUserSetting: any[]; }, numericSystem: string, tz_data: any, roles: string[]) {
    const user = (data && (data.SearchUserSetting.length > 0))
      ? data.SearchUserSetting[0]
      : undefined;
    let tmp: userState = {
      ...initialState
    };

    if (user) {
      try {
        if (user.userID) {
          tmp.userID = JSON.parse(user.userID);
          // LogRocket.identify(String(tmp.userID), {
          //   name: loginCredentials.username
          // });
        }
        if (user.preferredFields) tmp.preferredFields = JSON.parse(user.preferredFields) as number[];

        if (user.tablePreference) {
          const tempTablePreference = JSON.parse(user.tablePreference) as userState["tablePreference"];

          if (!Array.isArray(tempTablePreference)) {
            tmp.tablePreference = updatePreferences(tempTablePreference, "T");
          }
          else {
            UpdateTablePrefrenceInDB(initialState.tablePreference);
          }

          // ELSE: if the older version of table prefrence is there with the type number[]
          // we use initial values
        }

        if (user.graphPreference) {
          const tempVariable = JSON.parse(user.graphPreference) as userState["graphPreference"];
          tmp.graphPreference = updatePreferences(tempVariable, "G");
        };
        if (user.dashBoardPreference) tmp.dashBoardPreference = JSON.parse(user.dashBoardPreference) as number[];
        if (user.shortCutPages) tmp.shortCutPages = JSON.parse(user.shortCutPages) as shortCutPagesValueType[];
        if (user.dateRange) tmp.dateRange = user.dateRange;
        if (user.sortModel) tmp.sortModel = JSON.parse(user.sortModel);
        if (user.filterModel) tmp.filterModel = JSON.parse(user.filterModel);
        const TimeZoneData: Array<any> = tz_data.data?.value.map((a: any): userState["timezone"] => ({
          name: a.TimeZoneName,
          code: a.TimeZoneCode,
          AESTDifference: a.GMTDifference,
          ID: a.TimeZoneID
        }));

        if (user.timezone) tmp.timezone = TimeZoneData.find(a => a.ID === JSON.parse(user.timezone ?? 28)); // Defaults to AEST for new users; 15 = AEST
        if (user.language) {
          tmp.language = user.language;
          i18n.changeLanguage(tmp.language);
        }

        tmp.numericSystem = numericSystem;
      }
      catch (err) {
        console.log(err);
      }
    }
    else {
      const CREATE_USER_PROFILE = gql`mutation {
            InsertUserSetting(input: {shortCutPages: "[]", timezone: "28", authToken: ${localStorage.getItem("access_token")}}) {
              userID
            }
          }`;

      secondaryClient.mutate({ mutation: CREATE_USER_PROFILE }).catch(e => { });
    }
    // tmp.role = getLoginData().access_roles;
    tmp.role = roles;
    dispatch(setUser(tmp));
  }

  async function GetUserFromGraphQl(roles: string[], token?: string) {
    const response = await getUserFromGraphQL({
      variables: { accesstoken: token ?? localStorage.getItem("access_token")?.replaceAll("\"", '') }
    });

    const numericSystem = await getV1User();
    const { data: tz_data } = await TimeZonesQuery.refetch();

    afterGetUser(response.data, numericSystem, tz_data, roles)
  }

  async function refreshReduxFromGraphQL(token?: string) {
    const response = await getUserFromGraphQL({
      variables: { accesstoken: token ?? localStorage.getItem("access_token")?.replaceAll("\"", '') }
    });

    const DB_graphPreference = JSON.parse(response.data.SearchUserSetting[0].graphPreference);
    if (!deepEqual(graphPreference, DB_graphPreference)) {
      if (response.data.SearchUserSetting[0].graphPreference) {
        dispatch(
          setGraphPreference(
            updatePreferences(DB_graphPreference, "G")
          )
        );
      }
    }

    if (response.data.SearchUserSetting[0].tablePreference) {
      const tempTablePerefrence = JSON.parse(response.data.SearchUserSetting[0].tablePreference);

      if (!Array.isArray(tempTablePerefrence)) {
        dispatch(
          setTablePreference(
            updatePreferences(tempTablePerefrence, "T")
          )
        );
      }
      else {
        UpdateTablePrefrenceInDB(initialState.tablePreference);
        dispatch(
          setTablePreference(initialState.tablePreference)
        );
      }
    }
    else {
      dispatch(
        setTablePreference(initialState.tablePreference)
      );
    }
    if (response.data.SearchUserSetting[0].sortModel) {
      const tempSortModel = JSON.parse(response.data.SearchUserSetting[0].sortModel);
      dispatch(
        setSortModel(tempSortModel)
      )
    }
    if (response.data.SearchUserSetting[0].filterModel) {
      const tempFilterModel = JSON.parse(response.data.SearchUserSetting[0].filterModel);
      dispatch(
        setFilterModel(tempFilterModel)
      )
    }
    if (response.data.SearchUserSetting[0].shortCutPages) {
      const shortCuts = JSON.parse(response.data.SearchUserSetting[0].shortCutPages) as shortCutPagesValueType[];
      dispatch(
        setShortCutPages(shortCuts)
      );
    }
  }

  return {
    refreshReduxFromGraphQL,
    GetUserFromGraphQl,
    loading
  };
}

export default useUserSettings;

/*  LoginWithToken(token): 
 *    set auth_token in localstorage
 *    get numeric system from v1
 *    set IsV2User to true (default)
 *    get user from V2 Graphql
 *      then: get timezone
 *      then: AfterGetUser(gqlData, token, numericSystem)
 *        navigate to dashboard
 * 
 *  AfterGetUser(gqlData, token, numericSystem):
 *    check if userID returned from gql Yes:
 *      parse gql data;
 *      logrocket identify (maybe make it a variable)
 *    No:
 *      use defaults
 *      CREATE_USER_PROFILE
 *      set up roles
 *    save user;
 */

function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}