import Box from '@mui/material/Box';
import { ReactECharts } from '../../../components/ReactEcharts/ReactEcharts';
import React, { forwardRef } from 'react';
import { EChartsOption } from 'echarts';

interface Props {
    data: string;
    color: string[];
}

const GaugeStats = forwardRef(function GaugeStats(props: Props, ref) {
    const { data, color } = props;
    let valueColor = "red"
    // if(dataValue.days < 30){
    //   valueColor = props.color[0]
    // } else if (dataValue.days < 70){
    //   valueColor= props.color[1]
    // } else {
    //   valueColor = props.color[2]
    // }
    valueColor = color[Math.trunc(Number(data) / 35)]
    const options: any = {
        tooltip: {
            formatter: '{a} {b} : {c}%'
        },
        series: [
            {
                name: 'Days',
                type: 'gauge',
                data: [
                    {
                        value: data,
                        itemStyle: { color: valueColor }
                    }
                ],
                detail: {
                    valueAnimation: true,
                    formatter: '{value}'
                },
                pointer: {
                    icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                    length: '80%',
                    width: 10,
                    itemStyle: { color: "black" }
                },
                startAngle: 180,
                endAngle: 0,
                progress: {
                    show: false,
                    width: 20
                },
                axisLine: {
                    lineStyle: {
                        width: 20,
                        color: [
                            [0.2, color[0]],
                            [0.5, color[1]],
                            [0.8, color[2]],
                            [1, color[3]]
                        ]
                    }
                },
                splitLine: {
                    show: false,
                    distance: 0,
                    length: 10
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
            }
        ]
    };
    return (
        <ReactECharts
            option={options}
            style={{
                marginTop: '10px',
                width: '100%',
                height: '100%'
              }}
        />
    );
})

export default GaugeStats;