import * as React from 'react';	

import Box from '@mui/material/Box';	
import InputLabel from '@mui/material/InputLabel';	
import MenuItem from '@mui/material/MenuItem';	
import FormControl from '@mui/material/FormControl';	
import Select from '@mui/material/Select';	
import SearchBar from '../../../../components/SearchBar/SearchBar';	
import FilterButton from '../../../../components/Buttons/FieldListTopBarButtons/FilterButton';	
import LegendButton from '../../../../components/Buttons/FieldListTopBarButtons/LegendButton';	
import { sortConst } from '../../../../utils/sort';	
import styles from './style.module.css';	

import { createTheme, InputAdornment, ThemeProvider, Typography, withStyles } from '@mui/material';	
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';	
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';	
import { display } from '@mui/system';	
import { FieldListComponentContext } from '../FieldListComponent';	
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../stores/hooks';
import { setSortModel } from '../../../../stores/Slices/userSlice';
import { UpdateSortModelInDB } from '../../FieldTable/NewFieldTable';


const theme = createTheme({	
    typography: {	
        button: {	
            textTransform: 'none'	
        }	
    },	

});	
export interface IFieldListTopBarProps {	
}	

const sortReverseMap: any = {
    "1": {
        field: 'IrrigationDueForecast',
        sort: 'asc'
    },
    "2": {
        field: 'IrrigationDueForecast',
        sort: 'desc'
    },
    "3": {
        field: 'LocationDescription',
        sort: 'asc'
    },
    "4": {
        field: 'LocationDescription',
        sort: 'desc'
    },
    "5": {
        field: 'CropName',
        sort: 'asc'
    },
    "6": {
        field: 'CropName',
        sort: 'desc'
    },
}

export default function FieldListTopBar(props: IFieldListTopBarProps) {	

    const { sort, setSort } = React.useContext(FieldListComponentContext);
    const dispatch = useAppDispatch();
    const sortModel = useAppSelector(state => state.user.sortModel);
    
    const handleChange = (event: any) => {	
        setSort(event.target.value);
        
        const newModel = [sortReverseMap[event.target.value]]
        if (newModel) {
            const updatedSortModel = {
                ...sortModel,
                "ProbeTable": newModel,
              }
              dispatch(setSortModel(updatedSortModel))
              UpdateSortModelInDB(updatedSortModel);
        }

    };	

    const [showLegend, setShowLegend] = React.useState(true);	

    const toggleShowLegend = () => {	
        setShowLegend(!showLegend);	
    };	

    const [filter, setFilter] = React.useState(false);	

    const handleFilterButtonClick = () => {	
        setFilter(!filter);	
    }

    const { t } = useTranslation("FieldListTopBar");

    return (	
        <ThemeProvider theme={theme}>	
            <div className={styles.fieldListTopBar}>	
                <div className={styles.fieldListTopBarWrapper}>	
                    <div className={styles.leftSide}>	
                        <div className={styles.selectWrapper}>	
                            <FormControl fullWidth size="medium">	
                                <Select	
                                    labelId="select-label"	
                                    id="select"	
                                    value={sort}	
                                    label=""	
                                    onChange={handleChange}	
                                    sx={{ backgroundColor: 'white', height: '2.5rem'}}	
                                    startAdornment={<InputAdornment position='start'>Sort by: </InputAdornment>}	
                                    MenuProps={{	
                                        anchorOrigin: {	
                                            vertical: "bottom",	
                                            horizontal: "right"	
                                          },	
                                          transformOrigin: {	
                                            vertical: "top",	
                                            horizontal: "right"	
                                          },	
                                      }}	
                                >	
                                    <MenuItem value={sortConst.Irrigation_Forecast_Date_ASC} ><div className={styles.menuItem}><Typography variant="inherit" noWrap>{t("FieldListTopBar-IrrigationForecastDate")}</Typography><div className={styles.menuItemArrow}><ArrowUpwardIcon /></div></div></MenuItem>	
                                    <MenuItem value={sortConst.Irrigation_Forecast_Date_DESC} ><div className={styles.menuItem}><Typography variant="inherit" noWrap>{t("FieldListTopBar-IrrigationForecastDate")}</Typography><div className={styles.menuItemArrow}><ArrowDownwardIcon /></div></div></MenuItem>	
                                    <MenuItem value={sortConst.Field_Name_ASC} ><div className={styles.menuItem}><Typography variant="inherit" noWrap>{t("FieldListTopBar-FieldName")}</Typography><div className={styles.menuItemArrow}><ArrowUpwardIcon /></div></div></MenuItem>	
                                    <MenuItem value={sortConst.Field_Name_DESC} ><div className={styles.menuItem}><Typography variant="inherit" noWrap>{t("FieldListTopBar-FieldName")}</Typography><div className={styles.menuItemArrow}><ArrowDownwardIcon /></div></div></MenuItem>	
                                    <MenuItem value={sortConst.Crop_ASC} ><div className={styles.menuItem}><Typography variant="inherit" noWrap>{t("FieldListTopBar-Crop")}</Typography><div className={styles.menuItemArrow}><ArrowUpwardIcon /></div></div></MenuItem>	
                                    <MenuItem value={sortConst.Crop_DESC} ><div className={styles.menuItem}><Typography variant="inherit" noWrap>{t("FieldListTopBar-Crop")}</Typography><div className={styles.menuItemArrow}><ArrowDownwardIcon /></div></div></MenuItem>	
                                    {/* <MenuItem value={sortConst.Irrigation_Override_Date_ASC} ><div className={styles.menuItem}><Typography variant="inherit" noWrap>Irrigation Override Date </Typography><div className={styles.menuItemArrow}><ArrowUpwardIcon /></div></div></MenuItem>	
                                <MenuItem value={sortConst.Irrigation_Override_Date_DESC} ><div className={styles.menuItem}><Typography variant="inherit" noWrap>Irrigation Override Date </Typography><div className={styles.menuItemArrow}><ArrowDownwardIcon /></div></div></MenuItem> */}	
                                </Select>	
                            </FormControl>	
                        </div>	
                        <div className={styles.searchBarWrapper}>	
                            <SearchBar />	
                        </div>	
                    </div>	
                    <div className={styles.rightSide}>	
                        <LegendButton onClick={toggleShowLegend} isActive={showLegend} />	
                        <FilterButton onClick={handleFilterButtonClick} isActive={filter} />	
                    </div>	
                </div>	
                {showLegend ?	
                    <div className={styles.fieldListTopBarLegend}>	
                        <div className={styles.legendLeft}>	
                            Legend	
                        </div>	
                        <div className={styles.legendRight}>	
                            <div className={styles.legendItem}><div className={styles.greenBall}></div>{t("FieldListTopBar-OK")}</div>	
                            <div className={styles.legendItem}><div className={styles.blueBall}></div>{t("FieldListTopBar-Irrigate")}</div>	
                            <div className={styles.legendItem}><div className={styles.redBall}></div>{t("FieldListTopBar-Stressed")}</div>	
                            <div className={styles.legendItem}><div className={styles.greyBall}></div>{t("FieldListTopBar-N/A")}</div>	
                        </div>	
                    </div>	
                    : <></>	
                }	
            </div>	
        </ThemeProvider>	
    );	
}