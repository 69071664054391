import { useSelector } from 'react-redux';
import GraphPageDataCard, { GraphPageDataCardType } from '../../../../../components/GraphPageDataCard/GraphPageDataCard';
import { FieldDetailDataType } from '../../../../../context/FieldDetailsContext/FieldDetailsContext';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import { valueFormatter } from '../../../../../utils/numericConversions';


interface IRainfallCard {

}

export default function RainfallCard(props: IRainfallCard) {
  const { numericSystem }: { numericSystem: string } = useSelector((state: any) => state.user);
  const FieldDetailContext: FieldDetailDataType = useFieldDetailContext();

  const soilProbeData = FieldDetailContext.soilProbesData.data?.data.value[0] ?? {};

  let loading = FieldDetailContext.soilProbesData.isLoading;

  const RainfallCard: GraphPageDataCardType = {
    title: 'Rainfall',
    items: [
      { title: "Last Hour", action: valueFormatter(numericSystem, soilProbeData.Rain1Hour,   "mm") },
      { title: "6 Hours",   action: valueFormatter(numericSystem, soilProbeData.Rain6Hours,  "mm") },
      { title: "24 Hours",  action: valueFormatter(numericSystem, soilProbeData.Rain24Hours, "mm") },
      { title: "48 Hours",  action: valueFormatter(numericSystem, soilProbeData.Rain48Hours, "mm") },
      { title: "7 Days",    action: valueFormatter(numericSystem, soilProbeData.Rain7Days,   "mm") },
      { title: "30 Days",   action: valueFormatter(numericSystem, soilProbeData.Rain30Days,  "mm") },
      { title: "365 Days",  action: valueFormatter(numericSystem, soilProbeData.Rain365Days, "mm") },
    ]
  };

  return (
    <GraphPageDataCard title={RainfallCard.title} items={RainfallCard.items} loading={loading} id="RainfallCard"/>
  );
}