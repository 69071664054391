import { ApolloError, gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { secondaryClient } from "../../api/apollo";
import { useAppSelector } from "../../stores/hooks";
import { setShortCutPages, shortCutPagesValueType } from "../../stores/Slices/userSlice";
import { RootState } from "../../stores/store";

// Define the GraphQL mutation for setting the user's shortcut pages
const SET_SHORTCUT_PAGES = gql(`
mutation ($prefrences: String!, $authToken: String!) {
  UpdateUserSetting (
    input: {
        shortCutPages: $prefrences
        authToken: $authToken
    }
  ) {
    shortCutPages
  }
}
`);

/**
 * A hook for managing the user's list of shortcut pages. Returns a tuple containing:
 * - A function for setting the user's shortcut pages
 * - An object containing the following properties:
 *   - `data` - any data returned by the GraphQL mutation
 *   - `loading` - a boolean that is `true` when the mutation is in progress and `false` when it is not
 *   - `error` - an `ApolloError` object that contains information about any errors that occurred during the mutation. If no error occurred, this property will be `undefined`.
 * - An array of the user's current shortcut pages
 * 
 * @returns A tuple containing a function, an object, and an array.
 * 
 * @example
 * // Assume that `shortCutPages` is an array of shortcut page objects and `setShortCutPages` is a function returned by the `useShortcutPages` hook.
 * 
 * const [saveShortCutPages, { data, loading, error }, shortCutPages] = useShortcutPages();
 * 
 * // Set the user's shortcut pages to the `shortCutPages` array:
 * // Note: this updates the redux as well
 * saveShortCutPages(shortCutPages);
 * 
 * // Access the user's current shortcut pages:
 * console.log(shortCutPages);
 */
const useShortcutPages = (): [(shortCutPages: shortCutPagesValueType[]) => void, { data: any; loading: boolean; error: ApolloError | undefined; }, shortCutPagesValueType[]] => {

  const dispatch = useDispatch();

  /**
   * `ShortCutPages` : User's list of shortcut pages from the Redux store.
   */
  const ShortCutPages: shortCutPagesValueType[] = useAppSelector((state: RootState) => state.user.shortCutPages)

  // Use the Apollo `useMutation` hook to set the user's shortcut pages in the database
  const [_setShortCutPages_DB, { data, loading, error }] = useMutation(SET_SHORTCUT_PAGES);

  /**
   * A function that updates the user's list of shortcut pages in the database and in the Redux store.
   * 
   * @param shortCutPages An array of shortcut page objects to set as the user's shortcut pages.
   * 
   * @example
   * // Assume that `shortCutPages` is an array of shortcut page objects and `setShortCutPages_DB` is a function.
   * 
   * // Set the user's shortcut pages to the `shortCutPages` array:
   * setShortCutPages_DB(shortCutPages);
   * 
   * @remarks
   * This function updates the user's shortcut pages in both the database and the Redux store. To access the updated list of shortcut pages in the Redux store, you can dispatch the `setShortCutPages` action provided by the `userSlice` reducer.
   */
  function setShortCutPages_DB(shortCutPages: shortCutPagesValueType[]) {
    const prevShortCutPages = [...ShortCutPages]

    // Update the user's shortcut pages in the Redux store
    dispatch(setShortCutPages(shortCutPages));

    // Use the `_setShortCutPages_DB` function to call the GraphQL mutation and pass in the user's updated shortcut pages
    _setShortCutPages_DB({
      variables: {
        prefrences: JSON.stringify(shortCutPages),
        authToken: localStorage.getItem("access_token")?.replaceAll("\"", '')
      },
      client: secondaryClient
    }).catch(
      // If there is an error →
      // revert the user's shortcut pages in the Redux store
      () => dispatch(setShortCutPages(prevShortCutPages))
    )
  }

  // Return a tuple containing the `setShortCutPages_DB` function, the Apollo `useMutation` hook result, and the user's current shortcut pages
  return [setShortCutPages_DB, { data, loading, error }, ShortCutPages];
}

export default useShortcutPages;
