import * as React from 'react';
import { forwardRef } from 'react';
import { CURRENT_TIME, getWeekday, addDays, tileArrayGenerator, aestToLocationLocalTimeConverter, addDaysLuxon } from '../../../../../utils/DateConvertor';
import FieldDetailPaper from '../../../../../components/FieldDetailPaper/FieldDetailPaper';
import FieldDetailHeader from '../../../../../components/FieldDetailPaper/FieldDetailHeader';
import FieldDetailBody from '../../../../../components/FieldDetailPaper/FieldDetailBody';

import styles from './style.module.css';
import ScheduleCalendarTop from './ScheduleCanlendarTop';
import ScheduleCalendarEtc from './ScheduleCalendarEtc';
import useFieldDetailContext from '../../../../../hooks/ContextHooks/useFieldDetailContext';
import ScheduleCalendarIrrigation from './ScheduleCalendarIrrigation';
import LegendButton from '../../../../../components/Buttons/FieldListTopBarButtons/LegendButton';
import ScheduleCalendarYieldLoss from './ScheduleCalendarYieldLoss';
import { DateTime } from 'luxon';
import ScheduleCalendarStressHours from './ScheduleCanlendarStressHours';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../stores/hooks';
import { RootState } from '../../../../../stores/store';
import { LatLongDistanceCalcMetricKM, valueFormatter, valueRounder } from '../../../../../utils/numericConversions';
import moment from 'moment';
import { CURRENT_TIME_AEST } from '../../../../../utils/DateConvertor';
import useCanopyStressContext from '../../../../../hooks/ContextHooks/useCanopyStressContext';


export interface scheduleCalendarDataType {
  dateInfo: DateTime,
  EtcInfo: number,
  irrigationForecast: number,
  yieldLoss: number,
  stressHours: {
    value: number,
    available: boolean,
  }
  weatherForecast: {
    rain: number,
    temperature: number
  }
}

const NOW = new Date(CURRENT_TIME_AEST.toISO())

const createScheduleCalendarData = (): scheduleCalendarDataType[] => {
  const array: scheduleCalendarDataType[] = [];
  for (let i = 0; i <= 9; i++) {
    const tmp: scheduleCalendarDataType = {
      dateInfo: addDaysLuxon(CURRENT_TIME_AEST, i),
      yieldLoss: -999,
      stressHours: {
        value: 0,
        available: false
      },
      EtcInfo: -999,
      irrigationForecast: 3,
      weatherForecast: {
        rain: 1,
        temperature: 1
      }
    }
    array.push(tmp);
  }
  return array;
};

type DataEntry = [string, string];

interface ForecastEntry {
  value: number;
  available: boolean;
}
export interface IScheduleCalendarProps {
}

const ScheduleCalendar = forwardRef<HTMLDivElement, IScheduleCalendarProps>((props: IScheduleCalendarProps, ref) => {

  const { t } = useTranslation("ScheduleCalendar")

  const { locationDevicesData, satLocationDevicesData, soilProbesData, BOMForecastData, canopyStressForecast, rainEventDataSummaryData, canopyStressLoading } = useFieldDetailContext();
  const CanopyStress = useCanopyStressContext();
  const initialValue = React.useMemo(() => createScheduleCalendarData(), []);
  const [scheduleCalendarData, setScheduleCalendarData] = React.useState<scheduleCalendarDataType[]>(initialValue);
  const numericSystem: string = useAppSelector((state: RootState) => state.user).numericSystem;
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (locationDevicesData.data?.data.value[0].LocationIDCanopy) {
      setShow(true);
    }
  }, [locationDevicesData.data?.data.value[0].LocationIDCanopy])

  React.useEffect(() => {
    if (!BOMForecastData.isLoading && BOMForecastData.data && soilProbesData.data && rainEventDataSummaryData.data) {
      const array: scheduleCalendarDataType[] = [];
      const irrigationForecast = soilProbesData.data?.data.value[0].IrrigationDueForecast;
      const locationTimezoneGMTDifference = soilProbesData.data?.data.value[0].GMTDifference;
      const locationTimeNOW = CURRENT_TIME_AEST.plus({ hour: locationTimezoneGMTDifference });

      // https://moment.github.io/luxon/#/formatting?id=table-of-tokens
      // forecast date in locationlocal timezone
      const forecastDate = irrigationForecast ? aestToLocationLocalTimeConverter(irrigationForecast, locationTimezoneGMTDifference, true) : null;
      console.log('forecast',locationTimeNOW, forecastDate)
      const tileArray = tileArrayGenerator(locationTimeNOW, forecastDate);
      const rainForcastData = rainEventDataSummaryData.data?.filter(a => a.RainmmForecast)

      for (let i = 0; i < scheduleCalendarData.length; i++) {
        const thisDateLocationDateTimeNow = locationTimeNOW.plus({ day: i });
        const thisDateFormatted = thisDateLocationDateTimeNow.toFormat("yyyy-MM-dd'T00:00:00'");
        const thisDateRainForecastData = rainForcastData?.find((a: any) => a && a.DateTime == thisDateFormatted);
        const thisDateBomForecastData = BOMForecastData.data.find((a: any) => a && a.Date == thisDateFormatted);
        const EtcInfo = thisDateFormatted == thisDateBomForecastData?.Date ? valueRounder((numericSystem === "I" && thisDateBomForecastData?.Etc) ? thisDateBomForecastData?.Etc * 62.765 / 25.4 : thisDateBomForecastData?.Etc) : -999;
        const temperature = thisDateBomForecastData?.AirTempMax ? valueRounder(thisDateBomForecastData?.AirTempMax, 0) : -999;
        const rain: any = thisDateRainForecastData?.RainmmForecast ?? (thisDateBomForecastData?.AirTempMax ? 0 : -999);
        const currentTotalYeildLoss = (tileArray[i] === 2) ? 100 : 0;
        const yieldLoss = (tileArray[i] === 3) ? -999 : currentTotalYeildLoss;
        const tmp: scheduleCalendarDataType = {
          ...scheduleCalendarData[i],
          dateInfo: thisDateLocationDateTimeNow,
          EtcInfo,
          weatherForecast: {
            rain,
            temperature,
          },
          yieldLoss,
          irrigationForecast: tileArray[i],
        };
        console.log('tmp', thisDateLocationDateTimeNow)
        array.push(tmp);
      }
      setScheduleCalendarData(array);

      if (CanopyStress.CanopyStressGraphSeries?.stress_time_forecast?.data) {
        const nowDateTime = DateTime.local({ zone: 'Australia/Brisbane' }).plus({ hour: locationTimezoneGMTDifference });
        const stressHours: ForecastEntry[] = calculateTimeOverThreshold(nowDateTime, CanopyStress.CanopyStressGraphSeries.stress_time_forecast.data, 100);
        const updatedArray: scheduleCalendarDataType[] = [];
        for (let i = 0; i < array.length; i++) {
          const tmp: scheduleCalendarDataType = {
            ...array[i],
            stressHours: stressHours[i],
          };
          updatedArray.push(tmp);
        }
        setScheduleCalendarData(updatedArray);
      }
    }
  }, [BOMForecastData.isLoading, BOMForecastData.data, soilProbesData.data, canopyStressForecast.data, rainEventDataSummaryData.data, CanopyStress.CanopyStressGraphSeries?.stress_time_forecast]);

  const [showLegend, setShowLegend] = React.useState(true);

  const toggleShowLegend = React.useCallback(() => {
    setShowLegend((prevLegend) => !prevLegend);
  }, []);

  function calculateTimeOverThreshold(startingDate: DateTime, data: DataEntry[], threshold: number): ForecastEntry[] {

    const result: ForecastEntry[] = [];
    for (let i = 0; i < 10; i++) {
      result.push({ value: 0, available: false });
    }

    function getIndex(date: DateTime): number {
      const nowDateOnly = DateTime.fromISO(startingDate.toISODate())
      return Math.min(date.diff(nowDateOnly, 'days').days, 9);
    }

    let prevEntry: DataEntry | null = null;
    let prevDayAllOverThreshold = false;

    for (const entry of data) {
      const index = getIndex(DateTime.fromISO(DateTime.fromISO(entry[0]).toISODate()));

      if (DateTime.fromISO(entry[0]) >= startingDate) {
        if (parseFloat(entry[1]) > threshold) {
          if (prevEntry && DateTime.fromISO(prevEntry[0]).hasSame(DateTime.fromISO(entry[0]), 'day')) {
            const timeDifference = DateTime.fromISO(entry[0]).diff(DateTime.fromISO(prevEntry[0]), 'hours').hours;

            if (index >= 0 && index < result.length) {
              result[index] = { value: result[index].value + timeDifference, available: true };
            }
          } else if (prevEntry && prevDayAllOverThreshold && index - 1 > 0 && index - 1 < result.length - 1) {
            result[index - 1].value = 24;
          }
          if (!prevEntry || !DateTime.fromISO(prevEntry[0]).hasSame(DateTime.fromISO(entry[0]), 'day')) {
            prevDayAllOverThreshold = true;
          }
        } else {
          if (index >= 0 && index < result.length) {
            result[index].available = true;
            prevDayAllOverThreshold = false;
          }
        }
      }
      else if (index === 0) {
        result[0].available = true;
      }
      prevEntry = entry;
    }

    return result;
  }

  const debug: boolean = useAppSelector(state => state.user.debug);

  const distanceFromWeatherStation = debug && LatLongDistanceCalcMetricKM(
    Number(locationDevicesData.data?.data.value[0]?.Latitude || satLocationDevicesData.data?.data.value[0]?.Latitude),
    Number(locationDevicesData.data?.data.value[0]?.Longitude || satLocationDevicesData.data?.data.value[0]?.Longitude),
    (BOMForecastData.data && BOMForecastData.data.length > 0) && BOMForecastData.data[0]?.Lat,
    (BOMForecastData.data && BOMForecastData.data.length > 0) && BOMForecastData.data[0]?.Lon
  )

  const loading = locationDevicesData.isLoading || soilProbesData.isLoading || BOMForecastData.isLoading || canopyStressForecast.loading || rainEventDataSummaryData.isLoading || canopyStressLoading;

  return (
    <FieldDetailPaper width='100%' id="ScheduleCalendar">
      <FieldDetailHeader backgroundColor='#D9D7D780'>
        <div className={styles.scheduleCalendarHeader}>
          {t('scheduleCalendar:scheduleCalendar-title')}
          <span className={styles.subHeadder}>
            { distanceFromWeatherStation && `(The forecast is from ${valueFormatter(numericSystem, valueRounder(distanceFromWeatherStation), "km", true)} away)`}
          </span>
        </div>
      </FieldDetailHeader>
      <FieldDetailBody className={styles.bodyWrapper} loading={loading}>
        <>
          <ScheduleCalendarTop scheduleCalendarData={scheduleCalendarData} />
          <ScheduleCalendarEtc scheduleCalendarData={scheduleCalendarData} />
          {/* {show ?
            <ScheduleCalendarStressHours scheduleCalendarData={scheduleCalendarData} /> :
            <></>
          } */}
          {/* <ScheduleCalendarYieldLoss scheduleCalendarData={scheduleCalendarData} /> */}
          <ScheduleCalendarIrrigation scheduleCalendarData={scheduleCalendarData} />
          <div>
            <div className={styles.options}>
              <LegendButton className={styles.legendButton} onClick={toggleShowLegend} isActive={showLegend} />
            </div>
            {showLegend ?
              <div className={styles.legendSection}>
                <div className={styles.legendLeft}>
                  {t('scheduleCalendar:scheduleCalendar-legend')}
                </div>
                <div className={styles.legendRight}>
                  <div className={styles.legendItem}><div className={styles.greenBall}></div>{t('scheduleCalendar:scheduleCalendar-ok')}</div>
                  <div className={styles.legendItem}><div className={styles.blueBall}></div>{t('scheduleCalendar:scheduleCalendar-irrigate')}</div>
                  <div className={styles.legendItem}><div className={styles.redBall}></div>{t('scheduleCalendar:scheduleCalendar-stressed')}</div>
                  <div className={styles.legendItem}><div className={styles.greyBall}></div>{t('scheduleCalendar:scheduleCalendar-na')}</div>
                </div>
              </div>
              : <></>
            }
          </div>
        </>

      </FieldDetailBody>
    </FieldDetailPaper>

  );
});

export default ScheduleCalendar;
