import Box from '@mui/material/Box';
import React from 'react';

let readoutData = [{
  title: "Land Use Efficiency Index",
  value: "3.4",
  description: "For this field, this season, 3.4 sqm of land were used to grow 1kg of cotton. Our 2025 target is 3.1 sqm.",
  color: "#e02130",
}, {
  title: "Water Use Efficiency Index",
  value: "2.74",
  description: "For this field, this season, 2.74 kilolitres of irrigation was used to grow 1 kg of cotton. Our 2025 target is 2.35 kilolitres.",
  color: "#fab243",
}, {
  title: "Efficiency Index",
  value: "94.6",
  description: "For this field, this season, 94.6. this is a function of land and water used to grow 1 kg of cotton.",
  color: "#429867",
}
]

interface Readout {
  title: string;
  value: string;
  description: string;
  color: string;
}

interface ReadoutDataProps {
  readoutData: Readout[];
}


function ReadOutStats() {
  // const rows = []
  return (
        <ReadoutDataList readoutData={readoutData}></ReadoutDataList>
  );
}

const ReadoutDataList: React.FC<ReadoutDataProps> = ({ readoutData }) => {
  return (
    <>
      {readoutData.map((input: Readout, index: number) => (
        <Box
          key={index}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          width="100%"
          gap={2}
          height="85px"
        >
          <Box
            style={{
              color: input.color,
              borderRadius: "10px",
              textAlign: "center",
              display: "flex",
              justifyContent: "right",
              alignItems: "center"
            }}
            width="20%"
            fontSize={30}
          >
            <h2
              style={{
                fontWeight: "500",
                fontSize: "2.4rem"
              }}
            >
              {input.value}
            </h2>
          </Box>
          <Box width="70%">
            <h3
              style={{
                fontSize: "1.1rem",
                padding: "0",
                margin: "0"
              }}
            >
              {input.title}
            </h3>
            <p
              style={{
                fontSize: "0.8rem",
                padding: "0",
                margin: "0"
              }}
            >
              {input.description}
            </p>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default ReadOutStats;