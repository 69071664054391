import * as React from 'react';
import { GoannaDrawerContext } from '../../context/GoannaDrawerContext/GoannaDrawerContext';

const useGoannaDrawerContext = () => {
    const context = React.useContext(GoannaDrawerContext);

    if (context === undefined) {
        throw new Error("useGoannaDrawerContext was used outside of its Provider");
    }
    return context
}

export default useGoannaDrawerContext;