import { AxiosResponse } from 'axios';
import { http } from '../../api/http';

export interface CanopyFieldsDataType {
    data: {
        value: {
            AccountID: number;
            AccountName: string;
            Active: boolean;
            Altitude: any;
            AssignedWeatherStationDescription: any;
            CropID: number;
            CropName: string;
            DayDegrees: number;
            DayWaterUse: number;
            DeviceCategoryID: number;
            DeviceID: number;
            DeviceName: any;
            DeviceTypeID: any;
            DripThreshold: number;
            EndDateCanopy: any;
            ExternalID: any;
            ExternalSourceCode: string;
            FarmID: number;
            FarmName: string;
            FieldPolyCoords: any;
            FloodThreshold: number;
            Fullpoint: any;
            GI: number;
            GrowthStages: string;
            IrrigationMethodID: number;
            Latitude: string;
            LocationDescription: string;
            LocationID: number;
            LocationIDProbe: any;
            LocationIDRain: number;
            LocationIDSatellite: number;
            LocationIDSolar: any;
            LocationIDTemp: any;
            Longitude: string;
            PlantingDate: string;
            Rain1Hour: number;
            Rain6Hours: number;
            Rain7Days: number;
            Rain24Hours: number;
            Rain30Days: number;
            Rain48Hours: number;
            Rain365Days: number;
            RainYTD: number;
            RegionID: number;
            RegionName: string;
            StartDate: string;
            StartDateCanopy: string;
            TimeZoneCode: string;
            TimeZoneName: string;
            TotalWaterUse: number;
            VarietyID: number;
            VarietyName: string;
            WeatherStationID: number;
            TempThreshold: number;
        }[];
    };
}

const DEVICE_PATH = 'odata/CanopyFields';

const useCanopyFields = (): (locationID: string) => (Promise<AxiosResponse<any, any>>) | undefined => {
    const fetchCanopyFieldsData = (locationID: string) => {
        if (locationID)
        {
            const query = DEVICE_PATH + `(${locationID})?&%24count=true`;
            const response = http.get(query);
            return response;
        }
    };

    return fetchCanopyFieldsData;
};

export default useCanopyFields;
