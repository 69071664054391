import * as React from 'react';
import { useLocation, NavLink } from 'react-router-dom';

import { Breadcrumbs, styled } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import styles from './style.module.css'

export interface IRouterBreadCrumbs {
}

const LINK_TO_NAME_MAPPING: { [key: string]: string } = {
  'settings': "Settings",
  'myfields': "My Fields"
}

export default function RouterBreadCrumbs(props: IRouterBreadCrumbs) {

  const location = useLocation();
  const pathNames = location.pathname.split('/').slice(2).filter(x => x);

  return (
    <Breadcrumbs separator={<NavigateNextIcon />}>
      {pathNames.map((value: string, index) => {
        const absloutePath = '/pages/' + pathNames.slice(0, index + 1).join('/')
        return (
          <NavLink
            to={absloutePath}
            className={styles.routerLink}
            key={absloutePath}
          >
            {LINK_TO_NAME_MAPPING[value] == undefined ? value : LINK_TO_NAME_MAPPING[value]}
          </NavLink>)
      })}
    </Breadcrumbs>
  );
}
