import * as React from 'react';
import { useSelector } from 'react-redux';
import useFieldDetailContext from '../../hooks/ContextHooks/useFieldDetailContext';
import { valueFormatter } from '../../utils/numericConversions';
import { EChartsSeriesType } from './SoilTotalContext';

interface RootzoneDataGraphSeriesDataType {
  dataSet: any[];
};

interface RootzoneContextDataType {
  RootzoneDataGraphSeries: RootzoneDataGraphSeriesDataType,
  loading: boolean
}

interface IRootzoneProviderProps {
  children: React.ReactNode,
}

const RootzoneContext = React.createContext({} as RootzoneContextDataType);

export default function RootzoneProvider(props: IRootzoneProviderProps) {
  const { numericSystem }: { numericSystem: string } = useSelector((state: any) => state.user);
  const { deviceData, rootzoneData } = useFieldDetailContext();

  const data = rootzoneData.data;

  const RootzoneDataGraphSeries = React.useMemo(() => {
    if (!deviceData.data?.value[0] || !data) return { dataSet: [] };

    const dataSet = [];
    for (let i = 1; i <= 12; i++) {
      const depthName = `depth${i}`;
      const rootzoonAvailableAttriName = `MoistureRootZoneDepth${i}`;
      const rootzoonDailyUsageAttriName = `dailyUseOfRootZoneDepth${i}`;
      if (!deviceData.data.value[0].hasOwnProperty(depthName)) continue;

      const depthValue = deviceData.data.value[0][depthName];
      if (!depthValue) continue;

      const name = valueFormatter(numericSystem, depthValue, "cm", true, 0);

      const tmp = {
        'Depth': name,
        'Daily Use': null,
        'Available': null,
      };

      if (typeof data[0] === 'object') {
        if (data[0].hasOwnProperty(rootzoonDailyUsageAttriName)) {
          tmp['Daily Use'] = data[0][rootzoonDailyUsageAttriName].toFixed(1);
        }
        if (data[0].hasOwnProperty(rootzoonAvailableAttriName)) {
          tmp['Available'] = data[0][rootzoonAvailableAttriName].toFixed(1);
        }
      }
      dataSet.push(tmp);
    }

    return { dataSet: dataSet.reverse() };
  }, [deviceData, rootzoneData, numericSystem]);

  return (
    <RootzoneContext.Provider value={{ RootzoneDataGraphSeries, loading: rootzoneData.isLoading }}>
      {props.children}
    </RootzoneContext.Provider>
  );
}

export { RootzoneContext, RootzoneProvider };
