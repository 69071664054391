import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_CANOPY_URL,
});



//  uri: 'https://u3nuibn4ui.execute-api.ap-southeast-2.amazonaws.com/test/graphiql',
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('access_token')?.replaceAll("\"", '');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "content-type": "application/json",
    }
  }
});

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

// =================================================================================================

const secondaryHttpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_V2_URL
});

export const secondaryClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: authLink.concat(secondaryHttpLink),
  cache: new InMemoryCache({ addTypename: false }),
});