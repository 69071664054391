import { AlertColor, Alert, Typography } from "@mui/material";
import { CustomContentProps, useSnackbar } from "notistack";
import React from "react";

interface GoannaAlertProps extends CustomContentProps {
    message: string,
    variant: AlertColor
}

const GoannaAlert = React.forwardRef<HTMLDivElement, GoannaAlertProps>(({ id, message, variant }: GoannaAlertProps, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = React.useCallback((event: React.SyntheticEvent<Element, Event>) => {
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    return <Alert
        ref={ref}
        onClose={handleDismiss}
        severity={variant}
        sx={{ width: '100%' }}
    >
        <Typography>{message}</Typography>
    </Alert>;
})

export default GoannaAlert;