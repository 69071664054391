import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { http } from '../../api/http';

export interface getRootZoneDailyUsageFromCachedType {
};

const url= process.env.REACT_APP_AXIOS_URL + '/';
const GET_ROOT_ZONE_DAILY_USAGE_FROM_CACHED_PATH: string = 'Reportdata//RootZoneDailyUsage/GetRootZoneDailyUsageFromCached/';

const useGetRootZoneDailyUsageFromCached = (locationID: string) => {

    const query = url + GET_ROOT_ZONE_DAILY_USAGE_FROM_CACHED_PATH + `${locationID}`;
    const fetchGetRootZoneDailyUsageFromCached = async () => {
        const response  = await fetch(query,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token')?.replaceAll("\"", ''),
          }
        }
        );
        return response.json();
        // const response = await http.get(query, {
        //     headers: {
        //         Accept: '*/*'
        //     }
        // });
        // return response;
    };

    const GetRootZoneDailyUsageFromCached = useQuery({ queryKey: ['GetRootZoneDailyUsageFromCached'], queryFn: fetchGetRootZoneDailyUsageFromCached, enabled: false });

    return GetRootZoneDailyUsageFromCached;
}

export default useGetRootZoneDailyUsageFromCached;