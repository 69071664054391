import * as React from 'react';

import Paper from '@mui/material/Paper';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { createContext } from 'react';
import { useTranslation } from "react-i18next";

import FieldListTopBar from './components/fieldListTopBar';
import styles from './style.module.css';
import IrrigationForecastTop from '../../../components/IrrigationForecastTop/IrrigationForecastTop';
import IrrigationForecastItem from '../../../components/IrrigationForecastItem/IrrigationForecastItem';

import { useAppSelector } from '../../../stores/hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { AEST_Zone, calcDateDifference, CURRENT_TIME, tileArrayGenerator } from '../../../utils/DateConvertor';
import { filterIrrigationForecastItem, IrrigationForecastItemFilterType } from '../../../utils/filter';
import { sortIrrigationForecastItem, sortConst } from '../../../utils/sort';
import { Button } from '@mui/material';
import { DateTime } from 'luxon';
import useForecastContext from '../../../hooks/ContextHooks/useForecastContext';

interface FieldListComponentConterxtType {
  itemData: any[],
  preferedFields: any[],
  fieldList: any[],
  farmList: any[],
  cropTypeList: any[],
  varietyList: any[],
  loading: boolean;
  filterObject: IrrigationForecastItemFilterType,
  setFilterObject: Function
  sort: string;
  setSort: Function,
  handleFilterApply: Function
}

export const defaultFilterObject = {
  field: ['All'],
  farm: ['All'],
  cropType: ['All'],
  variety: ['All'],
  status: ['OK', 'Irrigate', 'Stress', 'N/A'],
  active: [0,1]
};

const defaultSort = sortConst.Irrigation_Forecast_Date_ASC;

const sortMapping: {[key: string]: {
  [key: string]: string;
}} = {
  IrrigationDueForecast: {
    asc: "1",
    desc: "2"
  },
  LocationDescription: {
    asc: "3",
    desc: "4"
  },
  CropName: {
    asc: "5",
    desc: "6"
  }
}
export const FieldListComponentContext = createContext<FieldListComponentConterxtType>({ itemData: [], preferedFields: [], fieldList: [], farmList: [], cropTypeList: [], varietyList: [], loading: true, filterObject: defaultFilterObject, setFilterObject: () => { }, sort: defaultSort, setSort: () => { }, handleFilterApply: () => { } });

export interface IFieldListComponent {
}


export default function FieldListComponent(props: IFieldListComponent) {

  const { t } = useTranslation('FieldList');
  // const { row } = React.useContext(FieldTableContext);
  const { row } = useForecastContext();
  const [itemData, setItemData] = React.useState<any[]>([]);
  const [irrigationForecastCounter, setIrrigationForecastCounter] = React.useState<number[]>([]);
  const [displayData, setDisplayData] = React.useState<any[]>([]);

  const prefered_fields = useAppSelector((state) => state.user.preferredFields);
  const [preferedFields, setPreferedFields] = React.useState<string[]>([]);
  const [fieldList, setFieldList] = React.useState<string[]>([]);
  const [farmList, setFarmList] = React.useState<string[]>([]);
  const [cropTypeList, setCropTypeList] = React.useState<string[]>([]);
  const [varietyList, setVarietyList] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(true);

  const [filterObject, setFilterObject] = React.useState<IrrigationForecastItemFilterType>(defaultFilterObject);
  const [applyFilter, setApplyFilter] = React.useState<boolean>(false);

  const sortModel = useAppSelector(state => state.user.sortModel);
  const ProbeTableSort = sortModel ? sortModel["ProbeTable"] : [];
   
  const [sort, setSort] = React.useState<string>((ProbeTableSort[0] && sortMapping[ProbeTableSort[0].field] && ProbeTableSort[0].sort) ? sortMapping[ProbeTableSort[0].field][ProbeTableSort[0].sort] : defaultSort);

  const [loadMore, setLoadMore] = React.useState<number>(20);

  const isMobilePortrait = useAppSelector(state => state.topbar.dimensions.width < 500);
  const isMobile = useAppSelector(state => state.topbar.isMobile);

  const handleFilterApply = (filter: IrrigationForecastItemFilterType) => {
    const result = filterIrrigationForecastItem(itemData, filter);
    setApplyFilter(!applyFilter);
    setDisplayData(result);
  };

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 20 * 1000)
  }, []);

  React.useEffect(() => {
    if (!localStorage.getItem('searchHistory')) {
      localStorage.setItem('searchHistory', JSON.stringify([]));
    }
  }, []);

  React.useEffect(() => {
    const newItemData: any[] = [];
    let newFieldSet = new Set<string>();
    let newFarmSet = new Set<string>();
    let newCropTypeSet = new Set<string>();
    let newVarietySet = new Set<string>();
    let newPreferedSet = new Set<string>();

    const irrigationTotalCounter = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    if (row.length) {
      row.forEach((location) => {
        const startingTimeLocationLocalTimeZone = DateTime.fromISO(
          DateTime.fromJSDate(
            CURRENT_TIME, { zone: AEST_Zone }).plus({ hour: location.GMTDifference })
            .toISODate());
        const forecastDateLocationLocalTimeZone: DateTime | null = location.IrrigationDueForecast
          ? DateTime.fromISO(location.IrrigationDueForecast, { zone: AEST_Zone }).plus({ hour: location.GMTDifference })
          : null;
        if (forecastDateLocationLocalTimeZone !== null) {
          const index = calcDateDifference(startingTimeLocationLocalTimeZone, forecastDateLocationLocalTimeZone)
          if (index !== null && index !== undefined && index >= 0 && index <= 9) {
            irrigationTotalCounter[index] += 1;
          }
        }
        const tileArray = tileArrayGenerator(startingTimeLocationLocalTimeZone, forecastDateLocationLocalTimeZone);
        const statusString = ['OK', 'Irrigate', 'Stress', 'N/A'][tileArray[0]];
        const tmp = {
          locationID: location.LocationID ?? '',
          fieldName: location.LocationDescription ?? '',
          farmName: location.FarmName ?? '',
          tileArray: tileArray,
          status: statusString,
          irrigationForecastDate: location.IrrigationDueForecast ?? '',
          farmNameSort: location.FarmName ?? '',
          cropName: location.CropName ?? '',
          irrigationOverrideDate: '',
          FarmName: location.FarmName ?? '',
          cropType: location.CropName ?? '',
          varietyName: location.varietyName ?? '',
          GMTDifference: location.GMTDifference ?? '',
          Active: location.Active ?? true
        }
        newItemData.push(tmp);
        if (location.LocationDescription) newFieldSet.add(location.LocationDescription);
        if (location.FarmName) newFarmSet.add(location.FarmName);
        if (location.CropName) newCropTypeSet.add(location.CropName);
        if (location.varietyName) newVarietySet.add(location.varietyName);
        if (prefered_fields.includes(location.LocationID)) {
          newPreferedSet.add(location.LocationDescription);
        };
      });
      setItemData(newItemData);
      setIrrigationForecastCounter(irrigationTotalCounter);
      setPreferedFields(Array.from(newPreferedSet));
      setFieldList(Array.from(newFieldSet));
      setFarmList(Array.from(newFarmSet));
      setCropTypeList(Array.from(newCropTypeSet));
      setVarietyList(Array.from(newVarietySet));
      if (newItemData.length) {
        setLoading(false);
      }
    }
  }, [row]);

  React.useEffect(() => {
    if (itemData.length) {
      setDisplayData(filterIrrigationForecastItem(sortIrrigationForecastItem(itemData, sort).slice(), filterObject).slice());
    }
  }, [sort, itemData]);

  React.useEffect(() => {
    if (itemData.length) {
      const displayData = filterIrrigationForecastItem(sortIrrigationForecastItem(itemData, sort).slice(), filterObject).slice();
      setDisplayData(displayData);
      setIrrigationForecastCounter(calcIrrigationForecastCounter(displayData));
    }
  }, [applyFilter, itemData])

  // React.useEffect(() => {
  //   if (itemData.length) {
  //     setItemData(sortIrrigationForecastItem(itemData, sort).slice());
  //     const displayData = filterIrrigationForecastItem(sortIrrigationForecastItem(itemData, sort).slice(), filterObject).slice();
  //     setDisplayData(displayData);
  //     setIrrigationForecastCounter(calcIrrigationForecastCounter(displayData));

  //   }
  // }, [sort, itemData.length, row, applyFilter]);


  React.useEffect(() => {
    const cleanUpFn = scrollBind(styles.scrollBind);

    return cleanUpFn;
  }, [displayData]);

  const calcIrrigationForecastCounter = (data: any[]): number[] => {

    const irrigationTotalCounter = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    data.forEach((value) => {
      const startingTimeLocationLocalTimeZone = DateTime.fromISO(
        DateTime.fromJSDate(
          CURRENT_TIME, { zone: AEST_Zone }).plus({ hour: value.GMTDifference })
          .toISODate());
      const irrigationDueForecast = value.irrigationForecastDate;

      const forecastDateLocationLocalTimeZone: DateTime | null = irrigationDueForecast
      ? DateTime.fromISO(
            DateTime.fromISO(
              irrigationDueForecast, { zone: AEST_Zone }).plus({ hour: value.GMTDifference })
              .toISODate())
      : null;
      
      if (irrigationDueForecast) {
        const index = calcDateDifference(startingTimeLocationLocalTimeZone, forecastDateLocationLocalTimeZone)
        if (index !== null && index !== undefined && index >= 0 && index <= 9) {
          irrigationTotalCounter[index] += 1;
        }
      }
    })

    return irrigationTotalCounter;
  }

  return (
    <FieldListComponentContext.Provider value={{
      itemData: itemData,
      preferedFields: preferedFields,
      fieldList: fieldList,
      farmList: farmList,
      cropTypeList: cropTypeList,
      varietyList: varietyList,
      loading: loading,
      filterObject,
      setFilterObject: setFilterObject,
      sort: sort,
      setSort: setSort,
      handleFilterApply: handleFilterApply
    }}>
      <Paper elevation={1} sx={{ padding: '0px' }}>
        <div className={styles.fieldListTopSection}>
          <FieldListTopBar />
        </div>
        <div>
          <div className={styles.scrollBind}><IrrigationForecastTop irrigationTotal={irrigationForecastCounter} /></div>
          <div className={[styles.fieldListComponentWrapper, styles.scrollBind].join(' ')}>
            {
              loading ? <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}><CircularProgress /></div> :
                displayData.slice(0, loadMore).map((item) => {
                  // console.log('In fieldList: ', item);
                  return <IrrigationForecastItem locationID={item.locationID}
                    cropType={item.cropName}
                    varietyName={item.varietyName}
                    fieldName={item.fieldName}
                    farmName={item.farmName}
                    irrigationForecast={item.tileArray} />
                })
            }
            {
              displayData.length !== 0 && loadMore < displayData.length - 1 ?
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Button startIcon={<KeyboardDoubleArrowDownIcon />} onClick={() => {
                    if (displayData.length !== 0 && loadMore <= displayData.length - 1) {
                      if (itemData.length - loadMore - 1 < 20) {
                        setLoadMore(displayData.length - 1);
                      }
                      else {
                        setLoadMore(loadMore + 20);
                      }
                    }
                  }}>
                    {t("Load-More-Data")}
                  </Button>
                </div>
                : displayData.length !== 0 ?
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '20px',
                    font: '1rem poppins'
                  }}>
                    {t("No-more-fields-information")}
                  </div> :
                  (
                    loading
                      ? null
                      : <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '5%',
                        font: '1rem poppins'
                      }}>
                        {t("No-search-result-for-you")}
                      </div>
                  )
            }
          </div>
        </div>
      </Paper>
    </FieldListComponentContext.Provider>
  );
}

/** @Returns useEffect's cleanup function */
function scrollBind(className: string) {
  var scrollers = document.getElementsByClassName(className);
  var scrollerDivs = Array.prototype.filter.call(scrollers, function (testElement) {
    return testElement.nodeName === 'DIV';
  });

  function scrollAll(scrollLeft: any) {
    scrollerDivs.forEach(function (element, index, array) {
      element.scrollLeft = scrollLeft;
    });
  }

  function scrollNamedFunction(e: any) {
    scrollAll(e.target.scrollLeft);
  }

  scrollerDivs.forEach(function (element, index, array) {
    element.addEventListener('scroll', scrollNamedFunction);
  });

  return () => {
    scrollerDivs.forEach(function (element, index, array) {
      element.removeEventListener('scroll', scrollNamedFunction);
    });
  }
}
