import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enAU from './en-au.json'
import enUS from './en-us.json'
import internal from './internal.json'

// for all options read: https://www.i18next.com/overview/configuration-options
i18n.use(initReactI18next)
  .init({
    fallbackLng: 'internal', // default language
    debug: false,
    resources: {
      internal,
      enAU,
      enUS
    },
    compatibilityJSON: 'v4'
  })
  .then(() => i18n.changeLanguage("enAU"));



export default i18n;