import convert from 'convert';
import { DateTime } from 'luxon';
import moment from 'moment';

export const DECIMAL_DISPLAY_PRECISION = 2;
export const KG_IN_BALE = 500 / 2.20462;
const MM_HA_TO_INCH_ACRE = 0.0159325;
const KG_MM_TO_LB_INCH = 55.997415;

export function dateFormatter(numericSystem: string, date: string | undefined, showTime: boolean = false, useStringFormat: boolean = false): string | undefined {
  if (!date) return date;

  const parsedDate = DateTime.fromISO(date, { setZone: true });
  const dateFomat = numericSystem == "M" ? "dd/MM/yyyy" : "MM/dd/yyyy";
  const stringFormat = numericSystem == "M" ? "dd LLL yyyy" : "LLLdd yyyy"

  const format = (useStringFormat ? stringFormat : dateFomat) + (showTime ? " HH:mm" : "");

  return parsedDate.toFormat(format);
  // if (!useStringFormat) return moment(date).format((numericSystem == "M" ? "DD/MM/YYYY" : "MM/DD/YYYY") + (showTime ? " HH:mm" : ""));

  // return moment(date).format((numericSystem == "M" ? "DD MMM YYYY" : "MMM DD YYYY") + (showTime ? " HH:mm" : ""));
}

export type MetricTypesSupported = "" | "mm" | "$ / mm" | "mm / Ha" | "DD" | "kg" | "\u00B0C" | "Bales / ML" | "kg / mm" | "Bale(s) / Ha" | " / Bale(s)" | "mm / day" | "cm" | "b/ha" | " / Tonne" | "Tonne / Ha" | "km";

const unit_mapping: { [key: string]: string } = {
  "": "",
  "mm": "In",
  "$ / mm": "$ / inch",
  "mm / Ha": "inches / Acre",
  "DD": "DD",
  "kg": "lbs",
  "\u00B0C": "\u00B0F",
  "Bales / ML": "Bales / ML",
  "kg / mm": "lbs / inch",
  "Bale(s) / Ha": "Bale(s) / Acre",
  "Tonne / Ha": "Tonne / Acre",
  " / Bale(s)": " / Bale(s)",
  " / Tonne": " / Tonne",
  "mm / day": "inches / day",
  "cm": "In",
  "b/ha": "b/ac",
  "km": "miles",
}

const reverse_unit_mapping: { [key: string]: string } = {
  "": "",
  "In": "mm",
  "$ / inch": "$ / mm",
  "inches / Acre": "mm / Ha",
  "DD": "DD",
  "lbs": "kg",
  "\u00B0F": "\u00B0C",
  "Bales / ML": "Bales / ML",
  "lbs / inch": "kg / mm",
  "Bale(s) / Acre": "Bale(s) / Ha",
  " / Bale(s)": "/ Bale(s)",
  "inches / day": "mm / day",
  "inches": "cm",
  "b/ac": "b/ha",
  "miles": "km",
}

const convert_unit_mapping: { [key: string]: any } = {
  "\u00B0C": "C",
  "\u00B0F": "F",
  "kg": "kg",
  "lbs": "lb",
  "mm": "mm",
  "In": "in",
  "inches": "in",
  "cm": "cm",
  "km": "km",
  "miles": "miles",

  // hack to convert these value because they needed to be divided so I switched it
  "Bale(s) / Acre": "ha",
  "Bale(s) / Ha": "acre",
  "Tonne / Ha": "ha",
  "Tonne / Acre": "acre",
  "$ / mm": "in",
  "$ / inch": "mm",
  "b/ha": "acre",
  "b/ac": "ha"
}

export function valueFormatter(numericSystem: string, value: any, metricUnit: MetricTypesSupported, convertData?: boolean, percision?: number) {

  if (value == undefined) return undefined;
  if (!convertData) return value + " " + (numericSystem == "M" ? metricUnit : unit_mapping[metricUnit]);
  if (numericSystem == "M") return value + " " + metricUnit;

  // must be imperial
  // convert which unit to what
  try {
    if (convert_unit_mapping[metricUnit]) {
      return Number(
        convert(
          value,
          convert_unit_mapping[metricUnit]
        ).to(convert_unit_mapping[getUnitName(numericSystem, metricUnit)])
      )
        .toFixed(percision !== undefined ? percision : DECIMAL_DISPLAY_PRECISION)
        .toString()
        + " " + getUnitName(numericSystem, metricUnit);
    }
    else {
      // manually convert it
      switch (metricUnit) {
        case "mm / Ha":
          return Number(value * MM_HA_TO_INCH_ACRE).toFixed(percision !== undefined ? percision : DECIMAL_DISPLAY_PRECISION)
            .toString()
            + " " + getUnitName(numericSystem, metricUnit);
        case "kg / mm":
          return Number(value * KG_MM_TO_LB_INCH).toFixed(percision !== undefined ? percision : DECIMAL_DISPLAY_PRECISION)
            .toString()
            + " " + getUnitName(numericSystem, metricUnit);
        default:
          // if nothing then return metric
          return value + " " + metricUnit;
      }
    }
  }
  catch {
    console.warn(`Conversion Not implemented: from ${metricUnit}`);
    return value + " " + metricUnit;
  }
}

export function getUnitName(numericSystem: string, unit: MetricTypesSupported) {
  return (numericSystem == "M" ? unit : unit_mapping[unit])
}

export type ImperialTypesSupported = "" | "In" | "$ / inch" | "inches / Acre" | "DD" | "lbs" | "\u00B0F" | "Bales / ML" | "lbs / inch" | "Bale(s) / Acre" | " / Bale(s)" | " / Tonne" | "Tonne / Acre";

export function valueConverterImperial(numericSystem: string, value: any, imperialUnit: ImperialTypesSupported) {
  if (value == undefined) return undefined;
  if (numericSystem == "I") return value;

  return (
    Math.round(
      Number(
        convert(
          value,
          convert_unit_mapping[imperialUnit]
        ).to(
          convert_unit_mapping[reverse_unit_mapping[imperialUnit]])
      )
      * (10 ** DECIMAL_DISPLAY_PRECISION)
    )
    / (10 ** DECIMAL_DISPLAY_PRECISION)
  )
}

export function ImperialToMetric(value: number, imperialUnit: ImperialTypesSupported) {
  if (!value) return value;

  return Math.round(
    Number(
      convert(
        value,
        convert_unit_mapping[imperialUnit]
      ).to(
        convert_unit_mapping[reverse_unit_mapping[imperialUnit]])
    )
    * (10 ** DECIMAL_DISPLAY_PRECISION)
  )
    / (10 ** DECIMAL_DISPLAY_PRECISION)
}

export function MetricToImperial(value: number, metricUnit: MetricTypesSupported) {
  if (!value) return value;

  return Math.round(
    Number(
      convert(
        value,
        convert_unit_mapping[metricUnit]
      ).to(
        convert_unit_mapping[unit_mapping[metricUnit]])
    )
    * (10 ** DECIMAL_DISPLAY_PRECISION)
  )
    / (10 ** DECIMAL_DISPLAY_PRECISION)
}

export function valueRounder(number: number, precision = DECIMAL_DISPLAY_PRECISION) {
  if (!number) return 0;

  const factor = 10 ** precision;
  const roundedNumber = Math.round(number * factor) / factor;

  return roundedNumber === 0 ? 0 : roundedNumber;
}

export function numericValueFormatter(numericSystem: string, value: any, metricUnit: MetricTypesSupported) {

  if (value == undefined) return '';
  if (numericSystem == "M") return value;

  // convert which unit to what
  try {
    return Number(
      convert(
        value,
        convert_unit_mapping[metricUnit]
      ).to(convert_unit_mapping[getUnitName(numericSystem, metricUnit)])
    )
    // .toFixed(DECIMAL_DISPLAY_PRECISION)
  }
  catch {
    console.error("Conversion Failed")
    return "";
  }
}


// https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
export function LatLongDistanceCalcMetricKM(lat1?: number, lon1?: number, lat2?: number, lon2?: number) {
  if (!(lat1 && lon1 && lat2 && lon2)) return undefined;

  const p = 0.017453292519943295;    // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((lat2 - lat1) * p) / 2 +
    c(lat1 * p) * c(lat2 * p) *
    (1 - c((lon2 - lon1) * p)) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}