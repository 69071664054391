import Box from '@mui/material/Box';
import React, { forwardRef } from 'react';
import { ReactECharts } from '../../../components/ReactEcharts/ReactEcharts';
import { EChartsOption } from 'echarts';

interface Props {
  center: string;
  name: string;
  data: any[];
  // color: string[];
}

const CircleStats = forwardRef(function CircleStats(props:Props, ref) {
  const {center, name, data} = props;
  const options: EChartsOption = {
    title: {
      text: center,
      left: 'center',
      top: 'center',
      textStyle: {
        color: '#111'
      }
    },
    legend: {
      top: 'top'
    },
    tooltip: {
      trigger: 'item'
    },
    visualMap: {
      show: false,
      min: 80,
      max: 600,
      inRange: {
        colorLightness: [0, 1]
      }
    },
    series: [
      {
        name: name,
        type: 'pie',
        radius: ["45%", '75%'],
        center: ['50%', '50%'],
        data: data.sort(function (a, b) {
          return a.value - b.value;
        }),
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          lineStyle: {
            color: 'rgba(0, 0, 0, 0.7)'
          },
          smooth: 0.2,
          length: 10,
          length2: 20
        },
        animationType: 'scale',
        animationEasing: 'elasticOut',
      }
    ]
  };
  return (

        <ReactECharts
          option={options}
          style={{
            width: '100%',
            height: '100%'
          }}
        />
  );
})

export default CircleStats;